<template>
  <div v-show="hasModel3D" class="canvas3d-wrapper">
    <v-fade-transition>
      <v-overlay
        v-if="!installed || saving3dDesign"
        absolute
        opacity="1"
        color="white"
        z-index="0"
      >
        <v-row>
          <v-col class="d-flex justify-center actual-logo-loading">
            <v-img :src="actualLogo" />
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col class="d-flex justify-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center">
            <span class="aviso-canvas-3d">
              {{
                saving3dDesign
                  ? $t("general.canvas3dprocess")
                  : $t("general.canvas3dloading")
              }}
            </span>
          </v-col>
        </v-row>
      </v-overlay>
    </v-fade-transition>
    <div
      v-show="activeCanvas3d && active"
      id="canvas3d"
      :key="'canvas-' + modelCode"
      class="w-100"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import {
  init3DCanvas,
  loadColor,
  setActiveZone,
  uploadFile,
  searchModelOnApiAndSetActive,
  resetCanvas3d,
  removeFile
} from "@/utils/customDimUtils";

export default {
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    zona: {
      type: Object,
      required: true
    },
    img: {
      type: Object,
      default: null
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      installed: false,
      json: {}, // JSON con la información de la personalización 3D
      activeZone3D: null, // Zona activa en el canvas 3D
      imgInfoCanvas3d: {}, // Diccionario para poder borrar el logo actual del canvas
      activeColorHexcode: null, // Hexcode del color actual, para evitar re activar el cambio de color, si no ha cambiado

      // Objetos para hacer debounced
      debouncedChangeColor: null,
      debouncedChangeZone: null,
      debouncedChangeImg: null
    };
  },
  computed: {
    ...mapGetters("modelo", [
      "modelCode",
      "idModel3D",
      "hasModel3D",
      "actualColorInfo"
    ]),
    ...mapGetters("config", ["actualLogo", "saving3dDesign"]),
    /**
     * Indica si el canvas 3D está activo
     */
    activeCanvas3d() {
      return (
        process.env.VUE_APP_ACTIVE_3D == "true" &&
        this.modelCode &&
        this.hasModel3D &&
        this.installed
      );
    },
    /**
     * Hexcode del color seleccionado
     */
    hexcodeColorSelected() {
      return this.actualColorInfo?.hexcode;
    }
  },
  watch: {
    json: {
      handler(newVal) {
        console.log(newVal);
      },
      deep: true
    },
    async hexcodeColorSelected(newVal, oldVal) {
      if (newVal == oldVal) return;

      if (this.activeCanvas3d) {
        await this.updateColorCanvas3d(newVal);
      }
    },
    async img(newVal, oldVal) {
      console.log("🚀 ~ file: canvas3d.vue:76 ~ img ~ newVal:", newVal);

      if (this.debouncedChangeImg) {
        clearTimeout(this.debouncedChangeImg);
      }

      // Tenemos que controlar la condición de carrera de cambiar de zona que no se borre la imagen
      if (!this.activeZone3D || this.activeZone3D?.sku != this.zona.Posicion)
        return;

      // Guardamos todo en variables, ya que puede cambiar su estado, pero la ejecución debe mantenerse
      let localImgInfoCanvas3d = this.imgInfoCanvas3d;
      let localActiveZone3D = this.activeZone3D
      let localZona = this.zona;
      let localNewVal = newVal;
      let localOldVal = oldVal;

      this.debouncedChangeImg = setTimeout(async () => {

          
          if (localOldVal?.id && localOldVal?.base64 && localOldVal.id != localNewVal?.id) {
            // Debemos borrar la imagen previamente en el canvas
            if (!localImgInfoCanvas3d[localOldVal?.id]) {
              console.warn(
                "💻 ~ No tenemos un imgInfoCanvas3d para borrar la imagen",
                localOldVal?.id
              );
              return;
            }

            console.log(
              "🚀 ~ img ~ this.imgInfoCanvas3d: =>",
              localImgInfoCanvas3d,
              localActiveZone3D,
              localZona,
              localOldVal
            );

            await removeFile(
              localImgInfoCanvas3d[localOldVal?.id],
              localActiveZone3D
            );
            delete this.imgInfoCanvas3d[localOldVal?.id];
          }

          console.log("TESTTTT =>", localOldVal, localNewVal, localZona, localActiveZone3D, localActiveZone3D?.sku != localZona.Posicion);
          if (
            this.activeCanvas3d &&
            localNewVal &&
            localNewVal.base64 &&
            localOldVal?.id != localNewVal?.id
          ) {
            console.log("ADDED")
            await this.updateImgCanvas3d(localNewVal);
            // console.error("TODO: Actualizar imagen en canvas 3D");
          }
        }, 600);
    },
    installed(newVal, oldVal) {
      if (newVal == oldVal) return;

      if (newVal) {
        this.$emit("show-canvas");
      } else {
        this.$emit("close-canvas");
      }
    },
    async zona(newVal, oldVal) {
      if (newVal?.Posicion == oldVal?.Posicion) return;

      if (!newVal || !newVal?.Posicion) {
        console.warn("💻 ~ Necesitamos un valor de posicion correcto", newVal);
        return;
      }

      //TODO: Eliminar imagen anterior

      await this.setZone3d(newVal);
    },
    async idModel3D(newVal, oldVal) {
      if (newVal == oldVal) return;

      let result = await searchModelOnApiAndSetActive(newVal);

      if (!result) {
        console.warn("💻 ~ No hemos podido cargar el modelo correcto");
        this.disableCanvas3d();
      } else if (!this.installed) {
        await this.load3D();
      }
    }
  },
  async mounted() {
    await this.load3D();
  },
  beforeDestroy() {
    this.disableCanvas3d();
  },
  methods: {
    /**
     * Carga un modelo 3D en caso de que ese modelo tenga 3D
     */
    async load3D() {
      if (!this.hasModel3D) {
        console.warn("💻 ~ No tenemos un modelo 3D para cargar");
        return;
      }

      if (!this.installed && this.idModel3D) {
        try {
          await init3DCanvas(this.json, this.idModel3D);
        } catch (err) {
          console.error(err);
        }
        this.installed = true;

        await this.updateColorCanvas3d();
      }
    },

    /**
     * Actualiza el color del canvas 3D.
     *
     * @param {string|null} hexcodeColorSelected - El código hexadecimal del color seleccionado. Si no se proporciona, se utilizará this.hexcodeColorSelected.
     *
     * Este método actualiza el color del canvas 3D utilizando el código hexadecimal proporcionado. Si no se proporciona un código hexadecimal, se utiliza el valor de this.hexcodeColorSelected.
     * Se utiliza un temporizador para evitar múltiples actualizaciones rápidas. Si el color seleccionado es el mismo que el color activo, no se realiza ninguna acción.
     * Después de cargar el nuevo color, se actualiza la zona activa en el canvas 3D.
     */
    async updateColorCanvas3d(hexcodeColorSelected = null) {
      if (!hexcodeColorSelected) {
        hexcodeColorSelected = this.hexcodeColorSelected;
      }
      console.log(
        "🚀 ~ updateColorCanvas3d ~ hexcodeColorSelected:",
        hexcodeColorSelected
      );

      if (this.debouncedChangeColor) {
        clearTimeout(this.debouncedChangeColor);
      }

      this.debouncedChangeColor = setTimeout(async () => {
        if (hexcodeColorSelected == this.activeColorHexcode) return;
        await loadColor(hexcodeColorSelected);
        this.activeColorHexcode = hexcodeColorSelected;
        setTimeout(async () => {
          this.activeZone3D = await setActiveZone(this.zona);
        }, 1000);
      }, 500);
    },
    /**
     * Establece la zona 3D activa después de un retraso debounced.
     *
     * @param {Object} zone - La zona que se va a establecer como activa.
     * @returns {Promise<void>} - Una promesa que se resuelve cuando la zona activa ha sido establecida.
     *
     * Esta función establece la zona 3D activa después de un retraso de 450 ms para evitar múltiples cambios rápidos.
     * Si ya hay un cambio de zona en proceso, se cancela el anterior.
     */
    async setZone3d(zone) {
      if (this.activeCanvas3d) {
        if (this.debouncedChangeZone) {
          clearTimeout(this.debouncedChangeZone);
        }

        this.debouncedChangeZone = setTimeout(async () => {
          this.activeZone3D = await setActiveZone(zone);
          console.log("🚀 ~ zona ~ this.activeZone3D:", zone);
        }, 500);
      }
    },
    /**
     * Actualiza la imagen del canvas 3D con una nueva imagen en base64.
     *
     * @param {string} base64 - La imagen en formato base64 que se va a cargar en el canvas 3D.
     * @returns {Promise<void>} - Una promesa que se resuelve cuando la imagen ha sido cargada y el evento ha sido emitido.
     *
     * @emits save-design - Emite este evento cuando la imagen ha sido guardada exitosamente.
     */
    async updateImgCanvas3d({ base64, id }) {
      if (!this.activeZone3D) {
        console.warn(
          "💻 ~ No hemos podido cargar la zona correcta para el canvas 3d"
        );
        return;
      }
      this.imgInfoCanvas3d[id] = await uploadFile(base64, this.activeZone3D);
      console.log(
        "🚀 ~ updateImgCanvas3d ~ this.imgInfoCanvas3d:",
        this.imgInfoCanvas3d
      );

      if (base64) {
        this.$emit("save-design", {
          zoneToSave: this.zona
        });
      }
    },
    /**
     * Desactiva el canvas 3D y elimina todos los elementos dentro del div con id "canvas3d".
     *
     * Este método realiza las siguientes acciones:
     * 1. Establece la propiedad `installed` a `false`.
     * 2. Obtiene el elemento con id "canvas3d" del DOM.
     * 3. Si el elemento existe, elimina todos sus elementos hijos.
     * 4. Llama a la función `resetCanvas3d` para restablecer el estado del canvas 3D.
     */
    disableCanvas3d() {
      this.installed = false;

      // Eliminamos todos los elementos dentro del div canvas3d
      let canvas3d = document.getElementById("canvas3d");
      if (canvas3d) {
        while (canvas3d.firstChild) {
          canvas3d.removeChild(canvas3d.firstChild);
        }
      }

      resetCanvas3d();
    }
  }
};
</script>

<style lang="scss">
.oktextil-app .canvas3d-wrapper .v-image__image {
  filter: invert(1);
}

.canvas3d-wrapper {
  width: 100%;

  .v-overlay {
    max-height: 780px;
    top: 5px;

    .aviso-canvas-3d {
      color: var(--Color-Grey-800, #282828);
      font-family: $heading-font-family;
      font-size: 0.75rem;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
    }
  }
}
#canvas3d,
.canvas3d-wrapper {
  max-height: 785px;
  height: 100%;
}
</style>
