
/**
 * Url de la imagen por defecto
 * @type {String}
 * @constant
 */
const noImgUrl = "https://static.gorfactory.es/reprocesos/Imagenes/No_img.jpg";

/**
 * Tipos de ficheros válidos
 * @type {Array}
 * @constant
 * */
const fileTypeAvalibles = ["application/pdf", "image/svg+xml", "image/png", "image/jpeg"];

/**
 * 
 * @param {String} img Url de la imagen que queremos comprobar si es válida
 * @returns bool indicando si la imagen es válida
 */
const checkImageValid = (img) => img && img != noImgUrl;

/**
 * 
 * @param {Event} event Evento proveniente de un input de tipo file
 * @returns Promesa con la imagen parseada
 */
const parseImage = (event) => {
    if (event.target.files.length === 0) {
        console.warn("parseImage: no file in event")
        return;
    }

    const file = event.target.files[0];

    if (!file) {
        console.warn("parseImage: no file")
        return;
    }

    if (file.size > 15000000) {
        console.warn("parseImage: file too big")
        return;
    }

    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.addEventListener("loadend",
            async () => {
                let base64 = reader.result;

                if (!base64) {
                    console.warn("parseImage: no base64")
                    reject();
                }

                if (!fileTypeAvalibles.includes(file.type)) {
                    console.warn("parseImage: invalid file type")
                    reject();
                }

                const originalImage = base64;

                // Comprobamos si es un pdf
                if (file.type == "application/pdf") {
                    // Importamos la librería
                    const pdfjsLib = await import('pdfjs-dist/webpack');

                    // Preparamos el base64
                    base64 = atob(base64.replace("data:application/pdf;base64,", ""));

                    // Cargamos el documento PDF
                    const loadingTask = pdfjsLib.getDocument({ data: base64 });
                    const pdf = await loadingTask.promise;

                    // Renderizamos la primera página
                    const page = await pdf.getPage(1);
                    const scale = 1.5;
                    const viewport = page.getViewport({ scale: scale });

                    // Preparamos el canvas y su contexto
                    const canvas = document.createElement("canvas");
                    const context = canvas.getContext("2d");
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    // Renderizamos la página en el canvas
                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport
                    };
                    await page.render(renderContext).promise;

                    // Convertimos el canvas a una imagen PNG en base64
                    base64 = canvas.toDataURL("image/png");
                }

                resolve({ base64, originalImage })
            }, { once: true });
    });


}

export { checkImageValid, noImgUrl, fileTypeAvalibles, parseImage }