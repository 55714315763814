<template>
  <v-expansion-panels
    class="componentCartElement"
    accordion
    :disabled="loading"
  >
    <v-expansion-panel
      v-for="reproceso in cart"
      :id="'reproceso_expansion_' + reproceso.id"
      :key="reproceso.id"
      active-class="active-panel"
      hover
      :disabled="loading"
      @change="updateTimeoutHeight(reproceso.id)"
    >
      <v-expansion-panel-header>
        <span class="presupuesto-title">
          {{ $t("general.Presupuesto") }} {{ reproceso.id }}
        </span>

        <slot name="actions">
          <div class="max-width-fit-content">
            <span class="mr-2 modelo-name">
              {{ reproceso.modelos?.[0]?.nombre }}
            </span>
            <span class="mr-2 modelo-code">
              {{ reproceso.modelos?.[0]?.modelCode }}
            </span>
            <b class="mr-2 price-total">
              {{ correct2Decimals(reproceso.total) }}{{ currencySymbol }}
            </b>
            <v-btn
              v-if="!disableDelete && expandedId != reproceso.id"
              :loading="loadingDelete[reproceso.id] || loading"
              :disable="
                loadingPrint[reproceso.id] ||
                loadingDelete[reproceso.id] ||
                loading
              "
              icon
              @click="deleteReprocesoLocal(reproceso)"
            >
              <v-icon left size="20" class="mx-0">$fi-trash-2</v-icon>
            </v-btn>
          </div>
        </slot>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row
          v-for="r in reproceso.reprocesos"
          :key="r.id"
          class="px-2 zones-description"
        >
          <v-col cols="2">
            <span class="zona-name">
              {{ $t(`Zonas.${r.zona.replace("_", " ")}`) }}
            </span>
          </v-col>
          <v-col class="ellipse-description zona-articulos" cols="8">
            {{ getArticulosFromReprocesos(r) }}
          </v-col>
          <v-col class="pl-5">
            <span class="zona-precio">
              {{ correct2Decimals(r.total) }}{{ currencySymbol }}
            </span>
          </v-col>
        </v-row>
        <template
          v-if="
            reproceso.manipulados &&
            reproceso.manipulados.articulos &&
            reproceso.manipulados.articulos != 0
          "
        >
          <v-row
            v-for="r in reproceso.manipulados.articulos"
            :key="r.id"
            class="px-2 zones-description"
          >
            <v-col cols="2">
              <span class="zona-name">
                {{ $t(`Tecnicas.MANIPULADOS`) }}
              </span>
            </v-col>
            <v-col class="ellipse-description zona-articulos" cols="8">
              {{ getArticulosFromReprocesos(r) }} 
            </v-col>
            <v-col class="pl-5">
              <span class="zona-precio">
                {{ correct2Decimals(r.total) }}{{ currencySymbol }}
              </span>
            </v-col>
          </v-row>
        </template>

         <template
          v-if="
            reproceso.etiquetas && reproceso.etiquetas.length != 0
          "
        >
          <v-row
            v-for="r in reproceso.etiquetas"
            :key="r.id"
            class="px-2 zones-description"
          >
            <v-col cols="2">
              <span class="zona-name">
                {{ $t(`configuracion.ReprocesosGenerales.Etiquetas`) }}
              </span>
            </v-col>
            <v-col class="ellipse-description zona-articulos" cols="8">
              {{ getArticulosFromReprocesos(r) }}
            </v-col>
            <v-col class="pl-5">
              <span class="zona-precio">
                {{ correct2Decimals(r.total) }}{{ currencySymbol }}
              </span>
            </v-col>
          </v-row>
        </template>

        <!-- <v-row>
          <v-col>
            <v-card :disabled="loading">
              <v-card-title class="title-list py-1 text-wrap">
                <b>{{ $t("presupuesto.fichaReprocesos.Productos").toUpperCase() }}</b><v-spacer></v-spacer>
                {{ correct2Decimals(calculateTotal(reproceso.productos))}}{{ currencySymbol }}
                </v-card-title>
              <v-divider></v-divider>
              <v-list>
                <v-list-item
                  v-for="producto in productWithCorrectRound(reproceso.productos)"
                  :key="producto.id"
                >
                  <v-list-item-content>
                    <v-list-item-title class="text-wrap"
                      ><b>[{{ producto.reference }}]</b>
                      {{ producto.description }}</v-list-item-title
                    >
                    <v-list-item-subtitle class="font-size-17 text-wrap"
                      >{{ producto.quantity }}x{{ producto.price
                      }}{{ currencySymbol }} = {{ producto.total
                      }}{{ currencySymbol }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="reproceso.manipulados && reproceso.manipulados.articulos && reproceso.manipulados.articulos != 0"
          >
            <v-card :disabled="loading">
              <v-card-title class="title-list py-1"
                ><b>{{ $t("configuracion.ReprocesosGenerales.Manipulados").toUpperCase() }}</b><v-spacer></v-spacer>
                {{ calculateTotal(reproceso.manipulados.articulos)
                }}{{ currencySymbol }}</v-card-title
              >
              <v-divider></v-divider>
              <v-list>
                <v-list-item
                  v-for="articulo in reproceso.manipulados.articulos"
                  :key="articulo.id"
                >
                  <v-list-item-content>
                    <v-list-item-title class="text-wrap">
                      <b>[{{ articulo.id }}] {{ articulo.reference }}</b> -
                      {{ articulo.description }}</v-list-item-title
                    >
                    <v-list-item-subtitle class="font-size-17"
                      >{{ articulo.quantity }}x{{ correct4Decimals(articulo.price)
                      }}{{ currencySymbol }} = {{ correct2Decimals(articulo.total)
                      }}{{ currencySymbol }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col v-if="reproceso.etiquetas && reproceso.etiquetas.length != 0">
            <v-card :disabled="loading">
              <v-card-title class="title-list py-1 text-wrap"
                ><b>{{ $t("configuracion.ReprocesosGenerales.Etiquetas").toUpperCase() }}</b><v-spacer></v-spacer>
                {{ calculateTotal(reproceso.etiquetas)
                }}{{ currencySymbol }}</v-card-title
              >
              <v-divider></v-divider>
              <v-list>
                <v-list-item
                  v-for="etiqueta in reproceso.etiquetas"
                  :key="etiqueta.id"
                >
                  <v-list-item-content>
                    <v-list-item-title class="text-wrap"
                      ><b>{{ etiqueta.tecnica }}</b> {{ correct2Decimals(etiqueta.total)
                      }}{{ currencySymbol }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card :disabled="loading">
              <v-card-title class="title-list py-1"
                ><b>{{ $t("presupuesto.fichaReprocesos.PrecioReprocesos").toUpperCase() }}</b><v-spacer></v-spacer>
                {{ calculateReprocesosOnly(reproceso.reprocesos)
                }}{{ currencySymbol }}
            </v-card-title>
              <v-divider></v-divider>
              <v-list>
                <v-list-item v-for="r in reproceso.reprocesos" :key="r.id">
                  <v-list-item-content>
                    <v-list-item-title class="text-wrap"
                      ><b
                        >{{ $t(`Zonas.${r.zona.replace("_", " ")}`) }} -
                        {{ translateTecnica(r.tecnica) }}:</b
                      >
                      {{ correct2Decimals(r.total) }}{{ currencySymbol }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row> -->
        <div class="mt-8 actions-btn">
          <v-btn
            :loading="loadingPrint[reproceso.id] || loading"
            :disable="
              loadingPrint[reproceso.id] ||
              loadingDelete[reproceso.id] ||
              loading
            "
            class="mr-3"
            outlined
            @click="printReprocesoLocal(reproceso)"
          >
            <v-icon left size="20" class="mr-2">
              mdi-arrow-down-bold-circle
            </v-icon>
            <span>{{ $t("general.Presupuesto") }}</span>
          </v-btn>
          <v-btn
            v-if="!disableDelete"
            :loading="loadingDelete[reproceso.id] || loading"
            :disable="
              loadingPrint[reproceso.id] ||
              loadingDelete[reproceso.id] ||
              loading
            "
            dark
            @click="deleteReprocesoLocal(reproceso)"
          >
            <v-icon left size="20" class="mr-2">$fi-trash-2</v-icon>
            <span>{{ $t("configuracion.ReprocesosGenerales.Eliminar") }}</span>
          </v-btn>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    cart: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    disableDelete: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loadingPrint: {},
      loadingDelete: {},
      expandedId: null
    };
  },
  computed: {
    ...mapGetters("config", ["translateTecnica", "clientCartAvalible"]),
    ...mapGetters("modelo", ["currencySymbol"])
  },
  methods: {
    ...mapActions("carrito", [
      "printReproceso",
      "printReprocesoClient",
      "deleteLineComercialCart",
      "deleteLineClientCart"
    ]),
    ...mapActions("config", ["updateHeightFrame"]),
    productWithCorrectRound(value) {
      return value.map(producto => {
        return {
          ...producto,
          total: this.correct2Decimals(producto.total),
          price: this.correct4Decimals(producto.price)
        };
      });
    },
    correct2Decimals(value) {
      return value?.toLocaleString(this.$i18n, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },
    correct4Decimals(value) {
      return value?.toLocaleString(this.$i18n, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 4
      });
    },
    updateTimeoutHeight(reprocesoId) {
      if (reprocesoId == this.expandedId) {
        this.expandedId = null;
      } else {
        this.expandedId = reprocesoId;
      }
      setTimeout(() => {
        this.updateHeightFrame();
        if (reprocesoId) {
          const element = this.$el.querySelector(
            "#reproceso_expansion_" + reprocesoId
          );
          if (element) {
            element.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
              inline: "nearest"
            });
          }
        }
      }, 350);
    },
    calculateReprocesosOnly(reprocesos) {
      return reprocesos.reduce((total1, r) => {
        let price = r.articulos.reduce((total, articulo) => {
          return total + articulo.total;
        }, 0);
        return total1 + price;
      }, 0);
    },
    calculateTotal: function (articulos) {
      return this.correct2Decimals(
        articulos.reduce((total, articulo) => {
          return total + articulo.total;
        }, 0)
      );
    },
    getListModelCode: function (reproceso) {
      let max = 2;
      return reproceso.modelos
        .map(modelo => {
          // Si se han añadido 3 modelos, se indica unos puntos suspensivos y no se escriben más
          if (max < 0) return;
          if (max-- <= 0) return "...";
          return modelo.nombre + " " + modelo.modelCode;
        })
        .join(", ");
    },
    getArticulosFromReprocesos(r) {
      if (r && r.articulos) {
        return r.articulos
          .map(articulo => {
            let description = articulo.description.toLowerCase();
            return description.charAt(0).toUpperCase() + description.slice(1);
          })
          .join(", ");
      }

      if(r.description){
        return r.description.charAt(0).toUpperCase() +  r.description.toLowerCase().slice(1);;
      }

      return "";
    },
    async printReprocesoLocal(reproceso) {
      this.loadingPrint[reproceso.id] = true;
      this.$emit("loadingPrint", true);
      this.loadingPrint = { ...this.loadingPrint };

      let printFunction = this.clientCartAvalible
        ? this.printReprocesoClient(reproceso.id)
        : this.printReproceso(reproceso.id);

      printFunction.finally(() => {
        this.loadingPrint[reproceso.id] = false;
        this.$emit("loadingPrint", false);
      });
    },
    async deleteReprocesoLocal(reproceso) {
      this.loadingDelete[reproceso.id] = true;
      this.$emit("loadingDelete", true);
      this.loadingDelete = { ...this.loadingDelete };

      let deleteFunction = this.clientCartAvalible
        ? this.deleteLineClientCart(reproceso.id)
        : this.deleteLineComercialCart(reproceso.id);

      deleteFunction.finally(() => {
        this.loadingDelete[reproceso.id] = false;
        this.$emit("loadingDelete", false);
      });
    }
  }
};
</script>

<style lang="scss">
.ellipse-description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.zones-description {
  color: var(--Color-Grey-600, #787878);
  text-overflow: ellipsis;
  font-family: $heading-font-family;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.cotizacion-resumen-linea {
  padding-left: 8px !important;
}
.componentCartElement {
  .actions-btn {
    display: flex;
    justify-content: end;

    button {
      font-size: 12px;
    }
  }

  .v-expansion-panel::before {
    box-shadow: none;
  }

  .v-expansion-panel-header {
    padding: 0px 24px;
    min-height: 40px;
    font-size: 0.875rem;

    .v-btn.v-btn--icon {
      max-width: 36px;

      &:hover {
        color: red;
      }
    }
  }

  .col {
    padding: 0 4px;
    margin-top: 10px;
  }

  .v-list-item {
    min-height: 40px;
  }

  .v-list-item__title {
    font-size: 0.875rem;
  }

  .v-list-item__content {
    padding: 0;
  }

  .v-list-group {
    background: var(--Color-White, #fff);
  }

  .presupuesto-title {
    color: var(--Color-Black, #121212);
    font-family: $heading-font-family;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .modelo-name,
  .modelo-code {
    color: var(--Color-Grey-600, #787878);
    font-family: $heading-font-family;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .price-total {
    color: var(--Color-Black, #121212);
    font-family: $heading-font-family;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    min-width: 55px;
    display: inline-block;
    text-align: right;
  }
}

.v-list-group__header {
  max-height: 32px;
  min-height: 32px !important;
  padding: 4px 8px;
}

.max-width-fit-content {
  max-width: fit-content;
}

.title-list {
  font-size: 1.05rem;
}

// .active-panel{
//   box-shadow: 0px 3px 1px -2px #265a28, 0px 2px 2px 0px #265a28, 0px 1px 5px 0px #265a28;
// }

#carrito
  > div.item.shark-2
  > div
  > div.clientCartExpansionPanel
  > div
  > div
  > div
  > div {
  padding: 0 !important;
}

.container.body-dialog .col {
  max-width: 441px;
}
</style>
