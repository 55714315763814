<template>
  <div>
    <v-row v-show="canvas" :loading="loadingDesign">
      <v-col>
        <canvas
          id="canvas-personalizador"
          ref="canvas"
          :class="{ hover }"
          width="460"
          height="460"
          @mouse:over="hover = true"
          @mouse:out="hover = false"
        ></canvas>
        <canvas id="static-canvas" class="d-none"></canvas>
        <!-- Checkbox para mostrar el fondo especial -->
        <v-checkbox
          v-if="avaliblePreview"
          v-model="useBackgroundPreview"
          :label="$t('general.Previsualizar')"
          class="mt-2"
          hide-details
        ></v-checkbox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import CotizadorBtn from "@/components/basic/CotizadorBtn.vue";
// import ColorBall from "@/components/basic/ColorBall.vue";
import { mapGetters, mapActions } from "vuex";
import {
  fabric,
  generateSvgFromJson
  // setBackgroundToSvg
} from "@/utils/fabricUtils.js";
import {
  // checkImageValid,
  noImgUrl
} from "@/utils/imageUtils.js";

export default {
  components: {
    // CotizadorBtn,
    // ColorBall
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    zona: {
      type: Object,
      required: true
    },
    tecnica: {
      type: String,
      default: ""
    },
    dialog: {
      type: Boolean,
      default: false
    },
    img: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      canvas: null,
      defaultConfig: {
        cornerColor: this.$vuetify.theme.currentTheme.primary,
        stroke: this.$vuetify.theme.currentTheme.primary,
        strokeWidth: 2
      },
      useBackgroundPreview: false,
      hover: false,
      timeout: null,
      delay: 2000
    };
  },
  computed: {
    ...mapGetters("carrito", [
      "totalUnidades",
      "savedDesign",
      "loadingDesign",
      "sortColorAvalibleAndWithQuantity",
      "actualListDesign",
      "filteredListDesignInCart",
      "numDesignForEachPosition",
      "numDesignForEachPreviewRule"
    ]),
    ...mapGetters("modelo", ["newComputedImgUrl", "hasModel3D"]),
    ...mapGetters("config", ["colorSelected"]),
    isSavedDesign() {
      // Si actualListDesign contiene el id actual es que esta guardado
      return (
        this.actualListDesign &&
        this.actualListDesign.findIndex(
          x => x.id == this.zona.id && this.zona.Posicion == x.posicion
        ) > -1
      );
    },
    avaliblePreview() {
      if (this.zona.PreviewRule) {
        return this.isSavedDesign
          ? this.numDesignForEachPreviewRule[this.zona.PreviewRule] > 1
          : this.numDesignForEachPreviewRule[this.zona.PreviewRule] > 0;
      }
      return this.isSavedDesign
        ? this.numDesignForEachPosition[this.zona.Zona] > 1
        : this.numDesignForEachPosition[this.zona.Zona] > 0;
    },
    actualDesign() {
      return this.img?.base64 ?? null;
    }
  },
  watch: {
    modelo(newVal, oldVal) {
      if (newVal == oldVal) return;

      this.localdialog = false;
      // this.tabs = null;
      if (this.canvas) {
        this.canvas.dispose();
        this.canvas = null;
      }
      this.resetImg();
    },
    async zona(newVal, oldVal) {
      if (newVal == oldVal) return;

      this.localdialog = false;
      // await this.emitSaveDesign(oldVal);
      // this.tabs = null;
      this.useBackgroundPreview = false;
      // if (this.canvas) {
      //   this.canvas.dispose();
      //   this.canvas = null;
      // }

      // await this.tryInitializeNewCanvasFromDesignList();
      this.cancelDesign();
      this.$emit("close-canvas");
    },
    async useBackgroundPreview(newVal, oldVal) {
      if (newVal == oldVal) return;

      // Si no hay canvas salimos
      if (!this.canvas) return;

      if (!this.avaliblePreview) return;

      if (newVal) {
        await this.trySetPreviewAsBackground();
      } else {
        await this.setBackgroundImg();
        this.canvas.renderAll();
      }
    },
    colorSelected(newVal) {
      if (this.canvas) {
        this.selectColor(newVal);
      }
    },
    async img(newVal, oldVal) {
      console.log(
        "🚀 ~ file: designModal.vue:174 ~ img ~ newVal?.id == oldVal?.id && this.canvas:",
        newVal?.id,
        oldVal?.id,
        this.canvas,
        newVal?.id == oldVal?.id && this.canvas
      );
      if (newVal?.id == oldVal?.id && this.canvas) return;

      if (newVal?.id && !this.canvas) {
        await this.tryInitializeNewCanvasFromDesignList();

        // Si no hemos podido recuperar la imagen y no existe ninguna configuración para la zona actual
        if (!this.canvas) {
          let design = this.searchFromDesignList();

          if (design) {
            this.deleteZoneCustomDesign(design);
          }
          await this.initializeNewCanvas();
        }
      } else {

        if (newVal.observaciones) {
          this.emitSaveDesign();
        }

        console.log("CERRAR");
        this.cancelDesign();
        this.$emit("close-canvas");
      }
    }
  },
  methods: {
    ...mapActions("carrito", [
      "saveZoneCustomDesign",
      "deleteZoneCustomDesign",
      "addLoadingDesign",
      "removeLoadingDesign"
    ]),
    ...mapActions("config", ["setColor"]),
    closeDialog() {
      this.localdialog = false;
    },
    // Función que se ejecuta cuando se modifica el canvas, para evitar que se pueda pasar el pedido hasta que el diseño se haya guardado
    handleCanvasModification() {
      this.addLoadingDesign();

      // Si ya hay un temporizador en marcha, cancelarlo
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.removeLoadingDesign();
      }

      // Configurar un nuevo temporizador
      this.timeout = setTimeout(async () => {
        // Hacemos el emit para que se guarde el diséño
        await this.emitSaveDesign();
        this.removeLoadingDesign();
        this.timeout = null;
      }, this.delay);
    },

    async emitSaveDesign(zonaToSave) {
      if (!this.canvas) {
        console.warn("No canvas to save design");

        if (this.img?.observaciones) {
          this.$emit("save-design", {
            zoneToSave: zonaToSave ?? this.zona,
            canvas2dInfo: null
          });
        }
        return;
      }

      console.log(
        "🚀 ~ file: designModal.vue:234 ~ emitSaveDesign ~ this.canvas:",
        this.canvas
      );

      const hiddenCanvas = new fabric.Canvas(null, {
        width: this.canvas.width,
        height: this.canvas.height
      });

      // Clonar el estado actual del canvas visible al canvas oculto
      hiddenCanvas.loadFromJSON(this.canvas.toJSON(), async () => {
        // Realiza las modificaciones necesarias en el canvas oculto
        if (this.useBackgroundPreview)
          await this.setBackgroundImg({ canvas: hiddenCanvas });

        hiddenCanvas.forEachObject(obj => obj.set({ strokeWidth: 0 }));
        hiddenCanvas.renderAll();

        // Guardar el diseño
        this.$emit("save-design", {
          zoneToSave: zonaToSave ?? this.zona,
          canvas2dInfo: {
            canvasJson: hiddenCanvas.toJSON(),
            svg: hiddenCanvas.toSVG({
              suppressPreamble: true,
              width: "100%",
              height: "100%"
            })
          }
        });

        // Limpiar el hiddenCanvas
        hiddenCanvas.clear();
        hiddenCanvas.dispose();
      });
    },
    async initializeNewCanvas(design) {
      this.$emit("show-canvas");
      if (!this.$refs.canvas) {
        console.error("No se ha podido inicializar el canvas");
        return;
      }

      if (this.totalUnidades <= 0) {
        console.error("Debe haber al menos una unidad para poder personalizar");
        return;
      }

      if (this.canvas) {
        console.warn("Ya existe un canvas");
        return;
      }

      console.log(
        "🚀 ~ file: designModal.vue:291 ~ initializeNewCanvas ~ this.$refs.canvas:",
        this.$refs.canvas
      );
      if (!this.$refs.canvas) {
        console.error("No hemos encontrado donde montar el canvas");
        return;
      }

      if (!this.canvas) {
        this.canvas = new fabric.Canvas(this.$refs.canvas);
        this.canvas.selection = false;
        this.canvas.uniScaleTransform = true;
      }

      this.canvas.on("object:modified", this.handleCanvasModification);
      this.canvas.on("object:added", this.handleCanvasModification);

      if (design) {
        console.log(
          "🚀 ~ file: designModal.vue:294 ~ initializeNewCanvas ~ design:",
          design
        );

        if (!design.canvasJson.objects?.length) {
          console.warn("No hay imagen en el diseño cargado")
        }

        design.canvasJson.objects.forEach(obj => {
          if (!Object.prototype.hasOwnProperty.call(obj, "left") || !Object.prototype.hasOwnProperty.call(obj, "top")) {
            console.error("Objeto inválido en el JSON:", obj);
          }
        });

        await new Promise(resolve => {
          this.canvas.loadFromJSON(design.canvasJson, aux => {
            console.log(
              "🚀 ~ file: designModal.vue:298 ~ this.canvas.loadFromJSON ~ aux:",
              aux
            );
            this.canvas.renderAll.bind(this.canvas);

            this.canvas.forEachObject(obj => {
              obj.set(this.defaultConfig);
            });

            this.canvas.renderAll();
            resolve();
          });
        });
      } else {
        var self = this;

        // this.selectedColor = this.sortColorAvalibleAndWithQuantity[0]?.code;

        await this.setBackgroundImg();

        await new Promise((resolve, reject) => {
          fabric.Image.fromURL(this.actualDesign, function (savedDesignImage) {
            // Si esta imagen ya se encuentra en el canvas evitamos que se añade otra vez
            if (
              self.canvas
                .getObjects()
                .findIndex(x => x.src == savedDesignImage.src) > -1
            ) {
              reject("Image already exists in canvas");
            } else {
              savedDesignImage.scaleToWidth(150);
              const resultHeight =
                150 * (savedDesignImage.height / savedDesignImage.width);
              let config = {
                ...self.defaultConfig,
                left: self.canvas.width / 2 - 150 / 2,
                top: self.canvas.height / 2 - resultHeight / 2
              };
              savedDesignImage.set(config);
              self.canvas.add(savedDesignImage);
              self.canvas.renderAll();
              resolve();
            }
          });
        });
      }

      console.log(
        "🚀 ~ file: designModal.vue:331 ~ initializeNewCanvas ~ this.canvas:",
        this.canvas
      );
      if (this.avaliblePreview) this.useBackgroundPreview = true; //TODO: es true
    },
    async setBackgroundImg({ image = null, canvas = null } = {}) {
      if (!canvas) {
        canvas = this.canvas;
      }
      if (!canvas) {
        console.warn("No canvas to set background");
        return;
      }
      this.addLoadingDesign();

      try {
        let defaultImage = noImgUrl;

        if (image) {
          console.log(
            "🚀 ~ file: designModal.vue:344 ~ setBackgroundImg ~ image:",
            image
          );
          if (image?.startsWith("http")) {
            defaultImage = image;
          } else {
            const { objects, options } = await new Promise(
              (resolve, reject) => {
                fabric.loadSVGFromString(image, (objects, options) => {
                  if (objects) {
                    resolve({ objects, options });
                  } else {
                    reject("Error loading SVG");
                  }
                });
              }
            );

            const obj = fabric.util.groupSVGElements(objects, options);
            obj.scaleToWidth(460);
            obj.set(this.defaultConfig);
            canvas.setBackgroundImage(obj, canvas.renderAll.bind(canvas));
            return;
          }
        } else {
          if (this.zona) {
            defaultImage = this.newComputedImgUrl(
              this.zona,
              this.colorSelected
            );
          }
        }

        const defaultImageObj = await new Promise((resolve, reject) => {
          fabric.Image.fromURL(defaultImage, defaultImageObj => {
            if (defaultImageObj) {
              defaultImageObj.scaleToWidth(460);
              resolve(defaultImageObj);
            } else {
              reject("Error loading default image");
            }
          });
        });
        canvas.setBackgroundImage(
          defaultImageObj,
          canvas.renderAll.bind(canvas)
        );
      } finally {
        this.removeLoadingDesign();
      }
    },

    async selectColor(color) {
      this.setColor(color);
      if (!this.hasModel3D) {
        if (this.useBackgroundPreview && this.canvas) {
          await this.trySetPreviewAsBackground();
        } else {
          await this.setBackgroundImg();
          this.canvas.renderAll();
        }
      }
    },
    cancelDesign() {
      // this.dialog = false;
      // this.tabs = null;
      if (this.canvas) {
        this.canvas.dispose();
        // this.canvas.destroy();
        this.canvas = null;
      }
    },
    async tryInitializeNewCanvasFromDesignList() {
      if (this.actualListDesign && this.actualListDesign.length > 0) {
        // Buscamos si alguno de los diseños coincide en Id
        let design = this.searchFromDesignListWithImage();
        console.log(
          "🚀 ~ file: designModal.vue:403 ~ tryInitializeNewCanvasFromDesignList ~ design:",
          design
        );

        if (design) {
          /// Cargamos el canvas
          if (design.design) await this.initializeNewCanvas(design);
        } else {
          // Avisamos que se cierra el canvas
          this.cancelDesign();
          this.$emit("close-canvas");
        }
      } else {
        this.cancelDesign();
        this.$emit("close-canvas");
      }
    },
    searchFromDesignListWithImage() {
      return this.searchFromDesignList({ withImage: true });
    },
    searchFromDesignList({ withImage = false } = {}) {
      // Buscamos si alguno de los diseños coincide en Id
      let design = this.actualListDesign.find(
        x =>
          x.id == this.zona.id &&
          this.zona.Posicion == x.posicion &&
          x.mode == "2d" &&
          (!withImage || this.img?.id == x.idImg)
      );

      return design;
    },
    async trySetPreviewAsBackground() {
      // De la lista de filtrados nos quedamos únicamente con los que tienen la misma posicion o tiene el mismo PreviewRule
      let filteredList = this.filteredListDesignInCart.filter(
        x =>
          x.id != this.zona.id &&
          (this.zona.Posicion == x.posicion ||
            (this.zona.PreviewRule &&
              this.zona.PreviewRule == x.previewRule)) &&
          x.mode == "2d"
      );
      console.log(
        "🚀 ~ file: designModal.vue:440 ~ trySetPreviewAsBackground ~ filteredList:",
        filteredList
      );

      // Si no hay ninguno salimos
      if (filteredList.length == 0) {
        await this.setBackgroundImg();
        this.canvas.renderAll();
        return;
      }

      let canvasJson = filteredList.reduce((acc, curr, index) => {
        if (index === 0) {
          return curr.canvasJson;
        } else {
          acc.objects = acc.objects.concat(curr.canvasJson.objects);
          return acc;
        }
      }, {});
      console.log(
        "🚀 ~ file: designModal.vue:455 ~ trySetPreviewAsBackground ~ canvasJson:",
        canvasJson
      );

      await generateSvgFromJson(canvasJson).then(svg => {
        console.log(
          "🚀 ~ file: designModal.vue:459 ~ awaitgenerateSvgFromJson ~ svg:",
          svg
        );
        this.setBackgroundImg({ image: svg });
        this.canvas.renderAll();
      });
    }
  }
};
</script>

<style lang="scss">
$body-font-family: "Cairo";
$title-font: "Outfit";

.disenoBody {
  line-height: 15px;
  letter-spacing: 0px;
  .subtitle {
    font-size: 14px;
  }

  .infoDesign {
    font-size: 12px;
  }

  .imgContainer {
    max-width: 240px;
  }

  .v-btn {
    width: 100%;
  }

  .fileBtn {
    width: 100%;
    height: 100px !important;
    font-family: $title-font, sans-serif;
    font-size: 17px;
    line-height: 40px;
    font-weight: 500;
    text-transform: none !important;
    letter-spacing: 0 !important;
  }
}

.canvas-container {
  &:hover {
    // border: 1px solid black;
    // border-radius: 4px !important;
    // height: 462px !important;
    // width: 462px !important;
  }
}
</style>
