var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tooltip',{attrs:{"disabled":!_vm.tooltip,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-badge',{attrs:{"color":"black","content":_vm.quantity > 99999 ? '99.999+' : _vm.quantity,"overlap":"","bordered":"","disabled":_vm.disabled,"value":_vm.showBadge && _vm.quantity > 0}},[_c('div',_vm._g(_vm._b({staticClass:"circle-selector-parent",class:{ 'disabled-color': _vm.disabled }},'div',attrs,false),on),[_c('div',{staticClass:"colorBall color-img-container",class:{ 'selected-color': _vm.selected },on:{"click":function($event){return _vm.changeColor()}}},[(_vm.colorImageURL)?_c('div',{staticClass:"color-img",class:{
              white: _vm.colorList[0] == '#FFFFFF',
              'white-ball': _vm.whiteBorder
            },style:(`background-image: url('${_vm.colorImageURL}'); background-size: cover; width: ${_vm.squareWidth}px; height: ${_vm.squareHeight}px;`)},[_c('span',{staticClass:"spanColor",style:({
                color: _vm.pickTextColorBasedOnBgColorSimple,
                ' font-size': `${_vm.squareWidth * 0.6}px`
              })})]):(_vm.colorList.length === 1)?_c('div',{staticClass:"colorSquare filtro-colores animsition-link",class:[_vm.circleClass, _vm.whiteBorder ? 'white-ball' : undefined],style:(`display:block;background-color:${_vm.colorList[0]}; width: ${_vm.squareWidth}px; height: ${_vm.squareHeight}px;`),attrs:{"href":"javascript:;"}},[_c('span',{staticClass:"spanColor",style:({
                color: _vm.pickTextColorBasedOnBgColorSimple,
                ' font-size': `${_vm.squareWidth * 0.6}px`,
                'pointer-events': 'none'
              })})]):(_vm.colorList.length === 2)?_c('div',{staticClass:"colorSquare multColorSquare filtro-colores animsition-link",class:[_vm.circleClass, _vm.whiteBorder ? 'white-ball' : undefined],style:(`display:block;background: linear-gradient(-45deg, ${_vm.colorList[1]} 0%, ${_vm.colorList[1]} 49%,${_vm.colorList[0]} 50%,${_vm.colorList[0]} 100% ); width: ${_vm.squareWidth}px; height: ${_vm.squareHeight}px; clip-path: circle(50%);`),attrs:{"href":"javascript:;"}},[_c('span',{staticClass:"spanColor",style:({
                color: _vm.pickTextColorBasedOnBgColorSimple,
                ' font-size': `${_vm.squareWidth * 0.6}px`,
                'pointer-events': 'none'
              })})]):(_vm.colorList.length === 3)?_c('div',{staticClass:"colorSquare multColorSquare filtro-colores animsition-link",class:[_vm.circleClass, _vm.whiteBorder ? 'white-ball' : undefined],style:(`display:block;background: linear-gradient(-45deg, ${_vm.colorList[2]} 0%, ${_vm.colorList[2]} 32%,${_vm.colorList[1]} 33%, ${_vm.colorList[1]} 65%, ${_vm.colorList[0]} 66%,${_vm.colorList[0]} 100% ); width: ${_vm.squareWidth}px; height: ${_vm.squareHeight}px; clip-path: circle(50%);`),attrs:{"href":"javascript:;"}},[_c('span',{staticClass:"spanColor",style:({
                color: _vm.pickTextColorBasedOnBgColorSimple,
                ' font-size': `${_vm.squareWidth * 0.6}px`,
                'pointer-events': 'none'
              })})]):_vm._e()]),(_vm.label)?_c('div',{staticClass:"circle-label"},[_c('span',{},[_vm._v(_vm._s(_vm.label))])]):_vm._e()])])]}}])},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }