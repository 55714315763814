<template>
  <v-dialog
    ref="cartDialog"
    v-model="localDialog"
    scrollable
    max-width="980px"
    class="dialog-z-index-general"
  >
    <v-card>
      <v-card-title
        class="text-h5 d-flex flex-column justify-center align-center header-title"
      >
        {{ $t("youReprocesos.headerDialogYouReprocesos") }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="d-flex flex-column justify-center mt-5 pb-0">
        <v-container>
          <v-row>
            <v-col cols="4" class="d-flex justify-center align-center text-center">
             <span>
                  {{ $t("youReprocesos.nameAndMailRequired") }}
                </span>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="8">
              <v-text-field
                v-model="name"
                class="pr-3"
                :label="$t('presupuesto.fichaReprocesos.Nombre')"
                outlined
                dense
                hide-details
                :error="emptyName"
              ></v-text-field>
              <v-text-field
                v-model="mail"
                class="pr-3 mt-2"
                :label="$t('cart.Email')"
                outlined
                type="email"
                dense
                hide-details
                :error="emptyMail"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="7">
              <v-textarea
                v-model="observaciones"
                class="mt-1 pl-3"
                :label="$t('presupuesto.Observaciones')"
                filled
                dense
                auto-grow
                no-resize
                rows="3"
                counter="250"
                :rules="[
                  observacionesValid ||
                    $t('cart.MaxInputObservaciones', { numCaracter: 250 })
                ]"
              />
            </v-col>
            <v-col cols="5">
              <v-textarea
                v-model="referencia"
                class="mt-1 pr-3"
                 :label="$t('presupuesto.fichaReprocesos.Referencia')"
                filled
                dense
                auto-grow
                no-resize
                rows="3"
                counter="100"
                :rules="[
                  referenceValid ||
                    $t('cart.MaxInputObservaciones', { numCaracter: 100 })
                ]"
              />
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="pt-0">
              <address-creator
                v-model="customAddress"
                class="pt-0"
                :no-error="true"
                :show-email="false"
              ></address-creator>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions>
         <cotizador-btn
          class="my-2"
            :on="{
              click: () => {
                localDialog = false;
              }
            }"
          >
            {{ $t("general.Cancelar") }}
          </cotizador-btn>
          <v-spacer></v-spacer>
          <cotizador-btn
          class="my-2"
          :disabled="emptyMail || emptyName || !observacionesValid || !referenceValid"
          :success=true
            :on="{
              click: () => {
                confirmDialog();
              }
            }"
          >
            {{ $t('general.Aceptar') }}
          </cotizador-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AddressCreator from "@/components/basic/AddressCreator.vue";
import CotizadorBtn from "@/components/basic/CotizadorBtn.vue";

export default {
  components: {
    AddressCreator,
    CotizadorBtn
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      customAddress: null,
      localDialog: this.dialog,

      mail: "",
      name: "",
      observaciones: "",
      referencia: ""
    };
  },
  computed: {
    emptyMail() {
      return !this.mail || this.mail == "" || !this.mail.includes("@");
    },
    emptyName() {
      return !this.name || this.name == "";
    },
    observacionesValid() {
      return this.observaciones.length <= 250;
    },
    referenceValid() {
      return this.referencia.length <= 100;
    }
  },
  watch: {
    dialog(newVal) {
      if (newVal != this.localDialog) this.localDialog = newVal;
    },
    localDialog(newVal) {
      this.$emit("update:dialog", newVal);
    }
  },
  methods: {
    confirmDialog() {
      this.$emit("confirm", {
        name: this.name,
        mail: this.mail,
        observaciones: this.observaciones,
        referencia: this.referencia,
        address: this.customAddress
      });

      this.customAddress = null;
        this.localDialog = false;

      this.mail = "";
      this.name = "";
      this.observaciones = "";
      this.referencia = "";
      this.localDialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.header-title {
  background-color: var(--v-primary-base);
  color: var(--v-colorTextBtn-base, white);
  font-weight: bold;
  font-size: 15px;
  font-family: Arial;
}
</style>
