<template>
  <general-modal persistant :dialog="localeAddZoneDialog" width="900px">
    <v-card class="selector-zones productModal mt-0">
      <v-card-title class="pa-0">
          <v-toolbar extended extension-height="0" height="fit-content"  dark color="black">
          <v-toolbar-title
            class="productos-titulo d-flex justify-space-between"
          >
            <v-container fluid>
              <v-row>
                <v-col cols="8" class="d-flex align-center py-0" >
                  <v-row>
                     <v-col cols="12" md="6" class="d-flex align-center py-0">
                  <span class="modelo-name">
                    {{ $t(`MODELS.${modelCode}.NAME`) }}
                  </span>
                </v-col>
                <v-col cols="12" md="6" class="d-flex justify-md-center align-center py-0">
                  <span>
                    {{ $t("general.Añadir zona") }}
                  </span>
                </v-col>
                  </v-row>
                </v-col>
               
                <v-col cols="4" class="d-flex flex-row-reverse pr-0 py-0">
                  <div class="left-border" @click="localeAddZoneDialog = false">
                    <v-btn icon dark>
                      <v-icon xl>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-toolbar-title>
          </v-toolbar>
        
      </v-card-title>
      <v-card-text>
        <v-select
          v-if="mode == 'listSelector'"
          v-model="selectedNewZone"
          :items="zonas"
        >
          <template slot="selection" slot-scope="data">
            {{ $t("Zonas." + formatZona(data.item.Zona)) }}
          </template>
          <template slot="item" slot-scope="data">
            {{ $t("Zonas." + formatZona(data.item.Zona)) }}
          </template>
        </v-select>
        <v-container v-else-if="mode == 'grid'" fluid>
          <v-row class="grid-zones">
            <v-col v-for="zona in cachedZonas" :key="zona.Zona" cols="12" md="3">
              <v-card
                :class="
                  zona.active
                    ? 'active'
                    : selectedNewZone != null
                      ? 'noActive'
                      : ''
                "
                @click="selectedNewZone = zona"
              >
                <v-card-title class="title-zone-selector">
                  {{ $t("Zonas." + formatZona(zona.Zona)) }}
                </v-card-title>
                <v-card-text>
                  <v-img
                    class="mt-0"
                    contain
                    :src="zona.imgUrl"
                    :lazy-src="zona.imgUrl"
                    style="background-color: white"
                  ></v-img>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="localeAddZoneDialog = false">
          {{ $t("general.Cancelar") }}
        </v-btn>
        <v-btn
          :disabled="!selectedNewZone"
          :color="selectedNewZone ? 'primary' : ''"
          @click="addZone"
        >
          {{ $t("general.Aceptar") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </general-modal>
</template>

<script>
import { mapGetters } from "vuex";

import { noImgUrl } from "@/utils/imageUtils";

import generalModal from "@/components/modales/generalModal.vue";

export default {
  components: {
    generalModal
  },
  props: {
    value: Boolean
  },
  data() {
    return {
      localeAddZoneDialog: false,
      selectedNewZone: null,
      mode: "grid",
      cachedZonas: []
    };
  },
  computed: {
    ...mapGetters("modelo", ["zonas", "newComputedImgUrl", "modelCode"]),
    ...mapGetters("config", ["colorSelected"]),
    someActive() {
      return this.selectedNewZone;
    },
    // computedZonas() {
    //   console.log("Computed zonas zonesSelector");
    //   return this.zonas.map((zona) => ({
    //     ...zona,
    //     active: this.someActive && zona.Zona === this.selectedNewZone.Zona,
    //     imgUrl: this.newComputedImgUrl(zona, this.colorSelected) ?? noImgUrl
    //   }));
    // }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value && !this.localeAddZoneDialog) {
          this.localeAddZoneDialog = true;
        }
      }
    },
    localeAddZoneDialog(value) {
      if (!value) {
        this.$emit("input", false);
      } else {
        this.selectedNewZone = null;
      }
    },
    zonas: {
        immediate: true,
        handler() {
            this.updateComputedZonas();
        }
    },
    colorSelected: {
        immediate: true,
      handler(newVal, oldVal) {
          if(newVal != oldVal)
            this.updateComputedZonas();
        }
    },
    selectedNewZone: {
        immediate: true,
        handler() {
            this.updateComputedZonas(true);
        }
    }
  },
  methods: {
    // Método para formatear una zona
    formatZona(value) {
      if (!value) return value;
      return value.toUpperCase().replace("_", " ");
    },
    addZone() {
      if (!this.someActive) return;

      this.$emit("add-zone", this.selectedNewZone);
      this.localeAddZoneDialog = false;
    },
    updateComputedZonas(soft = false) {
      console.log("[zonesSelector.vue] updateComputedZonas");
      this.cachedZonas = this.zonas.map((zona) => ({
        ...zona,
        active: this.selectedNewZone && zona.Zona === this.selectedNewZone.Zona,
        imgUrl: soft
            ? this.cachedZonas.find(z1 => z1.Posicion == zona.Posicion)?.imgUrl ?? this.newComputedImgUrl(zona, this.colorSelected) ?? noImgUrl
            : this.newComputedImgUrl(zona, this.colorSelected) ?? noImgUrl
      }));
    }
  }
};
</script>

<style lang="scss">
.selector-zones {
  .title-modal {
      font-size: 18px !important;
      font-weight: 300;
      font-family: $heading-font-family;
    }

  .grid-zones {

    .title-zone-selector {
      font: normal normal 400 16px/20px $heading-font-family;
      letter-spacing: 0px;
      color: black;
    }

    .active {
      border: 1px solid var(--v-colorBtn-base);
    }

    .noActive {
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
