import Vue from "vue";

export default {
  SET_CARRITO(state, payload) {
    state.carrito = payload;
  },
  ADD_REPROCESO(state, payload) {
    let reproceso = payload;
    reproceso.error = false;
    let total = 0;
    let lineas = [];
    // Recorremos las líneas resultantes, calculando el total
    if (reproceso.prices.articulos != null && reproceso.prices.articulos.length > 0) {
      reproceso.prices.articulos.forEach(function (elem) {
        let multiplicador = elem.multiplicador ?? 1;

        let capitalize = (str) => {
          return str.toLowerCase().replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
        };

        let linea = {
          id: elem.sapReferencia ?? "[#TEXT#]",
          cantidad: elem.cantidad,
          nombre: capitalize(elem.nombre),
          precio: elem.precioUnitario,
          multiplicador: multiplicador,
          total: (elem.cantidad * elem.precioUnitario * multiplicador).toFixed(2),
        };
        lineas.push(linea);
        total += parseFloat(linea.total);
      });
    }
    else {
      reproceso.error = true;
    }

    // Añadimos la información necesaria para tener controlado el reproceso
    reproceso.tecnica = reproceso.params.tecnica;
    reproceso.lineas = lineas;
    reproceso.total = total;
    reproceso.modelo = reproceso.params.codigo;
    reproceso.id = reproceso.params.id;
    reproceso.zona = reproceso.params.zona;
    reproceso.posicion = reproceso.params.posicion;

    // Añadimos el reproceso al carrito
    if (state.carrito.reprocesos != null) {
      let existeReproceso = state.carrito.reprocesos.find(r => r.id == reproceso.params.id);

      // Si ya existe, lo borramos y lo volvemos a añadir
      if (existeReproceso !== undefined) {
        state.carrito.total -= parseFloat(existeReproceso.total);
        state.carrito.total += parseFloat(reproceso.total);
        Object.assign(existeReproceso, reproceso);
      } else {
        state.carrito.reprocesos.unshift(reproceso);
        state.carrito.total += reproceso.total;
      }
    }

    // Añadimos el sobrecoste de cantidad exacta
    if (reproceso.isExtraCantidadExacta) {
      if ((!state.sobrecosteCantidadExacta || state.sobrecosteCantidadExacta <= 0))
        state.sobrecosteCantidadExacta = reproceso.total;
      state.paramsCantidadExacta = reproceso.params
    }

    if (reproceso.params.isEtiqueta) {
      reproceso.isEtiqueta = true;
    }

    if (reproceso.params.isManipulado) {
      reproceso.isManipulado = true;
    }
  },
  DELETE_REPROCESO(state, payload) {
    if (
      payload.tecnica &&
      payload.tecnica.includes("Manipulados") &&
      state.carrito.reprocesos != null
    ) {
      const reproceso = state.carrito.reprocesos.find(
        (u) =>
          u.zona == payload.zona &&
          u.modelo == payload.modelo &&
          u.tecnica.includes("Manipulados")
      );
      if (
        reproceso !== undefined &&
        reproceso != "" &&
        reproceso != null &&
        state.carrito.reprocesos != null
      )
        state.carrito.total -= reproceso.total;
      state.carrito.reprocesos = state.carrito.reprocesos.filter(
        (u) => !u.tecnica.includes("Manipulados")
      );
    } else {
      const reproceso = state.carrito.reprocesos.find(
        (u) => u.zona == payload.zona && u.modelo == payload.modelo && u.id == payload.id
      );
      if (reproceso !== undefined && reproceso != "" && reproceso != null)
        state.carrito.total -= reproceso.total;
      state.carrito.reprocesos = state.carrito.reprocesos.filter(
        (u) => !(u.zona == payload.zona && u.id == payload.id) || u.modelo != payload.modelo 
      );
    }
  },

  DELETE_REPROCESO_BY_ID(state, id) {
    if (!id) {
      console.warn("No id válido para borrar el reproceso", id);
      return;
    }

    const reproceso = state.carrito.reprocesos.find(u => u.id == id);

    if (reproceso !== undefined && reproceso != "" && reproceso != null) {
      state.carrito.total -= reproceso.total;
      state.carrito.reprocesos = state.carrito.reprocesos.filter(u => u.id != id);
    }

  },

  ADD_PRODUCTO(state, payload) {
    let producto = payload.producto;
    let cantidad = payload.cantidad;
    let precio = payload.precio;
    let color = payload.color;

    let linea = {
      id: producto.CODE,
      pack: producto.PACK,
      talla: producto.SIZE,
      color: color,
      cantidad: cantidad,
      precio: precio,
      total: (precio * cantidad).toFixed(4),
    };
    if (state.carrito.productos != null) {
      let productoExistente = state.carrito.productos.find(
        (p) => p.id == producto.CODE
      );
      if (productoExistente !== undefined && productoExistente !== null) {
        // Actualiza
        Object.assign(productoExistente, linea);
      } else {
        // Añade
        state.carrito.productos.push(linea);
      }
    }
  },
  DELETE_PRODUCTO(state, payload) {
    if (state.carrito.productos != null) {
      const result = state.carrito.productos.filter((p) => p.id != payload.id);
      if (result === undefined) {
        state.carrito.productos = [];
      } else {
        state.carrito.productos = result;
      }
    }
  },
  UPDATE_TOTALES(state) {
    state.carrito.cantidad = state.carrito.productos.reduce(function (acc, val) {
      return acc + parseFloat(val.cantidad);
    }, 0);

    let totalProducto = state.carrito.productos.reduce(function (acc, val) {
      return acc + parseFloat(val.total);
    }, 0);
    let totalReprocesos = state.carrito.reprocesos.reduce(function (acc, val) {
      return acc + parseFloat(val.total);
    }, 0);
    state.carrito.total =
      parseFloat(totalProducto) + parseFloat(totalReprocesos);
  },
  RESTORE_CARRITO(state, payload) {
    console.log("RESTORE_CARRITO");
    state.carrito = {
      reprocesos: [],
      color: null,
      lineas: {},
      productos: [],
      total: 0.0,
      cantidad: 0,
      modelo: payload,
      nreprocesos: []
    };
  },
  CONFIRM_CARRITO(state, pedido) {
    state;
    pedido;
  },
  CONFIRM_PRESUPUESTO(state, pedido) {
    state;
    pedido;
  },

  SET_COLOR(state, color) {
    Vue.set(state.carrito, "colorCode", color?.CODE ?? color?.Code ?? color);
  },

  UPDATE_N_REPROCESOS(state, payload) {
    if (state.carrito.nreprocesos.filter(r => r.p == payload.p && r.t == payload.t).length > 0) {
      if (payload.a == -1) {
        const indexOfObject = state.carrito.nreprocesos.findIndex(r => r.p == payload.p);
        state.carrito.nreprocesos.splice(indexOfObject, 1);
      }
    } else if (payload.a == 1) {
      state.carrito.nreprocesos.push({
        t: payload.t,
        p: payload.p
      })
    } //state.carrito.nreprocesos = state.carrito.nreprocesos + payload;
  },
  REMOVE_N_REPROCESOS(state, payload) {
    if (state.carrito.nreprocesos.filter(r => r.t == payload.t).length > 0) {
      if (payload.a == -1) {
        const indexOfObject = state.carrito.nreprocesos.findIndex(r => r.p == payload.p);
        state.carrito.nreprocesos.splice(indexOfObject);
      }
    }
  },

  REMOVE_CANTIDADEXACTA(state) {
    if (state.carrito.reprocesos != null) {
      state.carrito.reprocesos = state.carrito.reprocesos.filter(
        (u) => u.isExtraCantidadExacta != true
      );
    }

    state.carrito.total -= state.sobrecosteCantidadExacta;
    state.sobrecosteCantidadExacta = 0;
  },

  ADD_LOADING(state) {
    state.loading++;
  },
  REMOVE_LOADING(state) {
    state.loading--;

    if (state.loading < 0) {
      state.loading = 0;
    }
  },

  ADD_LOADING_DESIGN(state) {
    state.loadingDesign++;
  },
  REMOVE_LOADING_DESIGN(state) {
    state.loadingDesign--;

    if (state.loadingDesign < 0) {
      state.loadingDesign = 0;
    }
  },
  BORRAR_MANIPULADOS(state) {
    // Buscamos en el state.carrito.reprocesos, aquellos que sean manipulados (isManipulado = true) y los eliminamos
    if (state.carrito.reprocesos != null) {
      state.carrito.reprocesos = state.carrito.reprocesos.filter(
        (u) => u.isManipulado != true
      );
    }
  },
  SET_COMERCIAL_CART(state, payload) {
    state.comercialCart = [...payload];
  },
  SET_CLIENT_CART(state, payload) {
    state.clientCart = [...payload];
  },
  SET_FINISH_CART(state, payload) {
    state.completeCart = payload;
  },
  SET_LAST_CART(state, payload) {
    state.lastCart = payload;
  },
  SAVE_CUSTOM_DESIGN(state, payload) {
    state.savedDesign = payload;
  },
  SAVE_ZONE_CUSTOM_DESIGN(state, payload) {
    const index = state.actualDesign.findIndex(({ id, posicion }) => id === payload.id && posicion === payload.posicion);
    if (index !== -1) {
      // state.actualDesign[index] = { ...payload };
      Vue.set(state.actualDesign, index, payload);
    } else {
      state.actualDesign.push(payload);
    }
  },
  DELETE_ZONE_CUSTOM_DESIGN(state, payload) {
    const index = state.actualDesign.findIndex(({ id, posicion }) => id === payload.id && posicion === payload.posicion);
    if (index !== -1) {
      state.actualDesign.splice(index, 1);
    }
  },
  DELETE_ALL_ZONE_CUSTOM_DESIGN(state) {
    state.actualDesign = [];
  },
  UPDATE_DESIGN_COLOR(state, payload) {
    state.actualDesign = [...payload]
  },
  ADD_BOCETO_REPETICION(state, payload) {
    if (isNaN(payload?.id)) {
      console.error("No se ha proporcionado un identificador de zona correcto");
      return;
    }
    let actualBoceto = state.bocetosRepeticion.find(b => b.id == payload.id);
    if (actualBoceto) {
      actualBoceto.value = payload.value;
    } else {
      state.bocetosRepeticion.push(payload);
    }
  },
  DELETE_BOCETO_REPETICION(state, payload) {
    if (!isNaN(payload)) {
      return;
    }
    state.bocetosRepeticion = state.bocetosRepeticion.filter(b => b.id != payload);
  },
  SET_ADDRESS(state, payload) {
    state.shippingAddress = [...payload];
  },
  UPDATE_PARAMS_HEIGHT_AND_WIDTH_WITHOUT_UPDATE_PRICE(state, { id, ancho, alto }) {
    const index = state.actualDesign.findIndex(r => r.id === id);
    if (index !== -1) {
      const atributos = JSON.parse(state.actualDesign[index].params.atributos);

      // Atributos debe ser un array, y contener al menos un elemento
      if (!Array.isArray(atributos) || atributos.length === 0) {
        return;
      }

      if (atributos[0]) {
        if (atributos[0].maxWidth) {
          atributos[0].maxWidth = ancho;
        }

        if (atributos[0].maxHeight) {
          atributos[0].maxHeight = alto;
        }

        state.actualDesign[index].params.atributos = JSON.stringify(atributos);
      }
    }
  }
};
