export default {
  catalogInfo: {},
  modelos: [],
  modelo: {},
  codigosModelos: [],
  productos: [],
  modelInfo: null,
  waitExtranet: null,
  tecnicasCantidadExacta: process.env.VUE_APP_CANTIDADEXTRA.split(',').map(element => element.trim()),
  loading: 0,
  actualAlert: null,
  colorFondo: null,
};
