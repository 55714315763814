/*global customDIM*/
let initialized = false;
let partList = [];
let principalPart = null;

// function log(msg) {
//     console.log("%c" + msg, "color:blue;font-weight:bold; background-color:white;")
// }

/**
 * Comprueba si esta inicializado el canvas 3D
 * @returns {Boolean} Si se encuentra inicializado o no
 */
const checkIsInitialized = () => {
    if (!initialized) {
        console.warn("El canvas 3D no está inicializado")
        return false;
    }

    return true;
}


/**
 * Función para recuperar la parte principal de la prenda (aquella en la que podemos asignar colores)
 * @returns {Object} Objeto con la parte obtenida
 * @throws {Error} Error si no se ha inicializado el canvas 3D, o si no se encuentra la zona principal
 */
const getPrincipalPart = () => {
    if (!checkIsInitialized()) {
        throw new Error("El canvas 3D no está inicializado")
    }

    if (!principalPart) {
        console.log("💻 ~ Buscando la parte principal", partList);
        principalPart = partList?.find(p => p.customizationOptions.some(c => c == "colours"))

        if (!principalPart) {
            throw new Error("No hemos podido encontrar la parte principal del modelo")
        }
    }

    return principalPart;
}

/**
 * Función que inicializa el canvas 3D
 * @param {String} json Objeto con la personalización actual, y donde se van a guardar la personalización actual
 * @param {String} idModel idsap del modelo (por defecto 6554)
 * @returns {Promise} Promesa que se encarga de generar
 */
export const init3DCanvas = (json, idModel = 6554) => {
    console.log("💻 ~ init3DCanvas ~ idModel:", idModel)
    return new Promise((resolve, reject) => {
        // Comprobamos que existe el elemento con id canvas3d
        const canvas = document.getElementById('canvas3d');

        if (!canvas) {
            reject('Canvas no encontrado');
        }

        // Comprobamos que existe la librería customDIM
        if (!customDIM) {
            reject('customDIM no encontrado');
        }

        if (!idModel) {
            reject('No se ha proporcionado un idModel');
        }

        // Inicializamos el canvas 3D
        customDIM.install('canvas3d', 'gorfactoryTest', null, 16, json, () => {
            console.log("💻 ~ onAvalibleAPICustomDIM")
            initialized = true;

            //TODO: Descomentar cuando el sku lo tengan todos los modelos
            customDIM.api.call('model.getGroups', (result) => {
                console.log("💻 ~ customDIM.api.call ~ result:", result)

                let model = result.find(m => m.sku.sku == idModel);
                console.log("💻 ~ customDIM.api.call ~ model:", model, model?.id)

                customDIM.api.call('model.setGroup', model.id, (result) => {
                    console.log("💻 ~ customDIM.api.call ~ result:", result)
                    customDIM.api.call('part.getList', (result) => {
                        console.log("💻 ~ customDIM.api.call ~ result:", result)
                        partList = result
                        resolve(result);
                    }, reject);
                }, reject);
            }, reject)





            // customDIM.api.call('part.getList', (result) => {
            //     console.log("💻 ~ PARTLIST: part.getList", result);
            //     partList = result
            //     resolve();
            // }, console.warn);

        }, () => {
            console.log("💻 ~ onFinalizedCustomDIM")
        }, () => {
            console.log("💻 ~ onChangeCustomDIM”:")
        });

    })
}


/**
 * Función para cargar un color en el modelo 3d
 * @param {String} colorCode código de color que queremos seleccionar
 * @returns {Promise} Promesa con la llamada a la función
 */
export const loadColor = (colorCode) => {

    if (!colorCode) {
        console.warn("Nos debe proporcionar un color válido")
        return;
    }

    const hexcode = (colorCode?.hexcode || colorCode).replace("#", "");
    console.log("💻 ~ loadColor ~ hexcode:", hexcode)

    // Buscamos en la partList aquella que tiene como customizationOptions

    principalPart = getPrincipalPart();
    console.log("💻 ~ loadColor ~ principalPart:", principalPart)

    return new Promise((resolve, reject) => {
        customDIM.api.call(`design.setColourOfPieceByPart( ${principalPart.id}, 0, '${hexcode}' )`, (data) => { console.log("💻 ~ setColourOfPieceByPart " + data); resolve(data) }, reject);
    })

}


/**
 * Función para cargar una zona en concreto
 * @param {Object} Zona zona en la que se quiere cambiar el modelo
 * @returns {Promise} Promesa con la llamada a la función
 */
export const setActiveZone = ({ Posicion, Zona }) => {
    if (!checkIsInitialized()) {
        throw new Error("El canvas 3D no está inicializado")
    }

    principalPart = getPrincipalPart();

    let informationZone = partList.find(p => {
        if (typeof p.sku == 'number') {
            return p.sku == Posicion
        }

        return p.sku?.sku == Posicion
    });
    console.log("💻 ~ setActiveZone ~ partList:", partList)
    console.log("💻 ~ setActiveZone ~ informationZone:", informationZone)

    if (!informationZone) {
        const actualZone = Zona?.replace(/\s+/g, '')?.replace(/_/g, ' ')?.toUpperCase();
        console.log("💻 ~ setActiveZone ~ actualZone:", actualZone)

        informationZone = partList.find(p => p.name == actualZone);
    }

    if (!informationZone || !informationZone.customizationOptions.some(c => c == "images")) {
        throw new Error("No hemos podido encontrar la zona")
    }

    return new Promise((resolve, reject) => {
        customDIM.api.call(`part.setActive( ${informationZone.id} )`, () => resolve(informationZone), reject);
    })
}

/**
 * Función para subir una imagen en base64 a la zona seleccionada para
 * @param {String} base64 imagen en base64 que queremos subir
 * @param {Object} activeZone zona activa en la que queremos subir la imagen
 * @returns {Promise} Promesa con la llamada a la función
 */
export const uploadFile = (base64, activeZone) => {
    if (!checkIsInitialized()) {
        throw new Error("El canvas 3D no está inicializado")
    }

    if (!activeZone?.id) {
        throw new Error("No ha proporcionado la zona activa")
    }

    return new Promise((resolve, reject) => {
        customDIM.api.call(`design.image.uploadBase64("${base64}")`, result => {
            console.log("💻 ~ uploadFile ~ result:", result)

            if (!result?.id) reject("No hemos podido subir la imagen")

            customDIM.api.call(`design.image.addToPart(${activeZone.id},${result.id})`, resolve, reject);

            resolve(result)
        }, reject);
    });
}

export const uninstallCanvas = () => {

}