var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{ref:"cartDialog",staticClass:"dialog-z-index-general",attrs:{"scrollable":"","max-width":"980px"},model:{value:(_vm.localDialog),callback:function ($$v) {_vm.localDialog=$$v},expression:"localDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 d-flex flex-column justify-center align-center header-title"},[_vm._v(" "+_vm._s(_vm.$t("youReprocesos.headerDialogYouReprocesos"))+" ")]),_c('v-divider'),_c('v-card-text',{staticClass:"d-flex flex-column justify-center mt-5 pb-0"},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center align-center text-center",attrs:{"cols":"4"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("youReprocesos.nameAndMailRequired"))+" ")])]),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{staticClass:"pr-3",attrs:{"label":_vm.$t('presupuesto.fichaReprocesos.Nombre'),"outlined":"","dense":"","hide-details":"","error":_vm.emptyName},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{staticClass:"pr-3 mt-2",attrs:{"label":_vm.$t('cart.Email'),"outlined":"","type":"email","dense":"","hide-details":"","error":_vm.emptyMail},model:{value:(_vm.mail),callback:function ($$v) {_vm.mail=$$v},expression:"mail"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('v-textarea',{staticClass:"mt-1 pl-3",attrs:{"label":_vm.$t('presupuesto.Observaciones'),"filled":"","dense":"","auto-grow":"","no-resize":"","rows":"3","counter":"250","rules":[
                _vm.observacionesValid ||
                  _vm.$t('cart.MaxInputObservaciones', { numCaracter: 250 })
              ]},model:{value:(_vm.observaciones),callback:function ($$v) {_vm.observaciones=$$v},expression:"observaciones"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-textarea',{staticClass:"mt-1 pr-3",attrs:{"label":_vm.$t('presupuesto.fichaReprocesos.Referencia'),"filled":"","dense":"","auto-grow":"","no-resize":"","rows":"3","counter":"100","rules":[
                _vm.referenceValid ||
                  _vm.$t('cart.MaxInputObservaciones', { numCaracter: 100 })
              ]},model:{value:(_vm.referencia),callback:function ($$v) {_vm.referencia=$$v},expression:"referencia"}})],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"pt-0"},[_c('address-creator',{staticClass:"pt-0",attrs:{"no-error":true,"show-email":false},model:{value:(_vm.customAddress),callback:function ($$v) {_vm.customAddress=$$v},expression:"customAddress"}})],1)],1)],1),_c('v-divider')],1),_c('v-card-actions',[_c('cotizador-btn',{staticClass:"my-2",attrs:{"on":{
            click: () => {
              _vm.localDialog = false;
            }
          }}},[_vm._v(" "+_vm._s(_vm.$t("general.Cancelar"))+" ")]),_c('v-spacer'),_c('cotizador-btn',{staticClass:"my-2",attrs:{"disabled":_vm.emptyMail || _vm.emptyName || !_vm.observacionesValid || !_vm.referenceValid,"success":true,"on":{
            click: () => {
              _vm.confirmDialog();
            }
          }}},[_vm._v(" "+_vm._s(_vm.$t('general.Aceptar'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }