<template>
  <div>
    <div>
      <!-- <div class="vuetify_wrapper"> -->
      <!-- Usamos la clase vuetify_wrapper para poder añadir este componente en cualquier web, sin romper el CSS original de la misma. Mirar el fichero vue.config.js -->
      <v-app v-if="initLoadCalculadora || initLoaderYouReprocesos">
        <v-container class="loader">
          <v-row>
            <v-col cols="12">
              <v-progress-circular
                :rotate="360"
                :size="100"
                :width="15"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-container>
      </v-app>
      <v-app v-else id="appWrapContainer">
        <CartModal
          v-if="comercialLoginAvalible || clientCartAvalible"
          :dialog="cartDialog"
          @cerrar="onCloseCart"
        ></CartModal>
        <LoginComercialModal
          v-if="comercialLoginAvalible"
          :dialog="loginDialog"
          @cerrar="onCloseLogin"
        ></LoginComercialModal>
        <UserSearchModal
          v-if="comercialLoginAvalible"
          :dialog="userDialog"
          @cerrar="onCloseUser"
        ></UserSearchModal>

        <div id="calculadoraContainer">
          <div
            v-if="!(isYouReprocesos && youReprocesosConfig?.bannerUrl)"
            class="calculadora-title"
            :class="{ sticky: !isYouReprocesos }"
          >
            <div v-if="isInsideIframe && !isYouReprocesos">
              <v-btn class="floatingBack" @click="navigationBack">
                {{ modeloSeleccionado }}
                <v-icon>mdi-step-backward</v-icon>
              </v-btn>
            </div>
            <div
              v-if="comercialLoginAvalible && comercialInfo && !isYouReprocesos"
              class="calculadora-comercialname"
            >
              <v-menu auto offset-y>
                <template #activator="{ on, attrs }">
                  <v-btn class="py-6 px-3" v-bind="attrs" v-on="on">
                    {{ comercialInfo.name }} {{ comercialInfo.lastName }}
                    <br />
                    {{ comercialInfo.email }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item class="menu-options" @click="reiniciar">
                    <v-list-item-icon>
                      <v-icon>mdi-reload</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      {{ $t("general.Reiniciar") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item class="menu-options" @click="onLogout">
                    <v-list-item-icon>
                      <v-icon>mdi-logout-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      {{ $t("login.Logout") }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <v-spacer></v-spacer>
            <template
              v-if="
                !isYouReprocesos ||
                (isYouReprocesos && !youReprocesosConfig?.logourl)
              "
            >
              {{ $t("general.Marcaje") }}
            </template>
            <template
              v-else-if="isYouReprocesos && youReprocesosConfig?.logourl"
            >
              <img class="logo" :src="youReprocesosConfig.logourl" alt="logo" />
            </template>
            <v-spacer></v-spacer>
            <div
              v-if="
                comercialLoginAvalible &&
                loggedClient &&
                userOfClient &&
                !isYouReprocesos
              "
              class="calculadora-user"
            >
              <v-btn class="mr-1" dark outlined @click="userDialog = true">
                <v-icon dark>mdi-magnify</v-icon>
                <span class="selectedClient">
                  {{ loggedClient.clientId }} {{ loggedClient.clientName }}
                </span>
              </v-btn>
              <v-select
                v-model="localUserOfClient"
                :items="loggedClient.users"
                outlined
                dense
                item-text="label"
                item-value="user"
                color="primary"
                background-color="white"
                class="selectableUser"
              ></v-select>
            </div>
            <v-fab-transition>
              <div
                v-if="
                  ((comercialLoginAvalible &&
                    loggedClient &&
                    localUserOfClient &&
                    !userDialog &&
                    !loginDialog) ||
                    clientCartAvalible) &&
                  !isYouReprocesos
                "
                id="cart-btn"
                class="mr-2 fixed-btn"
                :class="{ ok: isOk }"
              >
                <v-badge
                  background-color="primary"
                  :content="
                    clientCartAvalible ? countClientCart : countComercialCart
                  "
                  overlap
                  bordered
                  :value="
                    clientCartAvalible
                      ? countClientCart != 0
                      : countComercialCart != 0
                  "
                >
                  <v-btn
                    fab
                    large
                    bottom
                    left
                    :loading="cargando"
                    @click="toggleCart"
                  >
                    <img class="ma-2" src="@/assets/cart.svg" alt="cart" />
                  </v-btn>
                </v-badge>
              </div>
            </v-fab-transition>
          </div>

          <div v-else class="calculadora-title banner-youReprocesos">
            <img :src="youReprocesosConfig.bannerUrl" alt="banner" />
          </div>

          <div class="cotizador-app">
            <v-fade-transition>
              <v-overlay
                :value="cargando || loadingModelo || loginDialog || userDialog"
                absolute
              >
                <v-progress-circular
                  v-if="!loginDialog || !comercialLoginAvalible || !userDialog"
                  indeterminate
                  color="gray"
                ></v-progress-circular>
              </v-overlay>
            </v-fade-transition>
            <alerta
              :dialog="alertActive"
              :titulo="alertError.title"
              :boton-cerrar="$t('confirm.Cerrar')"
              :cuerpo="alertError.body"
              :type-error="alertError"
              @cerrar="alertActive = false"
            ></alerta>
            <div v-if="showModeloSelector" class="text-h4 d-flex mb-2">
              <v-dialog
                ref="searchModelDialog"
                v-model="searchModelDialogShow"
                transition="dialog-bottom-transition"
                max-width="600"
                content-class="calculadora-dialog"
                @input="setDialogScrollable"
              >
                <template #activator="{ on, attrs }">
                  <div
                    ref="searchModelDialogButton"
                    v-bind="attrs"
                    class="d-flex selected-model"
                    v-on="on"
                  >
                    1.-{{ $t("general.Escoge el modelo a personalizar") }}&nbsp;
                    <v-icon class="icono_30">mdi-magnify</v-icon>
                    &nbsp;
                    <b
                      v-if="modeloSeleccionado"
                      class="text-decoration-underline d-flex"
                    >
                      {{ $t(`MODELS.${modeloSeleccionado}.NAME`) }}
                      {{ modeloSeleccionado }}
                    </b>
                  </div>
                </template>
                <template #default="dialog">
                  <v-card
                    id="searchModelDialog"
                    class="bodySearch"
                    :class="{ 'active-body-search': searchModelDialogShow }"
                    :loading="loadingSearch"
                  >
                    <v-card-text class="pt-10">
                      <v-item-group>
                        <v-container>
                          <v-row>
                            <h1>
                              {{
                                $t("general.Escoge el modelo a personalizar")
                              }}
                            </h1>
                          </v-row>
                          <v-row class="mt-5">
                            <v-text-field
                              v-model="searchText"
                              label="Buscar modelo"
                              prepend-inner-icon="mdi-magnify"
                              @input="searchDebounce"
                            ></v-text-field>
                            <v-list class="listSerachModel" subheader>
                              <v-list-item
                                v-for="modelo in searchResult"
                                :key="modelo.code"
                                @click="
                                  modeloSeleccionado = modelo.code;
                                  dialog.value = false;
                                "
                              >
                                <v-list-item-avatar>
                                  <v-img :src="modelo.imagemodelurl"></v-img>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <div class="d-flex align-center">
                                    <v-list-item-title>
                                      <div
                                        v-if="modelo"
                                        v-html="getSearchItemText(modelo)"
                                      ></div>
                                    </v-list-item-title>
                                    <cotizador-btn
                                      :on="{
                                        click: () => {
                                          searchModel(modelo.code);
                                        }
                                      }"
                                    >
                                      {{ $t("general.Seleccionar") }}
                                    </cotizador-btn>
                                  </div>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-row>
                        </v-container>
                      </v-item-group>
                    </v-card-text>
                  </v-card>
                </template>
              </v-dialog>
            </div>
            <Cotizador
              v-if="modeloSeleccionado"
              :modelo-id="modeloSeleccionado"
              @reload="reiniciar"
            ></Cotizador>
          </div>
        </div>
        <general-modal :dialog="localCompleteCartDialog">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="localCompleteCartDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>
                {{ $t("general.PedidoFinalizado") }}: {{ completeCart }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <div class="d-flex flex-column align-center">
                <h2 class="mt-3">
                  {{
                    $t("general.PedidoCompletado", { cartCode: completeCart })
                  }}
                </h2>
                <cart-element
                  class="mt-3"
                  disable-delete
                  :cart="lastCart"
                ></cart-element>
              </div>
            </v-card-text>
          </v-card>
        </general-modal>
      </v-app>
    </div>
  </div>
</template>

<script>
import Cotizador from "./views/Cotizador.vue";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import alerta from "./components/modales/alert.vue";
import { dynamicPrice } from "./utils/priceUtils";
import LoginComercialModal from "./components/modales/loginComercialModal.vue";
import UserSearchModal from "./components/modales/userSearchModal.vue";
import CartModal from "./components/modales/cartModal.vue";
import generalModal from "./components/modales/generalModal.vue";
import cartElement from "./components/basic/cartElement.vue";
import { orderBy, isEmpty } from "lodash";
import { getTextColor, lighterColor } from "@/utils/colorUtils";
import CotizadorBtn from "./components/basic/CotizadorBtn.vue";

export default {
  name: "App",
  components: {
    Cotizador,
    alerta,
    LoginComercialModal,
    UserSearchModal,
    CartModal,
    generalModal,
    cartElement,
    CotizadorBtn
  },
  data() {
    return {
      modeloSeleccionado: "",
      cargando: false,
      showModeloSelector: true,
      searchText: "",
      searchResult: null,
      debounce: null,
      alertError: {},
      alertActive: false,
      loginDialog: false,
      userDialog: false,
      cartDialog: false,
      localUserOfClient: null,
      loadingSearch: false,
      localCompleteCartDialog: false,
      searchModelDialogShow: false,
      initLoadCalculadora: true
    };
  },
  computed: {
    ...mapGetters("modelo", [
      "codigosModelos",
      "loadingModelo",
      "actualAlert",
      "modelPrice",
      "waitExtranet",
      "currency",
      "modelInfoFromCatalog",
      "modelCode",
      "colorAvalible"
    ]),
    ...mapGetters("carrito", [
      "carrito",
      "countComercialCart",
      "countClientCart",
      "completeCart",
      "lastCart"
    ]),
    ...mapGetters("config", [
      "actualLanguageRegion",
      "comercialInfo",
      "loggedClient",
      "userOfClient",
      "key",
      "resetCalculadora",
      "comercialLoginAvalible",
      "clientCartAvalible",
      "isOk",
      "isYouReprocesos",
      "youReprocesosConfig",
      "youReprocesosSession",
      "initLoaderYouReprocesos",
      "getLocaleFromLanguageRegion"
    ]),
    isInsideIframe() {
      return window !== window.parent;
    }
  },

  watch: {
    showModeloSelector: "resizeEvent",
    isYouReprocesos(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.initYouReprocesos();
      }
    },
    searchModelDialogShow(newVal) {
      if (
        newVal &&
        (this.modeloSeleccionado === undefined ||
          this.modeloSeleccionado == null ||
          this.modeloSeleccionado == "")
      )
        this.updateHeightFrame(750);
    },
    completeCart(newVal) {
      this.localCompleteCartDialog = newVal != "";
    },
    async userOfClient(newVal, oldVal) {
      // console.log("🚀 ~ file: App.vue:310 ~ userOfClient ~ newVal:", newVal);
      if (newVal != oldVal) {
        this.cargando = true;
        this.localUserOfClient = newVal.user;
        this.setKey(newVal.reprocesosKey);
      }
    },
    localUserOfClient(newVal, oldVal) {
      if (newVal != oldVal && newVal != this.userOfClient?.user) {
        this.setUserOfClient(
          this.loggedClient.users.find(u => u.user == newVal)
        );
      }
    },
    comercialInfo: function (newVal) {
      if (newVal) {
        this.loginDialog = false;
      }
    },
    key: async function () {
      this.cargando = true;
      if (!this.modeloSeleccionado) {
        let modeloId = this.$route.query.modelo;
        if (modeloId === undefined || modeloId == null || modeloId == "")
          this.searchModelDialogShow = true;
        //  this.$refs.searchModelDialogButton.$el.click();
        //   modeloId = "CA6502";
        this.modeloSeleccionado = modeloId;
      }
      await this.fetchComercialCart()
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.cargando = false;
        });
    },
    modeloSeleccionado: async function (newValue, oldValue) {
      if (newValue == oldValue) return;

      this.cleanAllDesign();

      if (this.waitExtranet) {
        await this.waitExtranet;
      }

      if (
        (this.key || this.isYouReprocesos) &&
        (newValue != this.modelCode || isEmpty(this.modelPrice))
      ) {
        console.log("INIT LOAD MODELO FROM");
        await this.initLoadModelo();
      }

      if (this.$refs["editeddialog"])
        this.$refs["editeddialog"].isActive = false;
    },
    actualAlert: function (newVal) {
      this.alertError = newVal;
      this.alertActive = newVal && (newVal.body || newVal.code) ? true : false;
    },
    alertActive: function (newVal, oldVal) {
      if (!newVal && newVal != oldVal) {
        this.clearAlert();
      }
    },
    resetCalculadora(newVal) {
      if (newVal) this.reiniciar();
    }
  },
  async created() {
    await this.initCalculadora();
    // Añadir listener para el evento resize
    window.addEventListener("resize", this.resizeEvent);

    if (this.$route.query.company) {
      this.setCompanyValue(this.$route.query.company);
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeEvent);
  },
  methods: {
    ...mapActions("carrito", [
      "restoreCarrito",
      "addProducto",
      "fetchComercialCart",
      "cleanAllDesign",
      "cleanAllDesign"
    ]),
    ...mapActions("modelo", [
      "InitCotizador",
      "clearAlert",
      "getModelAndPricesFromExtranet",
      "fetchModelo"
    ]),
    ...mapActions("config", [
      "setlocale",
      "setUserOfClient",
      "setKey",
      "logout",
      "setResetCalculadora",
      "updateHeightFrame",
      "setColor"
    ]),
    searchModel(model) {
      this.modeloSeleccionado = model;
      this.dialog.value = false;
    },
    navigationBack() {
      parent.postMessage(
        { type: "navigationToModel", modelCode: this.modeloSeleccionado },
        "*"
      );
    },
    async initYouReprocesos() {
      this.$vuetify.theme.currentTheme.primary =
        this.youReprocesosConfig.accentColor;
      this.$vuetify.theme.currentTheme.colorBtn =
        this.youReprocesosConfig.accentColor;
      this.$vuetify.theme.currentTheme.colorTextBtn = getTextColor(
        this.$vuetify.theme.currentTheme.colorBtn
      );
      this.$vuetify.theme.currentTheme.colorTextBanner = getTextColor(
        this.youReprocesosConfig.bannerColor
      );
      this.$vuetify.theme.currentTheme.bannerBackground =
        this.youReprocesosConfig.bannerColor;
      if (!this.modeloSeleccionado) this.searchModelDialogShow = true;

      if (this.youReprocesosConfig.logourl) {
        const favicon = document.getElementById("favicon-link");
        favicon.href = this.youReprocesosConfig.logourl;
      }

      await this.setLocaleApp(this.youReprocesosConfig.language);

      this.$vuetify.theme.currentTheme.primaryColorPdf = this.youReprocesosConfig.colorTypePdf == 1
          ? this.youReprocesosConfig.accentColor
          : this.youReprocesosConfig.colorTypePdf == 2
            ? this.youReprocesosConfig.bannerColor : "#7e7e7e";
      this.$vuetify.theme.currentTheme.primaryColorText = this.youReprocesosConfig.colorTypePdf == 0
        ? "white"
        : getTextColor(this.$vuetify.theme.currentTheme.primaryColorPdf);

      this.$vuetify.theme.currentTheme.secondaryColorPdf = lighterColor(this.$vuetify.theme.currentTheme.primaryColorPdf, 85);

      this.$vuetify.theme.currentTheme.secondaryColorText = getTextColor( this.$vuetify.theme.currentTheme.secondaryColorPdf);
    
    },
    resizeEvent() {
      setTimeout(() => {
        this.updateHeightFrame();
      }, 100);
    },
    toggleCart() {
      this.cartDialog = !this.cartDialog;
    },
    async initCalculadora() {
      // Establecemos el idioma
      let locale = this.$route.query.lang ?? "en";
      this.setlocale(locale);
      await this.setLocaleApp(locale);

      // Establecemos la visibilidad de los modelos
      let showModelos = this.$route.query.models;
      if (showModelos == "false") {
        this.showModeloSelector = false;
      }

      await this.InitCotizador();
      console.log("Init");
      let key = this.$route.query.k;
      let avalibleClientKey = !(key === undefined || key == null || key == "");
      if (!avalibleClientKey) {
        if (
          !this.comercialInfo &&
          this.comercialLoginAvalible &&
          !this.isYouReprocesos
        )
          this.loginDialog = true;
      } else {
        this.setKey(key);
        this.cargando = true;
      }


      if (this.isYouReprocesos || avalibleClientKey) {
        let modeloId = this.$route.query.modelo;
        if (modeloId === undefined || modeloId == null || modeloId == "")
          this.searchModelDialogShow = true;
        //   this.$refs.searchModelDialogButton.$el.click();
        // modeloId = "CA6502";
        this.modeloSeleccionado = modeloId;
      }

      if (this.isOk) {
        this.$vuetify.theme.currentTheme.primary = "#b74040";
      }


      if (this.isYouReprocesos) {
        this.initYouReprocesos();
      }

      console.log(
        "🚀 ~ initCalculadora ~ this.isYouReprocesos:",
        this.isYouReprocesos
      );
      this.cargando = false;
      this.initLoadCalculadora = false;
    },
    async initLoadModelo() {
      console.log("initLoadModelo");

      if (
        (this.key || this.isYouReprocesos) &&
        this.modeloSeleccionado &&
        (!this.modelPrice?.catalog ||
          !this.modeloSeleccionado.endsWith(this.modelCode))
      )
        this.getModelAndPricesFromExtranet({
          model: this.modeloSeleccionado,
          key: this.isYouReprocesos ? this.youReprocesosSession : this.key,
          isYouReprocesos: this.isYouReprocesos
        });
      else this.restoreCarrito(this.modeloSeleccionado);

      if (this.waitExtranet) {
        await this.waitExtranet;
      }

      let initUnit = this.$route.query.units;

      if (initUnit && !isNaN(initUnit)) {
        let initialSize = orderBy(
          this.modelPrice.tallasInfo,
          "Orden",
          "asc"
        )[0];

        let actualProductCode = Object.keys(
          this.modelInfoFromCatalog.products
        ).find(
          p => initialSize.Talla == this.modelPrice?.catalog?.products[p].SIZE
        );

        if (actualProductCode) {
          let actualProduct =
            this.modelPrice?.catalog?.products[actualProductCode];
          let producto = actualProduct.CODE;
          let price = this.calculatePrice(producto, initUnit);

          let actualColor = actualProduct.COLOR;
          // Calculamos product info
          let params = {
            producto: actualProduct,
            cantidad: initUnit,
            precio: price,
            color: actualColor
          };
          this.addProducto(params);
        }
      }

      if (this.colorAvalible.length > 0)
        this.setColor(this.colorAvalible[0].code);
    },
    async reiniciar() {
      await this.initLoadModelo();
      await this.cleanAllDesign();
      this.setResetCalculadora(false);
    },
    onCloseLogin() {
      this.loginDialog = false;
      this.userDialog = true;
    },
    onCloseCart() {
      this.cartDialog = false;
    },
    onCloseUser() {
      this.userDialog = false;
    },
    async onLogout() {
      console.log("LOGOUT!");
      await this.logout();
      this.loginDialog = true;
      this.userDialog = false;
      await this.initCalculadora();
    },
    async setLocaleApp(locale) {
      locale = this.getLocaleFromLanguageRegion(locale);
      await this.$root.$i18n.loadLanguageAsync(locale);
    },
    async searchDebounce() {
      this.searchResult = null;
      this.loadingSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        await this.search();
        this.loadingSearch = false;
      }, 500);
    },
    async search() {
      let lang = this.actualLanguageRegion;
      let params = {
        searchQuery: this.searchText,
        Language: lang,
        FilterShop: "STA,ROL,ROLSTA",
        OrderBy: "NOORDER",
        PageSize: "100",
      };

      await axios
        .get(`${process.env.VUE_APP_EXTRANET_URL}/Search/query`, {
          params: params
        })
        .then(response => {
          if (response.data.Items) {
            let regExp = new RegExp( params.searchQuery, 'i' );
            this.searchResult = response.data.Items.filter(
              (item) =>
                this.codigosModelos.find((c) => c == item.code) !=
                null
            ).sort((a,b) => {
              return b.objectID?.match(regExp)?.[0]?.length ?? 0 - a.objectID?.match(regExp)?.[0]?.length ?? 0 ;
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    getSearchItemText(model) {
      let result =
        model.familiename + " " + model.name + " (" + model.code + ")";
      let textoToFind = this.searchText.toUpperCase();
      // Create array from searchText splitting by space character
      let searchTextArray = textoToFind.split(" ");
      // Loop through each word in searchTextArray
      for (let i = 0; i < searchTextArray.length; i++) {
        result = result.replace(
          searchTextArray[i],
          "<b>" + searchTextArray[i] + "</b>"
        );
      }
      return result;
    },
    calculatePrice: function (producto, cantidad) {
      // Comprobamos si nos encontramos en stamina o no
      const pricesStm = this.modelInfoFromCatalog.originalShop
        ? this.modelInfoFromCatalog.originalShop == "STA"
        : this.modelInfoFromCatalog.shop == "STA";
      // console.log("🚀 ~ file: Productos.vue:316 ~ pricesStm:", pricesStm)

      const numByTypePrice = {};

      // Si nos encontramos en stamina, debemos juntar la cantidad de aquellos productos con los mismos precios
      if (pricesStm && this.modelPrice.prices.RANGEPRICE) {
        this.carrito.productos.forEach(val => {
          if (val.id != producto.CODE) {
            let productPritce = this.modelPrice.prices.RANGEPRICE[val.id];
            if (!productPritce) return;
            const actualType = productPritce.TYPE;

            if (!numByTypePrice[actualType]) {
              numByTypePrice[actualType] = 0;
            }

            numByTypePrice[actualType] += parseInt(val.cantidad);
          }
        });
      }

      let aux = dynamicPrice(
        this.modelPrice.prices,
        this.currency,
        this.modelInfoFromCatalog,
        producto.CODE ?? producto.code ?? producto,
        cantidad,
        "en",
        this.modelPrice.extra.priceNoIncr,
        this.modelPrice.extra.aplicaPack,
        this.modelPrice.extra.hasRangePrices
      );
      let calculatedPrice = parseFloat(aux);

      return calculatedPrice;
    },
    setDialogScrollable() {
      this.$nextTick(() => {
        this.$refs.searchModelDialog.showScroll();
      });
    }
  }
};
</script>

<style lang="scss">
$body-font-family: "Cairo";
$title-font: "Outfit";

$btn-letter-spacing: 0 !important;
$input-letter-spacing: 0 !important;
$input-label-letter-spacing: 0 !important;
$card-text-letter-spacing: 0 !important;

.container--fluid {
  max-width: 1900px !important;
}

.logo {
  max-height: 80px;
}

.v-application {
  font-family: $body-font-family, sans-serif !important;
  letter-spacing: 0px !important;

  .title {
    // To pin point specific classes of some components
    font-family: $title-font, sans-serif !important;
  }
}

.selected-model {
  font-family: $title-font, sans-serif !important;
}

.ps {
  height: 1000px;
}
::-webkit-scrollbar {
  width: 4px; /* Remove scrollbar space */
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: primary;
}
.icono_30 {
  font-size: 30px !important;
}

.calculadora-title {
  font-family: $title-font, sans-serif !important;
  background: var(--v-bannerBackground-base);
  width: 100%;
  color: var(--v-colorTextBanner-base);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
  font-size: 35px;
  font-weight: 300;
  margin-bottom: 25px;

  &.banner-youReprocesos {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-height: 256px;
      max-width: 1895px;
    }
  }
}

.calculadora-title.sticky {
  position: sticky;
  top: 0px;
  z-index: 2000;
}

.calculadora-title .calculadora-comercialname {
  font-size: 15px;
  font-weight: 300;
  margin-left: 10px;
  /* position: absolute; */
  /* left: 10px;
  height: 100%; */
  /* margin-top: 5px; */
}

.calculadora-title .calculadora-user {
  font-size: 15px;
  font-weight: 300;
  margin-right: 45px;
  /* position: absolute; */
  /* right: 10px; */
  /* height: 100%;  */
  /* margin-top: 5px; */
  /* background: white; */
  max-height: 45px;
  /* max-width: 250px; */
  display: flex;
  align-items: center;
}

.calculadora-title .calculadora-user .selectableUser,
.calculadora-title .calculadora-user .selectedClient {
  max-width: 250px;
  overflow: hidden;
}

.calculadora-title .calculadora-user .v-input {
  max-height: 40px;
}
.menu-options:hover {
  background-color: #e7e7e7;
  cursor: pointer;
}

.cotizador-app {
  margin-left: 30px;
  margin-right: 30px;
  position: relative;
}

#zoneAlert {
  position: absolute;
  left: 0;
  right: 0;
  top: 25px;
  margin-left: auto;
  margin-right: auto;
  width: 450px; /* Need a specific value to work */
  text-align: center;
}

// .fixed-btn {
//     // bottom: 0;
//     // position: sticky;
//     // margin: 0 0 16px 16px;
//     // z-index: 999999999;
//   }

.fixed-btn .v-badge__badge {
  background-color: #4caf50;
  font-weight: bold;
}

.fixed-btn.ok .v-badge__badge {
  background-color: #b74040;
}

.confirm-all-btn {
  min-height: 36px;
  height: auto !important;

  & > .v-btn__content {
    max-width: 150px;
    white-space: normal;
  }
}

#calculadoraContainer {
  .cotizador-app {
    margin-right: auto;
    margin-left: auto;
    max-width: 1820px;
  }
}

.calculadora-dialog {
  align-self: flex-start;
  margin-top: 130px;

  .bodySearch {
    max-height: 600px;
    overflow-y: auto;

    .listSerachModel {
      width: 100%;
      max-height: 450px;
      overflow-y: auto;
    }
  }
}

.loader {
  height: 100%;

  .row {
      height: 100%;

      .col {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
}
</style>
