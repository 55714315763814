<template>
  <v-dialog
    ref="updateFileDialog"
    v-model="localdialog"
    max-width="600px"
    persistent
    transition="dialog-bottom-transition"
    class="dialog-zindex"
  >
    <template #activator="{ on }">
      <CotizadorBtn
        v-if="!withoutActivator"
        dark
        :on="on"
        content-class="w-100"
      >
        <img
          class="ma-2 btn-icon-presupuesto"
          src="@/assets/cart.svg"
          alt="cart"
        />
        <span>{{ $t("presupuesto.fichaReprocesos.titulop1") }}</span>
      </CotizadorBtn>
    </template>
    <v-card>
      <v-card-text>
        <v-col class="disenoBody">
          <!-- Añadir diseño -->
          <v-row class="mt-1">
            <v-col>
              <div class="subtitle">
                {{ $t("presupuesto.diseñoSubtitle") }}
              </div>
              <div class="infoDesign mt-2">
                {{ $t("presupuesto.diseñoHelper") }}
              </div>
              <div>
                <v-btn
                  :loading="isSelecting || loadingCarrito"
                  class="mt-3 fileBtn"
                  large
                  @click="handleFileImport"
                >
                  <v-icon color="primary" class="mr-3">mdi-plus-circle</v-icon>
                  {{ $t("presupuesto.diseñoUpload") }}
                </v-btn>
                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept="image/*,application/pdf"
                  @change="selectFile"
                />
              </div>
            </v-col>
            <v-col v-if="actualDesign" class="imgContainer">
              <v-img
                :key="idImg"
                class="centerImg"
                :src="actualDesign"
                width="100%"
                contain
              ></v-img>
              <div class="centerContainer">
                <v-btn @click="deleteImg">
                  {{ $t("configuracion.ReprocesosGenerales.Eliminar") }}
                  <v-icon>$fi-trash-2</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col>
              <h4>
                {{ $t("presupuesto.diseñoAlternative") }}
              </h4>
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col>
              <h3>{{ $t("presupuesto.Observaciones") }}</h3>
              <v-textarea v-model="observaciones" class="mt-3" filled dense />
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="closeDialog">
          {{ $t("general.Cancelar") }}
        </v-btn>
        <v-btn color="primary" text @click="saveFileAndCloseDialog">
          {{ $t("general.Guardar") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { parseImage } from "@/utils/imageUtils.js";
import CotizadorBtn from "@/components/basic/CotizadorBtn.vue";

export default {
  components: {
    CotizadorBtn
  },
  props: {
    // Mostramos el botón por defecto
    withoutActivator: {
      type: Boolean,
      default: false
    },
    // Prop para controlar el dialog
    dialog: {
      type: Boolean,
      default: false
    },
    // Prop para controlar si esta activo o no
    disabled: {
      type: Boolean,
      default: false
    },
    zona: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      localdialog: false,
      isSelecting: false,
      actualDesign: null,
      originalFile: null,
      plainFile: null,
      idImg: 0,
      observaciones: "",
      loading: false,
      eliminando: false
    };
  },
  computed: {
    ...mapGetters("carrito", ["loadingCarrito" , "actualListDesign"]),
    ...mapGetters("modelo", ["modelCode"])
  },
  watch: {
    // Tratamiento para las modificaciones del dialogo
    dialog(newVal) {
      if (this.localdialog != newVal) {
        this.localdialog = newVal;
      }
    },
    localdialog(newVal, oldVal) {
      console.log("🎨 ~ localdialog ~ newVal:", newVal);
      if (newVal == oldVal) return;

      if (newVal != this.dialog) {
        console.log("🎨 ~ Test update dialog", newVal);
        this.$emit("update:dialog", newVal);
      }

      if (!newVal) {
        this.$emit("close-dialog");
      }

      if(newVal) {
        this.tryInitializeFromDesignList();
      }
    },
    // Si se cambia de modelo, debemos resetear
    modelCode(newVal, oldVal) {
      if (newVal != oldVal) {
        this.localdialog = false;
        this.resetImg();
      }
    },
    zona(newVal, oldVal) {
      if (newVal != oldVal) {
        // Reiniciamos la imagen actual
        this.localdialog = false;
        this.resetImg();

        // Intentamos recuperar la imagen para la zona
        this.tryInitializeFromDesignList();
      }
    }
  },
  methods: {
    ...mapActions("carrito", ["deleteZoneCustomDesign"]),
    deleteImg() {
      this.eliminando = true;
      this.resetImg();
    },
    // Función para resetear el dialogo
    resetImg() {
      console.log("🎨 ~ resetImg function call");

      this.actualDesign = null;
      this.plainFile = null;
      this.idImg = 0;
      this.observaciones = "";
      this.originalFile = null;
    },

    // Función para cerrar el modal
    closeDialog() {
      this.localdialog = false;
    },
    saveFile() {
       this.$emit("update-image", {
        base64: this.actualDesign,
        plainFile: this.plainFile,
        originalFile: this.originalFile,
        id: this.idImg,
        observaciones: this.observaciones
      });
    },
    // Cerramos el modal y propagamos el resultado
    saveFileAndCloseDialog() {
      this.closeDialog();
      this.saveFile();

      if (this.eliminando)
      {
        //Eliminamos la zona personalizada
        this.deleteZoneCustomDesign({
          id: this.zona?.id,
          posicion: this.zona.Posicion
        });

        this.eliminando = false;
      }
    },
    // Función para manejar el input de archivos
    handleFileImport() {
      this.isSelecting = true;

      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.value = null;
      // Trigger click on the FileInput
      this.$refs.uploader.click();
    },
    // Función para seleccionar un archivo
    selectFile(event) {
      parseImage(event);
      const file = event.target.files[0];

      if (file) {
        if (file.size > 15000000) {
          alert(this.$t("alert.alertSizeBiggerThan15Mb"));
        } else {
          var reader = new FileReader();

          reader.addEventListener(
            "loadend",
            async () => {
              let base64 = reader.result;
              this.originalFile = reader.result;
              // Comprobamos si es un pdf
              if (file.type == "application/pdf") {
                // Importamos la librería
                const pdfjsLib = await import("pdfjs-dist/webpack");

                // Preparamos el base64
                base64 = atob(
                  base64.replace("data:application/pdf;base64,", "")
                );

                // Cargamos el documento PDF
                const loadingTask = pdfjsLib.getDocument({ data: base64 });
                const pdf = await loadingTask.promise;

                // Renderizamos la primera página
                const page = await pdf.getPage(1);
                const scale = 1.5;
                const viewport = page.getViewport({ scale: scale });

                // Preparamos el canvas y su contexto
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                // Renderizamos la página en el canvas
                const renderContext = {
                  canvasContext: context,
                  viewport: viewport
                };
                await page.render(renderContext).promise;

                // Convertimos el canvas a una imagen PNG en base64
                base64 = canvas.toDataURL("image/png");
              }

              // this.$set(this.misPrecios, "CustomLogo", file);
              this.actualDesign = base64;
              this.plainFile = file;
              this.idImg = Math.floor(Math.random() * 2147483647);
            },
            { once: true }
          );

          reader.readAsDataURL(file);
        }
      }
    },

    // Función para inicializar el diseño desde la lista de diseños
    tryInitializeFromDesignList() {
      if (this.actualListDesign && this.actualListDesign.length > 0) {
        let design = this.actualListDesign.find(
          x => x.id == this.zona.id && this.zona.Posicion == x.posicion
        );
        console.log("[updateFileDialog] 🚀 ~ tryInitializeFromDesignList ~ design:", design)

        if (design && (this.idImg == 0 || this.idImg != design.idImg)) {
          console.log("🚀 ~ file: updateFileDialog.vue:322 ~ tryInitializeFromDesignList ~ (this.idImg == 0 || this.idImg != design.idImg):", (this.idImg == 0 || this.idImg != design.idImg), this.idImg)
          // Cargamos la imagen design en actualDesign y aumentamos el indice
          this.actualDesign = design.design;
          this.originalFile = design.originalFile;
          this.plainFile = design.plainFile;
          this.idImg = design.idImg;
          this.observaciones = design.observaciones

          this.saveFile();
        }
        else {
          this.$emit("update-image", null);
        }
      }
      else {
        this.$emit("update-image", null);
      }
    }
  }
};
</script>
