<template>
 <v-dialog
      v-model="dialog"
      v-no-scroll-body
      scrollable
      transition="dialog-bottom-transition"
      class="dialog-zindex margin-top-banner"
      :content-class="'margin-top-banner productModal smallModal youReprocesosProductModal'"
    >
      <template #activator="{on}">
        <CotizadorBtn
          class="mw-100 w-100 pr-2" content-class="w-100"
          sm
          :animated="modelPrice && totalUnidades == 0"
          :on="on"
          :disabled="!modelPrice"
        >
          + {{ $t("general.sizeColorQuantity") }}
        </CotizadorBtn>
      </template>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar
            extended
            extension-height="0"
            height="fit-content"
            dark
            color="black"
          >
            <v-toolbar-title
              class="productos-titulo d-flex justify-space-between"
            >
              <v-container fluid>
                <v-row>
                  <v-col cols="8" class="d-flex align-center py-0">
                    <v-row>
                      <v-col cols="12" md="6" class="d-flex align-center py-0">
                        <span class="modelo-name">
                          {{ $t(`MODELS.${modelCode}.NAME`) }}
                        </span>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        class="d-flex justify-md-center align-center py-0"
                      >
                        <span>
                          {{ $t("general.Anadir talla, color y cantidad") }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="4" class="d-flex flex-row-reverse pr-0 py-0">
                    <div class="left-border" @click="emitClick(false)">
                      <v-btn icon dark>
                        <v-icon xl>mdi-close</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text id="productSelectorDialogBody">
          <v-container fluid>
            <v-row class="d-flex justify-center mt-2">
              <div v-if="sublimacionSoloBlanco" class="alertSublimacionBlanco">
                <v-alert
                  border="bottom"
                  elevation="8"
                  text
                  type="warning"
                  dense
                >
                  <b>{{ $t("configuracion.Sublimacion.sublimacionBlanco") }}</b>
                  <br />
                  <b>
                    {{ $t("configuracion.Sublimacion.sublimacionSoloBlanco") }}
                  </b>
                </v-alert>
              </div>
            </v-row>
            <v-row>
              <v-col cols="12" md="5">
                <div class="texttable title-table-header d-flex align-center">
                  <span>{{ $t("general.Colores") }}</span>
                </div>
                <div
                  class="color-list d-flex justify-start flex-wrap ml-2 mt-1 py-2"
                >
                  <ColorBall
                    v-for="actColor in colorAvalible"
                    :key="actColor.code"
                    circle-class=""
                    :hexcode="actColor.url ? actColor.url : actColor.hexcode"
                    :selected="actualColor == actColor.code"
                    :quantity="getQuantityByColor(actColor.code)"
                    :disabled="
                      loadingPrices > 0 ||
                      (soloBlancoEnCarritoSublimacion &&
                        actColor.code != '01') ||
                      (actColor.code == '01' &&
                        sublimacionSoloBlanco &&
                        !soloBlancoEnCarritoSublimacion &&
                        totalUnidades > 0)
                    "
                    tooltip
                    :label="actColor.code"
                    @changeColor="changeColor(actColor.code)"
                  >
                    <span>
                      <u>{{ $t(`COLORS.${actColor.code}`) }}</u>
                    </span>
                    <template v-if="getQuantityByColor(actColor.code) != 0">
                      <div
                        v-for="sizeQuantity in quantityByColorAndProduct[
                          actColor.code
                        ]"
                        :key="sizeQuantity.size"
                      >
                        <span>
                          {{ $t(`SIZES.${sizeQuantity.size}`) }}:
                          {{ sizeQuantity.quantity }}
                        </span>
                      </div>
                    </template>
                  </ColorBall>
                </div>
              </v-col>
              <v-col>
                <v-fade-transition>
                  <v-overlay v-if="cargando" absolute>
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-overlay>
                </v-fade-transition>
                
                <v-data-table
                  v-if="(actualItems = getProductosByModeloColor(actualColor))"
                  :id="`productos-${actualColor}`"
                  :headers="headers"
                  :items="actualItems"
                  :items-per-page="actualItems.length"
                  hide-default-footer
                  class="table-products"
                >
                  <template #item.SIZE="{ item }">
                    <v-chip>
                      <b>{{ $t(`SIZES.${item.SIZE}`) }}</b>
                    </v-chip>
                  </template>

                  <template #item.QUANTITY="{ item }">
                    <div class="d-flex align-center max-width-input">
                      <product-input
                        input-classes="text-body"
                        :value="getQuantity(item)"
                        :product="item"
                        @input="quantity => updateQuantity(item, quantity)"
                        @keyup.enter="updateQuantityField"
                        @keyup.right="tryMoveNextColor"
                        @keyup.left="tryMovePreviousColor"
                      ></product-input>
                      <!-- <v-text-field
                class="text-body"
                :label="$t('general.Cantidad')"
                :value="getQuantity(item)"
                type="number"
                min="0"
                @input="quantity => updateQuantity(item, quantity)"
                @keyup.enter="updateQuantityField"
                @keyup.right="tryMoveNextColor"
                @keyup.left="tryMovePreviousColor"
              ></v-text-field> -->
                    </div>
                  </template>

                  <!-- <template v-slot:item.STOCK="{ item }">
           <span ><span v-show="item.STOCK.hasMore">+</span>{{ item.STOCK.stock }}</span>
          </template> -->

                  <template #item.MEDIDAS="{ item }">
                    <small
                      v-for="(zona, key) in item.MEDIDAS"
                      :key="key"
                      class="text-body text-size-zones"
                    >
                      <u class="font-weight-bold">
                        {{ $t("Zonas." + getZoneCodeById(zona.Posicion)) }}
                      </u>
                      :
                      <span class="font-weight-light">
                        &nbsp;&nbsp;&nbsp;&nbsp;{{ zona.Ancho }}X{{
                          zona.Alto
                        }}mm &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </small>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <CotizadorBtn
            content-class="px-10"
            :on="{
              click: () => {
                deleteAllProducts();
              }
            }"
          >
            {{ $t("cart.CleanCart") }}
          </CotizadorBtn>
          <CotizadorBtn
            content-class="px-10"
            class="ml-4"
            success
            :on="{
              click: () => {
                emitClick(false);
              }
            }"
          >
            {{ $t("cart.addToBudget") }}
          </CotizadorBtn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CotizadorBtn from "../basic/CotizadorBtn.vue";
import { dynamicPrice, numProductByType } from "../../utils/priceUtils";
import ColorBall from "../basic/ColorBall.vue";
import productInput from "@/components/basic/productInput.vue";

export default {
  name: "Productos",
  components: { CotizadorBtn, ColorBall, productInput },
  props: {
    color: Object,
    cantidadInicial: {
      type: Number,
      default: 0
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      productosModeloColor: null,
      headers: [
        {
          text: this.$t("general.Talla"),
          align: "start",
          sortable: false,
          value: "SIZE",
          class: "texttable title-table-header"
        },
        {
          text: this.$t("general.Cantidad"),
          // align: "start",
          sortable: false,
          value: "QUANTITY",
          class: "texttable title-table-header"
        }
        // {
        //   text: this.$t("general.Stock"),
        //   // align: "start",
        //   sortable: false,
        //   value: "STOCK",
        //   class: "texttable",
        // },
        // {
        //   text: this.$t("general.Tamaño"),
        //   align: "start",
        //   sortable: false,
        //   value: "MEASURES",
        //   class: "texttable"
        // },
        // {
        //   text: this.$t("general.MedidasImpresion"),
        //   align: "start",
        //   sortable: false,
        //   value: "MEDIDAS",
        //   class: "texttable title-table-header"
        // }
      ],
      cargando: false,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      actualColor: null,
      debouncedProductQuantity: {},
      loadingPrices: 0
    };
  },
  computed: {
    ...mapGetters("carrito", [
      "carrito",
      "quantityByColor",
      "quantityByColorAndProduct",
      "soloBlancoEnCarritoSublimacion",
      "totalUnidades"
    ]),
    ...mapGetters("modelo", [
      "productos",
      "modelPrice",
      "waitExtranet",
      "getProductosByModeloColor",
      "zonas",
      "colorAvalible",
      "currency",
      "hasRangePrices",
      "modelInfoFromCatalog",
      "modelCode",
      "sublimacionSoloBlanco"
    ]),
    ...mapGetters("config", ["key", "isYouReprocesos"]),
    cantidadByTalla: function () {
      // if this.carrito.productos is empty

      let result = this.carrito.productos.reduce((grouping, item) => {
        console.log("REDUCE!", grouping, item);
        if (grouping[item.talla] === undefined) {
          grouping[item.talla] = item.cantidad;
        } else {
          grouping[item.talla] += item.cantidad;
        }
        return grouping;
      }, {});

      console.log("RESULT!", result);
      return { S: 200, M: 500 };
    },
    actualColorInfo: function () {
      return this.colorAvalible.find(c => c.code == this.actualColor);
    }
  },
  watch: {
    dialog: function () {
      setTimeout(() => {
        const heightDialog =
          document.getElementById("productSelectorDialogBody")?.scrollHeight ??
          0;
        this.updateHeightFrame(heightDialog + 53);
      }, 250);
    },
    modelCode(newVal) {
      if (newVal && !this.dialog && !this.totalUnidades) {
        this.dialog = true;
      }
    },
    active: function (newActive, oldActive) {
      if (newActive !== oldActive) this.dialog = newActive;
    },
    colorAvalible: function (newVal) {
      if (newVal.length == 0) this.actualColor = null;
      else this.actualColor = newVal[0].code;
    }
  },
  async created() {
    this.dialog = this.active;
    this.updateProductosModeloColor();
    await this.waitExtranet;
    if (this.colorAvalible.length > 0)
      this.actualColor = this.colorAvalible[0].code;
  },
  mounted() {
    if (this.modelCode && !this.dialog && !this.totalUnidades) {
      this.dialog = true;
    }
  },
  methods: {
    ...mapActions("carrito", [
      "fetchCarrito",
      "addProducto",
      "deleteProducto",
      "deleteAllProducts"
    ]),
    ...mapActions("modelo", ["addAlert"]),
    ...mapActions("config", ["updateHeightFrame"]),
    getQuantityByColor(color) {
      return this.quantityByColor?.[color] ?? 0;
    },
    async removeAllProducts() {
      this.deleteAllProducts();
    },
    emitClick(payload) {
      this.dialog = payload;
      parent.postMessage({ type: "scrollTop" }, "*");
      if(!payload)
      this.$emit("close", payload);
    },
    changeColor(colorCode) {
      if (
        this.loadingPrices > 0 ||
        (this.soloBlancoEnCarritoSublimacion && colorCode != "01") ||
        (colorCode == "01" &&
          this.sublimacionSoloBlanco &&
          !this.soloBlancoEnCarritoSublimacion &&
          this.totalUnidades > 0)
      )
        return;
      this.actualColor = colorCode;
      this.updateHeightFrame();
      this.$emit("changeColor", colorCode);
    },
    getZoneCodeById(zone) {
      if (this.zonas.length == 0) return null;

      let search = this.zonas.find(z => z.Posicion == zone);

      if (search == null)
        return this.zonas[0].Zona.toUpperCase().replace("_", " ");

      if (!search.Zona) {
        console.error("Zona sin identificador de Zona")
      }
      return search.Zona?.toUpperCase()?.replace("_", " ");
    },
    updateProductosModeloColor: async function (modelo, color) {
      if (modelo == null) modelo = this.modelo;
      if (color == null) color = this.color;
      if (modelo == null || color == null) return;
      // this.productosModeloColor = this.productos.find(
      //   (p) =>  p.modelo == modelo.id && p.color == color.CODE
      // )?.productos;

      if (
        this.productosModeloColor == null ||
        this.productosModeloColor.length == 0
      ) {
        this.productosModeloColor = this.getProductosByModeloColor(
          this.actualColor
        ).find(p => p.color == color);
      }

      if (
        this.productosModeloColor != null &&
        this.productosModeloColor.length > 0
      )
        return;

      // await this.fetchProductos({
      //   modelo: modelo.id,
      //   color: color.CODE,
      //   key: this.key,
      // });
      this.productosModeloColor = this.productos.find(
        p => p.modelo == modelo.id && p.color == color.CODE
      )?.productos;
    },
    getQuantity: function (producto) {
      let productCart = this.carrito.productos?.find(
        p => p.id == producto.CODE || p.id == producto.id
      );

      if (!productCart || !productCart.cantidad) return 0;
      else return parseInt(productCart.cantidad);
    },
    updateQuantity: async function (producto, cantidad) {
      let product = producto.CODE ?? producto.code ?? producto;
      if (!this.debouncedProductQuantity[product]) this.loadingPrices++;
      clearTimeout(this.debouncedProductQuantity[product]);
      this.debouncedProductQuantity[product] = setTimeout(async () => {
        let productCart = this.carrito.productos.find(
          p => p.id == producto.CODE || p.id == producto.id
        );

        let moq = 1;

        if (product) {
          moq = this.modelInfoFromCatalog.products[product]?.MOQ;
        }

        if (cantidad < moq && cantidad != 0) {
          cantidad = moq * Math.floor(cantidad / moq);
          // cantidad = moq * Math.ceil(cantidad / moq);
          this.addAlert({
            title: this.$t("alert.errorMinUnitTitleDialog", {
              modelo: this.modelCode
            }),
            code: "errorNoMoq",
            minUnit: moq ?? 0,
            productCode: producto.CODE
          });
        }

        if (cantidad == 0 && productCart == null) return;

        let actualProductInCart = this.carrito.productos.find(
          p => p.id == product
        );
        // console.log("🚀 ~ file: Productos.vue:294 ~ actualProductInCart:", actualProductInCart)

        if (actualProductInCart) {
          await this.deleteProducto(actualProductInCart);
        }

        // Proceso de actualización de precios
        const pricesStm = this.modelInfoFromCatalog.originalShop
          ? this.modelInfoFromCatalog.originalShop == "STA"
          : this.modelInfoFromCatalog.shop == "STA";

        if (
          pricesStm &&
          this.hasRangePrices &&
          !this.modelInfoFromCatalog.products[product].OUTLET
        ) {
          let cartList = [];

          // console.log("🚀 ~ file: App.vue:433 ~ this.carrito.productos:", this.carrito.productos)
          cartList = this.carrito.productos.map(prod => {
            return {
              model: this.modelCode,
              product: prod.id,
              quantity: Number(prod.cantidad)
            };
          });
          // console.log("🚀 ~ file: Productos.vue:340 ~ cartList:", cartList)

          // Guardamos los productos del carrito para recalcular
          let actualCarritoProductos = [...this.carrito.productos];

          let selectedAmounts = {};
          selectedAmounts[product] = Number(cantidad);

          let quantityGrouped = numProductByType(
            this.modelInfoFromCatalog,
            this.modelPrice.prices,
            cartList,
            selectedAmounts
          );

          // Obtenemos el reango actual
          let actualRange = this.modelPrice.prices.RANGEPRICE[product];

          // Obtenemos la key actual
          let actualRangeKey = [
            actualRange.RANGES.COLOR,
            actualRange.RANGES.SIZEGROUP
          ].toString();
          // console.log("🚀 ~ file: Productos.vue:324 ~ actualRangeKey:", actualRangeKey)

          let actualQuantity = quantityGrouped.get(actualRangeKey);
          if (cantidad == 0 && productCart != null) {
            // Delete
            await this.deleteProducto(productCart);
          } else {
            // console.log("🚀 ~ file: Productos.vue:331 ~ actualQuantity:", actualQuantity)
            let price = this.calculatePrice(producto, actualQuantity);
            let params = {
              producto: producto,
              cantidad: cantidad,
              precio: price,
              color: this.actualColor
            };
            await this.addProducto(params);
          }

          actualCarritoProductos.forEach(async p => {
            await this.deleteProducto(p.id);
            let productCodeToSearch = p.id;
            product = this.modelInfoFromCatalog.products[productCodeToSearch];
            product.CODE = productCodeToSearch;
            actualRange = this.modelPrice.prices.RANGEPRICE[p.id];
            actualRangeKey = [
              actualRange.RANGES.COLOR,
              actualRange.RANGES.SIZEGROUP
            ].toString();
            actualQuantity = quantityGrouped.get(actualRangeKey);

            let price = this.calculatePrice(p.id, actualQuantity);
            let params = {
              producto: product,
              cantidad: p.cantidad,
              precio: price,
              color: p.color
            };
            // console.log("🚀 ~ file: Productos.vue:357 ~ params:", params)
            await this.addProducto(params);
          });
        } else {
          if (cantidad == 0 && productCart != null) {
            // Delete
            await this.deleteProducto(productCart);
          } else {
            // Add or Update
            let price = this.calculatePrice(producto, cantidad);
            let params = {
              producto: producto,
              cantidad: cantidad,
              precio: price,
              color: this.actualColor
            };
            await this.addProducto(params);
          }
        }

        producto.QUANTITY = parseInt(cantidad);

        this.loadingPrices--;
        this.debouncedProductQuantity[product] = null;
        // console.timeEnd("updateQuantityDebounced");
      }, 1000);
    },
    updateQuantityField: function (event) {
      event.preventDefault();
      this.dialog = false;
    },
    tryMoveNextColor: function (event) {
      event.preventDefault();

      if (this.loadingPrices > 0) return;

      for (let index = 0; index < this.colorAvalible.length; index++) {
        const color = this.colorAvalible[index];
        if (color.code === this.actualColor) {
          const newIndex = index + 1;
          if (newIndex < this.colorAvalible.length) {
            this.changeColor(this.colorAvalible[newIndex].code);
            return;
          } else {
            this.changeColor(this.colorAvalible[0].code);
            return;
          }
        }
      }
    },
    tryMovePreviousColor: function (event) {
      event.preventDefault();

      if (this.loadingPrices) return;

      for (let index = 0; index < this.colorAvalible.length; index++) {
        const color = this.colorAvalible[index];
        if (color.code === this.actualColor) {
          const newIndex = index - 1;
          if (newIndex < this.colorAvalible.length) {
            this.changeColor(this.colorAvalible[newIndex].code);
            return;
          } else {
            this.changeColor(this.colorAvalible[0].code);
            return;
          }
        }
      }
    },
    calculatePrice: function (producto, cantidad) {
      // Comprobamos si nos encontramos en stamina o no
      let NumProductSameType = parseInt(cantidad);

      // Si nos encontramos en stamina, debemos juntar la cantidad de aquellos productos con los mismos precios

      let aux = dynamicPrice(
        this.modelPrice.prices,
        this.currency,
        this.modelInfoFromCatalog,
        producto.CODE ?? producto.code ?? producto,
        NumProductSameType,
        "en",
        this.modelPrice.extra.priceNoIncr,
        this.modelPrice.extra.aplicaPack,
        this.modelPrice.extra.hasRangePrices
      );
      console.log("🚀 ~ aux:", aux);
      let calculatedPrice = parseFloat(aux);
      console.log("🚀 ~ calculatedPrice:", calculatedPrice);

      return calculatedPrice;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.dialog-zindex {
  z-index: 1000;
}

.productModal {
  margin-top: 83px !important;
  overflow-x: hidden;

  &.youReprocesosProductModal {
    margin-top: 12px !important;
    position: absolute;
    top: 35px;
    max-width: 1481px;
    max-height: 810px !important;

    &.smallModal {
      max-width: 600px;
    }
    // #productSelectorDialogBody {
    //   max-height: 956px;
    //   min-height: 760px;
    // }

    .table-products {
      overflow-x: hidden;
      overflow-y: auto;

      thead,
      tbody {
        display: block;
      }

      tbody {
        max-height: 400px !important;
        overflow-y: scroll;
      }
    }
  }

  .title-table-header {
    font-size: 18px !important;
    font-weight: 700;
    text-transform: uppercase;
    font-family: $heading-font-family;
    min-height: 48px;
  }

  .v-data-table >>> th {
    font-size: 20px !important;
  }

  .text-body {
    font-size: 14px !important;
  }

  .productos-titulo {
    font-size: 200% !important;
    // margin-left: 25px;
    width: 100%;

    span {
      font-size: 18px !important;
      font-weight: 300;
      font-family: $heading-font-family;
    }

    .left-border {
      border-left: 1px solid white;
      padding-left: 28px;
      padding-right: 28px;
      cursor: pointer;
      &:hover {
        background: #434343;
      }
    }

    .modelo-name {
      font-size: 20px;
      font-weight: bold;
    }
  }

  .color-list {
    width: 100%;
  }
}

.text-size-zones {
  font-size: 10px;
}

.v-toolbar__content {
  padding-right: 0px;
}

@media (max-width: 600px) {
  .productModal.youReprocesosProductModal {
    top: 0px;
    margin-top: 0px !important;
    max-height: 100%;

    // #productSelectorDialogBody {
    //   max-height:  100%;
    //   overflow-y: scroll;
    // }
  }
}
</style>
