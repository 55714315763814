/*global customDIM*/
let initialized = false;
let partList = [];
let principalPart = null;

let apiCallHistory = [];

// function log(msg) {
//     console.log("%c" + msg, "color:blue;font-weight:bold; background-color:white;")
// }

/**
 * Comprueba si esta inicializado el canvas 3D
 * @returns {Boolean} Si se encuentra inicializado o no
 */
const checkIsInitialized = () => {
    if (!initialized) {
        console.warn("El canvas 3D no está inicializado")
        return false;
    }

    return true;
}


let apiCallQueue = Promise.resolve();

/**
 * Función para encapsular las llamadas a customDIM.api.call
 * @param {String} method Método a llamar en la API
 * @returns {Promise} Promesa con el resultado de la llamada
 */
const apiCall = (method) => {
    if (!method.endsWith(')')) {
        method += '()';
    }
    apiCallQueue = apiCallQueue.then(() => {
        return new Promise((resolve, reject) => {
            customDIM.api.call(method, (result) => {
                console.log(`💻 [DIM UTILS] ~ apiCall ~ ${method} result:`, result);
                apiCallHistory.push({ method, result });
                console.log("💻 ~ apiCall ~ apiCallHistory:", apiCallHistory)
                resolve(result);
            }, (error) => {
                console.error(`💻 [DIM UTILS] ~ apiCall ~ ${method} error:`, error);
                reject(error);
            });
        });
    });
    return apiCallQueue;
};

/**
 * Función para recuperar la parte principal de la prenda (aquella en la que podemos asignar colores)
 * @returns {Object} Objeto con la parte obtenida
 * @throws {Error} Error si no se ha inicializado el canvas 3D, o si no se encuentra la zona principal
 */
const getPrincipalPart = () => {
    if (!checkIsInitialized()) {
        throw new Error("El canvas 3D no está inicializado")
    }

    if (!principalPart) {
        console.log("💻 ~ Buscando la parte principal", partList);
        principalPart = partList?.find(p => p.customizationOptions.some(c => c == "colours"))

        if (!principalPart) {
            throw new Error("No hemos podido encontrar la parte principal del modelo")
        }
    }

    return principalPart;
}

/**
 * Función para buscar un modelo en la API y cargarlo
 * @param {String} idModel Modelo que queremos buscar para cargar
 * @returns {Promise} Promesa con el listado de partes que tenemos
 */
export const searchModelOnApiAndSetActive = (idModel = 6554) => {
    console.log("💻 ~ customDIM.api.call ~ Searching:", idModel)

    return apiCall('model.getGroups').then(result => {
        console.log("💻 ~ file: customDimUtils.js:73 ~ returnapiCall ~ result:", result)

        if (!result || !result.length) {
            return Promise.reject('No se ha encontrado el modelo')
        }

        let model = result.find(m => m.sku.sku == idModel);
        console.log("💻 ~ customDIM.api.call ~ model:", model, model?.id)

        if (!model) {
            return Promise.reject('No se ha encontrado el modelo')
        }

        return apiCall(`model.setGroup(${model.id})`);
    }).then(() => {
        return apiCall('part.getList')
    }).then(result => {
        partList = result;
        return result;
    });
}

const checkInit = (reject) => {
    const canvas = document.getElementById('canvas3d');

    if (!canvas) {
        reject('Canvas no encontrado');
    }

    // Comprobamos que existe la librería customDIM
    if (!customDIM) {
        reject('customDIM no encontrado');
    }

    return canvas;
}



/**
 * Función que inicializa el canvas 3D
 * @param {String} json Objeto con la personalización actual, y donde se van a guardar la personalización actual
 * @param {String} idModel id del configurador
 * @returns {Promise} Promesa que se encarga de generar
 */
export const init3DCanvas = (json, idModel = 4) => {
    console.log("💻 ~ init3DCanvasAndSearchByIdsap ~ idModel:", idModel)

    return new Promise((resolve, reject) => {
        // Comprobamos que existe el elemento con id canvas3d
        checkInit(reject);

        if (!idModel) {
            reject('No se ha proporcionado un idModel');
        }

        // Inicializamos el canvas 3D
        customDIM.install('canvas3d', 'gorfactoryTest', null, idModel, json, () => {
            console.log("💻 ~ onAvalibleAPICustomDIM")
            // initialized = true;
            apiCall('part.getList').then(result => {
                console.log("💻 ~ file: customDimUtils.js:165 ~ customDIM.install ~ result: @", result)
                partList = result;
                initialized = true
                resolve(result);
            })


        }, () => {
            console.log("💻 ~ onFinalizedCustomDIM")
        }, () => {
            console.log("💻 ~ onChangeCustomDIM”:")
        });


    })
}

/**
 * Función que inicializa el canvas 3D
 * @param {String} json Objeto con la personalización actual, y donde se van a guardar la personalización actual
 * @param {String} idModel idsap del modelo (por defecto 6554)
 * @returns {Promise} Promesa que se encarga de generar
 */
// export const init3DCanvasAndSearchByIdsap = (json, idModel = 6554) => {
//     console.log("💻 ~ init3DCanvasAndSearchByIdsap ~ idModel:", idModel)
//     return new Promise((resolve, reject) => {
//         // Comprobamos que existe el elemento con id canvas3d
//         checkInit(reject);

//         if (!idModel) {
//             reject('No se ha proporcionado un idModel');
//         }

//         // Inicializamos el canvas 3D
//         customDIM.install('canvas3d', 'gorfactoryTest', null, 16, json, () => {
//             console.log("💻 ~ onAvalibleAPICustomDIM")
//             initialized = true;

//             searchModelOnApiAndSetActive(idModel).then(resolve).catch(reject);

//             // resolve();

//         }, () => {
//             console.log("💻 ~ onFinalizedCustomDIM")
//         }, () => {
//             console.log("💻 ~ onChangeCustomDIM”:")
//         });

//     })
// }


/**
 * Función para cargar un color en el modelo 3d
 * @param {String} colorCode código de color que queremos seleccionar
 * @returns {Promise} Promesa con la llamada a la función
 */
export const loadColor = (colorCode) => {

    if (!colorCode) {
        console.warn("Nos debe proporcionar un color válido")
        return;
    }

    const hexcode = (colorCode?.hexcode || colorCode).replace("#", "");
    console.log("💻 ~ loadColor ~ hexcode:", hexcode)

    // Buscamos en la partList aquella que tiene como customizationOptions

    principalPart = getPrincipalPart();
    console.log("💻 ~ loadColor ~ principalPart:", principalPart)

    return apiCall(`design.setColourOfPieceByPart( ${principalPart.id}, 0, '${hexcode}' )`);

}


/**
 * Función para cargar una zona en concreto
 * @param {Object} Zona zona en la que se quiere cambiar el modelo
 * @returns {Promise} Promesa con la llamada a la función
 */
export const setActiveZone = ({ Posicion, Zona }) => {
    if (!checkIsInitialized()) {
        throw new Error("El canvas 3D no está inicializado")
    }

    principalPart = getPrincipalPart();

    let informationZone = partList.find(p => {
        if (typeof p.sku == 'number') {
            return p.sku == Posicion
        }

        return p.sku?.sku == Posicion
    });
    console.log("💻 ~ setActiveZone ~ partList:", partList)
    console.log("💻 ~ setActiveZone ~ informationZone:", informationZone)

    if (!informationZone) {
        const actualZone = Zona?.replace(/\s+/g, '')?.replace(/_/g, ' ')?.toUpperCase();
        console.log("💻 ~ setActiveZone ~ actualZone:", actualZone)

        informationZone = partList.find(p => p.name == actualZone);
    }

    if (!informationZone || !informationZone.customizationOptions.some(c => c == "images")) {
        throw new Error("No hemos podido encontrar la zona")
    }

    return new Promise((resolve, reject) => {
        apiCall(`part.setActive(${informationZone.id})`).then(() => resolve(informationZone)).catch(reject);
    })
}

/**
 * Función para subir una imagen en base64 a la zona seleccionada para
 * @param {String} base64 imagen en base64 que queremos subir
 * @param {Object} activeZone zona activa en la que queremos subir la imagen
 * @returns {Promise} Promesa con la llamada a la función
 */
export const uploadFile = (base64, activeZone) => {
    if (!checkIsInitialized()) {
        throw new Error("El canvas 3D no está inicializado")
    }

    if (!activeZone?.id) {
        throw new Error("No ha proporcionado la zona activa")
    }

    return apiCall(`design.image.uploadBase64("${base64}")`).then(result => {
        console.log("💻 ~ uploadFile ~ result:", result);

        if (!result?.id) {
            return Promise.reject("No hemos podido subir la imagen");
        }

        return apiCall(`design.image.addToPart(${activeZone.id},${result.id})`).then(() => result);
    }).then(data => {
        console.log("💻 ~ addToPart ", data);
        return data;
    });
}

/**
 * Elimina un archivo de imagen asociado a una zona activa.
 *
 * @param {string|Object} logoId - El identificador del logo o un objeto que contiene la URL o el path del logo.
 * @param {Object} activeZone - La zona activa que contiene el logo.
 * @param {number} activeZone.id - El identificador de la zona activa.
 * @throws {Error} Si el canvas 3D no está inicializado.
 * @throws {Error} Si no se proporciona un logo válido.
 * @returns {Promise<void>} Una promesa que se resuelve cuando la imagen ha sido eliminada.
 */
export const removeFile = (logoId, activeZone) => {
    if (!checkIsInitialized()) {
        throw new Error("El canvas 3D no está inicializado")
    }

    if (!logoId) {
        throw new Error("No ha proporcionado la zona activa")
    }

    if (typeof logoId !== 'string') {
        // Necesitamos que sea el path, que es lo único que tengo entre identificaciones
        logoId = logoId?.url ?? logoId?.path ?? "";

        if (!logoId) {
            throw new Error("No ha proporcionado un logo válido")
        }
    }


    console.log("CONTROL Buscando imagen a eliminar con ID:", logoId, activeZone);

    return apiCall(`design.image.getListByPart( ${activeZone.id} )`).then(result => {
        let image = result.find(i => i.path == logoId || i.url == logoId);

        if (!image) {
            console.warn("No hemos encontrado la imagen a eliminar", logoId, activeZone, result)
            return;
        }

        return apiCall(`design.image.remove(${image.id})`);
        
    })

    // return apiCall(`design.image.remove(${logoId})`);
}

/**
 * Restablece el estado del canvas 3D y del objeto customDIM.
 * 
 * Esta función realiza las siguientes acciones:
 * - Establece la bandera `initialized` a `false`.
 * - Limpia el array `partList`.
 * - Establece `principalPart` a `null`.
 * - Restablece varias propiedades del objeto `customDIM.state` a sus estados iniciales.
 */
export const resetCanvas3d = () => {
    initialized = false;
    partList = [];
    principalPart = null;

    customDIM.state.initialized = false;
    customDIM.state.iframe = null;
    customDIM.state.ready = null;
    customDIM.state.waiting = null;

}

/**
 * Obtiene una captura de pantalla actual con las dimensiones y configuraciones especificadas.
 *
 * @param {Object} [options={}] - Opciones para la captura de pantalla.
 * @param {number} [options.width=460] - Ancho de la imagen.
 * @param {number} [options.height=460] - Altura de la imagen.
 * @param {string} [options.backgroundColor='FFFFFF'] - Color de fondo de la imagen en formato hexadecimal.
 * @param {number} [options.alpha=1] - Nivel de transparencia de la imagen (0 a 1).
 * @returns {Promise} - Promesa que se resuelve con la imagen obtenida.
 */
export const getActualScreenshot = ({width = 460, height = 460, backgroundColor = 'FFFFFF', alpha = 1} = {}) => {
    return apiCall(`scene.getImage(${width}, ${height}, '${backgroundColor}', ${alpha})`);
}

/**
 * Obtiene el diseño actual basado en una lista de diseños.
 * 
 * @param {Array} listDesign - Lista de diseños con información de zona y posición.
 * @param {Array} listColor - Lista de colores a aplicar en el diseño.
 * @returns {Promise<Object>} Promesa que resuelve con el diseño actual, incluyendo información 3D y lista de zonas con imágenes.
 */
export const getActualDesign = (listDesign, listColor, selectedColorcode) => {
    console.log("🚀 ~ getActualDesign ~ listColor:", listColor)
    return apiCall(`scene.getCustomizationAndLink`).then(result => {
        let actualDesignResult = {
            info3d: result,
            listImgZones: []
        }

        if (!listDesign.length) {
            return actualDesignResult;
        }

        // Aunque en listDesign tengamos múltiples diseños para una única zona, solo tenemos que mover el modelo a esa zona una vez.
        // Entonces vamos a mapear listDesign en objetos que contenga zona y posicion del listDesign
        // Evitando aquellas zonas que no tengan idImg (no tienen personalización) y aquellas que ya se encuentre dentro la combinación Zona-Posicion
        const filteredList = listDesign.reduce((accumulator, current) => {
            if (current.idImg) {
                const key = `${current.zona}-${current.posicion}`; // Creas una "clave única" combinando Zona y Posición.

                if (!accumulator.seenKeys.has(key)) {
                    accumulator.seenKeys.add(key);
                    accumulator.filtered.push({
                        Zona: current.zona,
                        Posicion: current.posicion
                    });
                }
            }
            return accumulator;
        }, { seenKeys: new Set(), filtered: [] }).filtered;

        if(!filteredList.length) {
            return actualDesignResult;
        }

        return new Promise((resolve, reject) => {
            let listPromises = Promise.resolve();
            
            principalPart = getPrincipalPart();

            listColor.forEach((color) => {
                listPromises = listPromises.then(() => apiCall(`design.setColourOfPieceByPart( ${principalPart.id}, 0, '${color.hexcode.replace("#", "")}' )`));

                filteredList.forEach((item) => {
                    listPromises = listPromises.then(() => setActiveZone(item).then(() => getActualScreenshot()).then((actualImg) => {
                        actualDesignResult.listImgZones.push({
                            color,
                            ...item,
                            img: actualImg
                        });
                    }))
                })
            });

            const originalColor = listColor.find(c => c.code == selectedColorcode);

            listPromises = listPromises.then(() => apiCall(`design.setColourOfPieceByPart( ${principalPart.id}, 0, '${originalColor.hexcode.replace("#", "")}' )`));

            listPromises = listPromises.then(() => resolve(actualDesignResult)).catch(reject);
        })


    });
}