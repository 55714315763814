var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{directives:[{name:"no-scroll-body",rawName:"v-no-scroll-body"}],staticClass:"dialog-zindex margin-top-banner",attrs:{"scrollable":"","transition":"dialog-bottom-transition","content-class":'margin-top-banner productModal smallModal youReprocesosProductModal'},scopedSlots:_vm._u([{key:"activator",fn:function({on}){return [_c('CotizadorBtn',{staticClass:"mw-100 w-100 pr-2",attrs:{"content-class":"w-100","sm":"","animated":_vm.modelPrice && _vm.totalUnidades == 0,"on":on,"disabled":!_vm.modelPrice}},[_vm._v(" + "+_vm._s(_vm.$t("general.sizeColorQuantity"))+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"extended":"","extension-height":"0","height":"fit-content","dark":"","color":"black"}},[_c('v-toolbar-title',{staticClass:"productos-titulo d-flex justify-space-between"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center py-0",attrs:{"cols":"8"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center py-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"modelo-name"},[_vm._v(" "+_vm._s(_vm.$t(`MODELS.${_vm.modelCode}.NAME`))+" ")])]),_c('v-col',{staticClass:"d-flex justify-md-center align-center py-0",attrs:{"cols":"12","md":"6"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("general.Anadir talla, color y cantidad"))+" ")])])],1)],1),_c('v-col',{staticClass:"d-flex flex-row-reverse pr-0 py-0",attrs:{"cols":"4"}},[_c('div',{staticClass:"left-border",on:{"click":function($event){return _vm.emitClick(false)}}},[_c('v-btn',{attrs:{"icon":"","dark":""}},[_c('v-icon',{attrs:{"xl":""}},[_vm._v("mdi-close")])],1)],1)])],1)],1)],1)],1)],1),_c('v-card-text',{attrs:{"id":"productSelectorDialogBody"}},[_c('list-product-input')],1),_c('v-card-actions',[_c('v-spacer'),_c('CotizadorBtn',{attrs:{"content-class":"px-10","on":{
             click: () => {
               _vm.deleteAllProducts();
             }
           }}},[_vm._v(" "+_vm._s(_vm.$t("cart.CleanCart"))+" ")]),_c('CotizadorBtn',{staticClass:"ml-4",attrs:{"content-class":"px-10","success":"","on":{
             click: () => {
               _vm.emitClick(false);
             }
           }}},[_vm._v(" "+_vm._s(_vm.$t("cart.addToBudget"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }