<template>
  <span :class="classSpan ?? 'mb-3'" class="d-flex">
    <v-checkbox
      v-model="localValue"
      :label="
        checkboxLabel
      "
      color="primary darken-2 white--text"
      class="mt-1 pt-1"
      hide-details="true"
      :disabled="disabled"
    ></v-checkbox>

    <v-tooltip v-if="icon && tooltipText" top>
      <template #activator="{ on, attrs }">
        <v-btn class="mt-1" icon v-bind="attrs" v-on="on">
          <v-icon>{{ icon }}</v-icon>
        </v-btn>
      </template>
      <span>
        {{
          tooltipText
        }}
      </span>
    </v-tooltip>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    checkboxLabel: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    tooltipText: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    classSpan: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      localValue: false
    };
  },
  watch: {
    localValue(newVal) {
      if (newVal != this.value) this.$emit("input", newVal);
    },
    value(newVal) {
      if(newVal != this.localValue) this.localValue = newVal;
    }
  },
  created() {
    this.localValue = this.value;
  }
};
</script>

<style>
.v-input--checkbox {
  .v-label {
    font: normal normal normal 12px/15px $heading-font-family;
    letter-spacing: 0px;
  }
}
</style>
