<template>
    <div>
        <v-dialog ref="userSearchDialog" v-model="localDialog" hide-overlay persistent max-width="400px">
            <v-card :loading="loading ? 'primary' : false" elevation="4" outlined>
                <v-card-title>
                    {{ $t("login.TitleUserModal") }}
                </v-card-title>

                <v-card-text>
                    <div class="d-flex flex-column align-center">
                        <span class="mt-3">
                            {{ $t("login.TitleSearchLabel") }}
                        </span>
                        <span class="mt-3">
                            {{ $t("login.TitleSearchMax10Label") }}
                        </span>
                    </div>
                </v-card-text>


                <div class="mx-5">
                    <v-autocomplete
                        v-model="selectedClient"
                        :items="userList"
                        :loading="loading"
                        :search-input.sync="search"
                        item-text="label"
                        item-value="clientId"
                        clearable
                        color="primary"
                        @update:search-input="searchUserBD"
                        @change="onChange"
                    >
                    </v-autocomplete>

                </div>

                <v-divider></v-divider>

                <v-card-actions class="d-flex align-center">
                    <v-checkbox
                        v-model="showAllClient"
                        class="mt-0"
                        label="Buscar entre todos los clientes"
                        color="primary"
                        hide-details
                    ></v-checkbox>
                    <v-spacer></v-spacer>
                    <v-btn class="white--text" :loading="loading" :disabled="loading || !selectedClient" color="primary" @click="loginSelectedClient">
                        {{ $t("login.InitUserModal") }}
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash';


export default {
    props: {
        dialog: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            localDialog: this.dialog,
            selectedClient: null,
            // selectedUser: null,
            loading: false,
            search: '',
            showAllClient: false
        };
    },
    computed: {
        ...mapGetters('config', ['userList']),
    },
    methods: {
        ...mapActions('config', ['searchUserList', 'loginClient', 'key']),
        cerrar: function () {
            this.localDialog = false;
            this.$emit("cerrar");
        },
        loginSelectedClient() {
            this.loading = true;

            this.loginClient({
                clientId: this.selectedClient
            });

            this.loading = false;
            this.cerrar();
        },
        searchUserBD: debounce(async function() {
            console.log("searchUserBD");
            if(!this.search) return;
            this.loading = true;
            await this.searchUserList({
                search: this.search,
                showAllClient: this.showAllClient
            });
            this.loading = false;
            }, 500),
        onChange() {
            // this.selectedUser = null;
        },
    },
    watch: {
        dialog(newVal) {
            if (newVal != this.localDialog) this.localDialog = newVal;

            if (newVal) {
                 this.$nextTick(() => { this.$refs.userSearchDialog.showScroll(); });
            }
        },
        localDialog(newVal) {
            if (newVal != this.dialog) {
                this.$emit("cerrar");
            }
        },
    },
};

</script>
