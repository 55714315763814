var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panels',{staticClass:"componentCartElement",attrs:{"accordion":"","disabled":_vm.loading}},_vm._l((_vm.cart),function(reproceso){return _c('v-expansion-panel',{key:reproceso.id,attrs:{"id":'reproceso_expansion_' + reproceso.id,"active-class":"active-panel","hover":"","disabled":_vm.loading},on:{"change":function($event){return _vm.updateTimeoutHeight(reproceso.id)}}},[_c('v-expansion-panel-header',[_c('span',{staticClass:"presupuesto-title"},[_vm._v(" "+_vm._s(_vm.$t("general.Presupuesto"))+" "+_vm._s(reproceso.id)+" ")]),_vm._t("actions",function(){return [_c('div',{staticClass:"max-width-fit-content"},[_c('span',{staticClass:"mr-2 modelo-name"},[_vm._v(" "+_vm._s(reproceso.modelos?.[0]?.nombre)+" ")]),_c('span',{staticClass:"mr-2 modelo-code"},[_vm._v(" "+_vm._s(reproceso.modelos?.[0]?.modelCode)+" ")]),_c('b',{staticClass:"mr-2 price-total"},[_vm._v(" "+_vm._s(_vm.correct2Decimals(reproceso.total))+_vm._s(_vm.currencySymbol)+" ")]),(!_vm.disableDelete && _vm.expandedId != reproceso.id)?_c('v-btn',{attrs:{"loading":_vm.loadingDelete[reproceso.id] || _vm.loading,"disable":_vm.loadingPrint[reproceso.id] ||
              _vm.loadingDelete[reproceso.id] ||
              _vm.loading,"icon":""},on:{"click":function($event){return _vm.deleteReprocesoLocal(reproceso)}}},[_c('v-icon',{staticClass:"mx-0",attrs:{"left":"","size":"20"}},[_vm._v("$fi-trash-2")])],1):_vm._e()],1)]})],2),_c('v-expansion-panel-content',[_vm._l((reproceso.reprocesos),function(r){return _c('v-row',{key:r.id,staticClass:"px-2 zones-description"},[_c('v-col',{attrs:{"cols":"2"}},[_c('span',{staticClass:"zona-name"},[_vm._v(" "+_vm._s(_vm.$t(`Zonas.${r.zona.replace("_", " ")}`))+" ")])]),_c('v-col',{staticClass:"ellipse-description zona-articulos",attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(_vm.getArticulosFromReprocesos(r))+" ")]),_c('v-col',{staticClass:"pl-5"},[_c('span',{staticClass:"zona-precio"},[_vm._v(" "+_vm._s(_vm.correct2Decimals(r.total))+_vm._s(_vm.currencySymbol)+" ")])])],1)}),(
          reproceso.manipulados &&
          reproceso.manipulados.articulos &&
          reproceso.manipulados.articulos != 0
        )?_vm._l((reproceso.manipulados.articulos),function(r){return _c('v-row',{key:r.id,staticClass:"px-2 zones-description"},[_c('v-col',{attrs:{"cols":"2"}},[_c('span',{staticClass:"zona-name"},[_vm._v(" "+_vm._s(_vm.$t(`Tecnicas.MANIPULADOS`))+" ")])]),_c('v-col',{staticClass:"ellipse-description zona-articulos",attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(_vm.getArticulosFromReprocesos(r))+" ")]),_c('v-col',{staticClass:"pl-5"},[_c('span',{staticClass:"zona-precio"},[_vm._v(" "+_vm._s(_vm.correct2Decimals(r.total))+_vm._s(_vm.currencySymbol)+" ")])])],1)}):_vm._e(),(
          reproceso.etiquetas && reproceso.etiquetas.length != 0
        )?_vm._l((reproceso.etiquetas),function(r){return _c('v-row',{key:r.id,staticClass:"px-2 zones-description"},[_c('v-col',{attrs:{"cols":"2"}},[_c('span',{staticClass:"zona-name"},[_vm._v(" "+_vm._s(_vm.$t(`configuracion.ReprocesosGenerales.Etiquetas`))+" ")])]),_c('v-col',{staticClass:"ellipse-description zona-articulos",attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(_vm.getArticulosFromReprocesos(r))+" ")]),_c('v-col',{staticClass:"pl-5"},[_c('span',{staticClass:"zona-precio"},[_vm._v(" "+_vm._s(_vm.correct2Decimals(r.total))+_vm._s(_vm.currencySymbol)+" ")])])],1)}):_vm._e(),_c('div',{staticClass:"mt-8 actions-btn"},[_c('v-btn',{staticClass:"mr-3",attrs:{"loading":_vm.loadingPrint[reproceso.id] || _vm.loading,"disable":_vm.loadingPrint[reproceso.id] ||
            _vm.loadingDelete[reproceso.id] ||
            _vm.loading,"outlined":""},on:{"click":function($event){return _vm.printReprocesoLocal(reproceso)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"left":"","size":"20"}},[_vm._v(" mdi-arrow-down-bold-circle ")]),_c('span',[_vm._v(_vm._s(_vm.$t("general.Presupuesto")))])],1),(!_vm.disableDelete)?_c('v-btn',{attrs:{"loading":_vm.loadingDelete[reproceso.id] || _vm.loading,"disable":_vm.loadingPrint[reproceso.id] ||
            _vm.loadingDelete[reproceso.id] ||
            _vm.loading,"dark":""},on:{"click":function($event){return _vm.deleteReprocesoLocal(reproceso)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"left":"","size":"20"}},[_vm._v("$fi-trash-2")]),_c('span',[_vm._v(_vm._s(_vm.$t("configuracion.ReprocesosGenerales.Eliminar")))])],1):_vm._e()],1)],2)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }