import { render, staticRenderFns } from "./Zonas.vue?vue&type=template&id=18772855&scoped=true"
import script from "./Zonas.vue?vue&type=script&lang=js"
export * from "./Zonas.vue?vue&type=script&lang=js"
import style0 from "./Zonas.vue?vue&type=style&index=0&id=18772855&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18772855",
  null
  
)

export default component.exports