import axios from "axios";

// Función encargada de calcular el coste por cantidad exacta. El elemento payload debe conteer el campo listReprocesos
export const CalcularPrecioCantidadesExactas = ({ payload, divisa, isProveedor, language, key }) => {
    return axios.post(`${process.env.VUE_APP_API_REPROCESOS}/Reprocesos/CalcularCantidadesExactas`, payload, {
        params: {
            divisa,
            isProveedor,
            language
        },
        headers: {
            "Content-Type": "text/json",
            ReprocesosKey: key
        },
    })
};


// Función para carlcular una técnica cualquiera
export const CalcularTecnica = ({ payload, key }) => {
    return axios
        .get(`${process.env.VUE_APP_API_REPROCESOS}/Reprocesos/CalcularPrecios`, {
            params: payload,
            headers: {
                ReprocesosKey: key
            }
        });
};

// Función para recuperar toda la información de un modelo
export const FetchModelo = ({ modelCode, reprocesosKey, language, isYouReprocesos }) => {
    if (!modelCode || !reprocesosKey) return Promise.reject(new Error(`Missing parameters in FetchModelo function`));

    if (!language) language = "es-ES";

    let headers = {};

    headers = {
        ...headers,
        ReprocesosKey: reprocesosKey
    }

    return axios
        .get(`${process.env.VUE_APP_API_REPROCESOS}/Info/Modelo/${modelCode}`, {
            params: {
                modelCode,
                isFromYouReprocesos: isYouReprocesos,
                language
            },
            headers
        });
}

// Función para recuperar el catalogo completo de Azure
export const FetchCatalog = () => {
    const blob = process.env.VUE_APP_BLOB_URL;
    return axios
        .get(`${process.env.VUE_APP_STATIC_IMG_URL}/${blob}/Catalog/catalog_info_3d.json`)
}

// Función para recuperar el fondo de los distintos colores
export const FetchFondoColor = () => {
    return axios.get(`${process.env.VUE_APP_API_REPROCESOS}/Info/Colors`)
}

// Función para obtener las manipulaciones del modelo actual
export const FetchManipulados = ({ modelCode, language }) => {
    if (!modelCode) return Promise.reject(new Error(`Missing parameters in FetchManipulados function`));

    if (!language) language = "es-ES";

    return axios.get(`${process.env.VUE_APP_API_REPROCESOS}/Info/Modelo/${modelCode}/Manipulados`, {
        params: {
            language
        }
    })
}

// Función para obtener los tamaños para sublimación dado un modelo
export const FetchSublimacionSizes = ({ modelCode }) => {
    if (!modelCode) return Promise.reject(new Error(`Missing parameters in FetchSublimacionSizes function`));

    return axios.get(`${process.env.VUE_APP_API_REPROCESOS}/Info/Sublimacion/Size`,
        {
            params: {
                modelCode
            }
        })
}

// Función para obtener los tamaños para números DTF
export const FetchDtfNumerosSizes = () => {
    return axios.get(`${process.env.VUE_APP_API_REPROCESOS}/Info/DtfNumeros/Size`)
}

// Función para obtener el tamaño para numeros transfer
export const FetchTransferNumerosSizes = () => {
    return axios
        .get(
            `${process.env.VUE_APP_API_REPROCESOS}/Info/TransferNumeros/Size`
        );
}

// Función para obtener los tamaños de digital
export const FetchSizeDigitalOptions = ({ isTextil, modelCode, reprocesosKey, posicion }) => {

    if (isTextil) {
        return FetchSizeDigitalTextilOptions({ modelCode, reprocesosKey, posicion });
    }

    return axios
        .get(
            `${process.env.VUE_APP_API_REPROCESOS}/Reprocesos/Tamanos/Digital?modelCode=${modelCode}&posicion=${posicion}`,
            {
                headers: {
                    ReprocesosKey: reprocesosKey
                },
            }
        )
}

export const FetchSizeDigitalTextilOptions = ({ modelCode, reprocesosKey, posicion }) => {
    return axios
        .get(
            `${process.env.VUE_APP_API_REPROCESOS}/Info/DigitalTextil/Size?modelCode=${modelCode}&posicion=${posicion}`,
            {
                headers: {
                    ReprocesosKey: reprocesosKey
                },
            }
        )
}
