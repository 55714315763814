<template>
  <div class="container-3d">
    <v-alert
    class="save-alert"
          :value="savingDesign || completeSaveDesign"
          dense
          border="left"
          outlined
          text
          :type="savingDesign ? 'info' : 'success'"
          transition="scale-transition"
        >
          {{ !savingDesign ? $t("general.completeSaved") : $t('general.processSaving') }}
        </v-alert>
    <div
      class="d-flex h-100 justify-center align-center flex-column img-preview"
    >
    <!-- {{actualListDesign}} -->
      <v-slide-x-transition v-if="!show2dCanvas">
        
        <v-img
          v-show="!actualImgSelected && !show2dCanvas && !activeCanvas3d && !hasModel3D"
          :key="zona?.id + color?.code"
          :src="imgUrl"
          :lazy-src="imgUrl"
          contain
          max-height="460px"
        >
          <template #placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="primary lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-slide-x-transition>
      <canvas2d
        :id="zona?.id"
        :zona="zona"
        :img="actualImgSelected"
        :active="!hasModel3D && !!actualImgSelected"
        @save-design="saveActualDesign"
        @show-canvas="activeCanvas2d = true"
        @close-canvas="activeCanvas2d = false"
      ></canvas2d>
      <canvas3d 
        :id="zona?.id"
        :zona="zona"
        :img="actualImgSelected"
        :active="hasModel3D && activeCanvas3d"
        @save-design="saveActualDesign"
        @show-canvas="activeCanvas3d = true"
        @close-canvas="activeCanvas3d = false"
      ></canvas3d>
      <!-- <v-btn class="mt-3" @click="load3D">Active 3d</v-btn> -->
    </div>
    <v-container class="button-group" fluid>
      <v-row>
        <v-col class="w-100 pa-0">
          <cotizador-btn disabled content-class="w-100">
            <v-icon class="mr-3">$fi-sliders</v-icon>
            {{ $t("draftLiteral.draftTransforBtn") }}
          </cotizador-btn>
        </v-col>
        <v-col class="w-100 pa-0">
          <cotizador-btn
            :disabled="!hasModel3D"
            content-class="w-100"
          >
            <v-icon size="16" class="mr-3">$fi-position-1</v-icon>
            {{ $t("draftLiteral.draftPositionBtn") }}
          </cotizador-btn>
        </v-col>
        <v-col v-if="!isYouReprocesos && activeBorradores" class="w-100 pa-0">
          <cotizador-btn disabled content-class="w-100">
            <v-icon size="16" class="mr-3">$fi-upload</v-icon>
            {{ $t("draftLiteral.draftSavedDesignsBtn") }}
          </cotizador-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="w-100 pa-0">
          <cotizador-btn
            :on="{
              click: () => {
                showDialogUpdateFile = true;
              }
            }"
            content-class="w-100"
            dark
          >
            {{
              !activeCanvas2d
                ? $t("draftLiteral.loadDraftAlternative")
                : $t("presupuesto.editCanvasTitle")
            }}
          </cotizador-btn>
          <update-file-dialog
            :dialog.sync="showDialogUpdateFile"
            :zona="zona"
            without-activator
            @update-image="storeActualImg"
          />
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import { noImgUrl, checkImageValid } from "@/utils/imageUtils";
import { setBackgroundToSvg } from "@/utils/fabricUtils";
import canvas2d from "@/components/cotizadorClientes/canvas2d.vue";
import canvas3d from "@/components/cotizadorClientes/canvas3d.vue";
import updateFileDialog from "@/components/modales/updateFileDialog.vue";

import CotizadorBtn from "@/components/basic/CotizadorBtn.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    CotizadorBtn,
    canvas2d,
    updateFileDialog,
    canvas3d
  },
  props: {
    color: {
      type: Object,
      validator: function (value) {
        return value?.code;
      },
      default: null
      // required: true
    },
    zona: {
      type: Object,
      validator: function (value) {
        return value?.Posicion && value?.Zona;
      },
      default: null
      // required: true
    },
    active: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      // Genérico para ambos tipos de canvas
      actualImgSelected: null, // Imagen seleccionada
      showDialogUpdateFile: false, // Dialogo para mostrar el modal para subir una imagen

      // Variable usables para el canvas 2d
      activeCanvas2d: false, // Booleano para mostrar el canvas 2d

      // Variables usables para el canvas 3d
      activeCanvas3d: false, // Booleano para mostrar el canvas 3d

      // Dos booleanos extras para mostrar el mensaje de guardado
      savingDesign: false, // Indica que hemos empezado a guardar el diseño
      completeSaveDesign: false, // Indica que acabamos de completar de guardar el diseño
    };
  },
  computed: {
    ...mapGetters("modelo", [
      "modelPrice",
      "newComputedImgUrl",
      "hasModel3D",
      "modelCode",
      "modelSlug"
    ]),
    ...mapGetters("config", ["isYouReprocesos", "colorSelected", "activeBorradores"]),
    ...mapGetters("carrito", ["actualListDesign", 
      "loadingDesign"]),
    imgUrl() {
      return this.newComputedImgUrl(this.zona, this.color?.code) ?? noImgUrl;
    },
    imgCustom: function () {
      let design = this.actualListDesign.find(
        d => d.id == this.zona.id && d.posicion == this.zona.Posicion
      );
      if (design && design.svg) return design.svg;
      return null;
    },
    // Diseño para la zona actual
    actualDesign() {
      let design = this.searchFromDesignList();

      return design;
    },
    show2dCanvas() {
      return this.activeCanvas2d && this.actualDesign?.mode == "2d";
    }
  },
  watch: {
    active(val) {
      if (val) {
        this.load3DWithChecks();
      }
    },
    modelCode(newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        this.load3DWithChecks();
      }
    },
    loadingDesign(newVal, oldVal) {
      if (newVal == oldVal && newVal == this.savingDesign) {
        return;
      }

      if (newVal != this.savingDesign) {
         this.savingDesign = newVal;
        if (!newVal && oldVal) {
          this.completeSaveDesign = true;

            setTimeout(async () => {
              // Hacemos el emit para que se guarde el diséño
              this.completeSaveDesign = false
            }, 2500);
        }
      }
    }
  },
  methods: {
    ...mapActions("carrito", ["saveZoneCustomDesign"]),
    storeActualImg(img) {
      this.actualImgSelected = img;
    },
    /**
     * Guarda el diseño actual
     * @param {Object} zoneToSave Parámetro opcional. Si se proporciona, se guardará para la zona indicada
     *
     * El tema del parámetro opcional, es porque al ser llamado desde el watcher de zona, se guarda con el id de destino, no el real
     */
    saveActualDesign({ zoneToSave, canvas2dInfo }) {
    console.log("SAVE ACTUAL DESIGN 🚀 ~ file: design3DModal.vue:257 ~ saveActualDesign ~ zoneToSave:", zoneToSave)
    console.log("SAVE ACTUAL DESIG 🚀 ~ file: design3DModal.vue:257 ~ saveActualDesign ~ canvas2dInfo:", canvas2dInfo)

      if (!this.actualImgSelected) {
        throw new Error(
          "No se ha seleccionado ninguna imagen para poder guardar el diseño"
        );
      }

      if (
        !this.actualImgSelected ||
        (this.actualImgSelected.id == 0 &&
          !this.actualImgSelected.observaciones)
      ) {
        console.warn(
          "No se ha seleccionado ninguna imagen para guardar, ni ninguna observación",
          this.actualImgSelected
        );
        return;
      }

      let informationActualZone = {
        id: zoneToSave?.id ?? this.zona.Id,
        tecnica: this.tecnica,
        zona: zoneToSave?.Zona ?? this.zona.Zona,
        posicion: zoneToSave?.Posicion ?? this.zona.Posicion,
        design:
          this.actualImgSelected.id > 0 ? this.actualImgSelected.base64 : null,
        originalFile: this.actualImgSelected.originalFile,
        observaciones: this.actualImgSelected.observaciones,
        idImg: this.actualImgSelected.id,
        previewRule: zoneToSave?.PreviewRule ?? this.zona.PreviewRule
      };

      if (this.activeCanvas2d) {
        informationActualZone.mode = "2d";


        if (canvas2dInfo) {
          informationActualZone.svg = canvas2dInfo?.svg;
          informationActualZone.canvasJson = canvas2dInfo?.canvasJson;
          informationActualZone.baseBackgroundImg =
            informationActualZone.canvasJson.backgroundImage.src;

          let imageWithoutFondo = this.newComputedImgUrl(
            zoneToSave ?? this.zona,
            this.colorSelected,
            true
          );

          if (
            checkImageValid(imageWithoutFondo) &&
            imageWithoutFondo != informationActualZone.baseBackgroundImg
          ) {
            console.log(
              "🚀 ~ file ~ imageWithoutFondo",
              imageWithoutFondo,
              informationActualZone.baseBackgroundImg
            );
            informationActualZone.svg =
              setBackgroundToSvg(
                informationActualZone.svg,
                imageWithoutFondo
              ) ?? informationActualZone.svg;

              console.log("🚀 ~ file: design3DModal.vue:325 ~ saveActualDesign ~ informationActualZone.svg:", informationActualZone.svg)
          }
        }

      }

      if(this.activeCanvas3d) {
        informationActualZone.mode = "3d";
      }

      this.saveZoneCustomDesign(informationActualZone);
    },
    searchFromDesignListWithImage() {
      return this.searchFromDesignList({ withImage: true });
    },
    searchFromDesignList({ withImage = false } = {}) {
      // Buscamos si alguno de los diseños coincide en Id
      let design = this.actualListDesign.find(
        x =>
          x.id == this.zona.id &&
          this.zona.Posicion == x.posicion &&
          x.mode == "2d" &&
          (!withImage || this.actualImgSelected?.id == x.idImg)
      );

      return design;
    }
  }
};
</script>

<style lang="scss">
.container-3d {
  max-width: 100%;
  width: 100%;
  height: calc(100vh - 157px);
  max-height: 900px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .save-alert {
    position: absolute;
    top: 15px;
    padding: 10px 25px;
  }

  .img-preview {
    .custom-svg {
      max-width: 460px;
    }
  }

  .button-group {
    // min-height: 96px;
    padding-bottom: 0px;
    .v-btn {
      border-radius: 0px !important;
    }
  }
}
</style>
