export const getTextColor = (color) => {
  let hexColor;

  if (!color) return color;

  if (color.startsWith("rgb(", true)) {
    // Extraer los componentes RGB
    const rgbComponents = color.match(/\d+/g);
    const r1 = parseInt(rgbComponents[0]);
    const g1 = parseInt(rgbComponents[1]);
    const b1 = parseInt(rgbComponents[2]);

    // Calcular la luminancia
    const luminanceRgb = (r1 * 0.299 + g1 * 0.587 + b1 * 0.114) / 255;

    // Devolver el color del texto en función de la luminancia
    return luminanceRgb > 0.5 ? "#000" : "#fff";
  } else if (color.startsWith("rgba(", true)) {
    // Extraer los componentes RGBA
    const rgbaComponents = color.match(/\d+(\.\d+)?/g);
    const r1 = parseInt(rgbaComponents[0]);
    const g1 = parseInt(rgbaComponents[1]);
    const b1 = parseInt(rgbaComponents[2]);
    const alpha = parseFloat(rgbaComponents[3]);

    // Calcular la luminancia considerando la transparencia
    const luminanceRgba = (r1 * 0.299 + g1 * 0.587 + b1 * 0.114) / 255 * alpha + (1 - alpha);

    // Devolver el color del texto en función de la luminancia
    return luminanceRgba > 0.5 ? "#000" : "#fff";
  } else {
    // Convertir el color a hexadecimal
    hexColor = color.replace("#", "");
    const r = parseInt(hexColor.substring(0, 2), 16) / 255;
    const g = parseInt(hexColor.substring(2, 4), 16) / 255;
    const b = parseInt(hexColor.substring(4, 6), 16) / 255;

    // Calcular la luminancia
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    // Devolver el color del texto en función de la luminancia
    return luminance > 0.5 ? "black" : "white";
  }
};

export const lighterColor = (hexColor, percentage) => {
  hexColor = hexColor.replace("#", "");
  const num = parseInt(hexColor, 16);
  let r = (num >> 16) + percentage;
  let g = ((num >> 8) & 0x00FF) + percentage;
  let b = (num & 0x0000FF) + percentage;

  // Asegúrate de que los valores estén dentro del rango 0-255
  r = Math.max(0, Math.min(255, r));
  g = Math.max(0, Math.min(255, g));
  b = Math.max(0, Math.min(255, b));

  const newColor = (r << 16) | (g << 8) | b;
  return "#" + newColor.toString(16).padStart(6, "0");
}

export const hexToRGBA = (hexColor, opacity = 1) => {
  hexColor = hexColor.replace("#", "");
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}