<template>
  <div class="generic-calculadora-parameters py-3 px-5">
    <v-fade-transition>
      <v-overlay v-if="cargandoTabZona" absolute>
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-overlay>
    </v-fade-transition>
    <!-- 
      Fila encargada de técnicas, grupos de técnicas y el selector de colores
    -->

    <v-row>
      <!-- Selector principal de técnica -->
      <v-col class="pl-8">
        <span class="litle-head-title">
          {{ $t("general.Seleccione una técnica para marcar") }}
        </span>
        <v-select
          v-model="tecnicaSeleccionada"
          :disabled="loadingCarrito"
          :items="groupedTecnicasArray"
          item-text="label"
          item-value="valueTecnica"
          label=""
          hide-details="true"
          class="mb-3 pt-0"
        ></v-select>
      </v-col>

      
      <!-- Selector de colores -->
      <v-col
        v-if="infoTecnicaSeleccionada.colores"
        :md="hasGroupedTecnicaSelected ? 4 : 4"
      >
        <span class="litle-head-title">
          {{ $t("configuracion.Serigrafia.Colores") }}
        </span>
        <v-select
          v-model="params.colores"
          :items="coloresDisponibles"
          color="primary darken-2"
          prepend-inner-icon="mdi-format-color-fill"
          label=""
          hide-details="true"
          class="mb-3 pt-0"
        ></v-select>
      </v-col>
    </v-row>

    <v-row v-if="hasGroupedTecnicaSelected">
      <!-- Selector de técnica secundaría, si tenemos un grupo de técnicas seleccionado (Numeros/TransferStm...) -->
      <v-col  cols="12" md="12">
        <!-- <span class="litle-head-title">varicion</span> -->
        <v-select
          v-model="specificTecnicaSeleccionadOfGroup"
          :disabled="loadingCarrito"
          :items="groupedTecnicas[tecnicaSeleccionada]"
          item-text="label"
          item-value="valueTecnica"
          label=""
          hide-details="true"
          class="mb-3 pt-0"
        ></v-select>
      </v-col>
    </v-row>
    <!-- Selector de altura mediante una selección de opciones -->
    <v-row v-if="infoTecnicaSeleccionada.maxHeightSelect">
      <v-col>
        <span class="litle-head-title">{{ $t($t('configuracion.Transfer.Alto')) }}</span>
        <v-select
          v-model="params.maxHeightSelect"
          :items="maxHeightSelectOption"
          color="primary darken-2"
          prepend-inner-icon="mdi-tape-measure"
          label=""
          hide-details="true"
          class="mb-3 pt-0"
        ></v-select>
      </v-col>
    </v-row>

    <!-- Selector de tipo de color -->
    <v-row v-if="infoTecnicaSeleccionada.typeColor">
      <v-col cols="12">
        <!-- <span class="litle-head-title">typeColor</span> -->
        <v-select
          v-model="params.typeColor"
          hide-details="true"
          prepend-inner-icon="mdi-format-color-fill"
          color="primary darken-2"
          :items="filteredOptions"
          item-text="Label"
          item-value="Type"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <!-- selector de tamano -->
      <v-col v-if="infoTecnicaSeleccionada.tamano && !seEnvianMetrosLineales">
        <span class="litle-head-title">
          {{ $t("configuracion.Serigrafia.Tamaño del estampado") }}
        </span>
        <v-select
          v-model="params.tamano"
          :items="tamanosArray"
          label=""
          outlined
          color="primary darken-2"
          prepend-inner-icon="mdi-tape-measure"
          hide-details="true"
          class="pt-0"
          @change="actualGetMedidasByTamano"
        ></v-select>
      </v-col>

      <!-- input de número de puntadas -->
      <v-col v-if="infoTecnicaSeleccionada.puntadas">
        <span class="litle-head-title">
          {{ $t("configuracion.Bordado.Introduce el número de puntadas") }}
        </span>
        <v-text-field
          v-model="params.puntadas"
          :suffix="$t('configuracion.Bordado.puntadas')"
          label=""
          hide-details="true"
          type="number"
          prepend-inner-icon="mdi-basket-fill"
          color="primary darken-2"
        ></v-text-field>
      </v-col>

      <!-- selector de tamano de impresion -->
      <v-col v-if="infoTecnicaSeleccionada.idTamImpresion">
        <span class="litle-head-title">
          {{ $t("configuracion.ImpresionDirecta.Tamaño del estampado") }}
        </span>
        <v-select
          v-model="params.idTamImpresion"
          :items="tamanosImpresionDirecta"
          label=""
          color="primary darken-2"
          prepend-inner-icon="mdi-tape-measure"
        ></v-select>
      </v-col>

      <!-- Selector de tamano estampados para números dtf -->
      <v-col v-if="infoTecnicaSeleccionada.tamanoEstampadoNumerosDTF" cols="12">
        <span class="litle-head-title">
          {{ $t("general.Tamaño") }}
        </span>
        <v-select
          v-model="params.tamanoEstampadoNumerosDTF"
          :items="filteredOptions"
          label=""
          item-text="label"
          item-value="value"
          color="primary darken-2"
          prepend-inner-icon="mdi-tape-measure"
        ></v-select>
      </v-col>

      <!-- check para indicar si impremimos a doble número o no -->
      <v-col v-if="infoTecnicaSeleccionada.dobleNumero">
        <v-checkbox
          v-model="params.dobleNumero"
          :label="$t('configuracion.Numeros.NumDobles')"
          color="primary darken-2 white--text"
          class="mt-1 pt-1"
          hide-details="true"
        ></v-checkbox>
      </v-col>

      <!-- Input para el número de metros lineales -->
      <v-col
        v-if="infoTecnicaSeleccionada.MetrosLineales && seEnvianMetrosLineales"
      >
        <span class="litle-head-title">
          {{ $t("configuracion.DTF.Metros lineales") }}
        </span>
        <v-text-field
          v-model="params.MetrosLineales"
          label=""
          hide-details="true"
          type="number"
          color="primary darken-2"
          min="1"
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- Selector de caras -->
    <v-row v-if="infoTecnicaSeleccionada.caras">
      <v-col>
        <span class="litle-head-title">
          {{ $t("configuracion.Sublimacion.Nº de caras") }}
        </span>
        <v-select
          v-model="params.numCaras"
          hide-details="true"
          prepend-inner-icon="mdi-asterisk"
          color="primary darken-2"
          :items="numCarasAvaliblesArray"
        ></v-select>
      </v-col>
    </v-row>

    <!-- Selector de gajos -->
    <v-row v-if="infoTecnicaSeleccionada.gajos">
      <v-col>
        <span class="litle-head-title">
          {{ $t("configuracion.CalculoPrecios.Gajos") }}
        </span>
        <v-select
          v-model="params.gajos"
          :items="numGajosDisponibles"
          label=""
          color="primary darken-2"
          prepend-inner-icon="mdi-format-color-fill"
          hide-details
          class="mt-0 py-0"
        ></v-select>
      </v-col>
    </v-row>

    <!-- Selector de tipo de termograbado -->
    <v-row v-if="infoTecnicaSeleccionada.typeTermograbado">
      <v-col>
        <span class="litle-head-title">
          {{ $t("configuracion.CalculoPrecios.tipoTermograbado") }}
        </span>
        <v-select
          v-model="params.typeTermograbado"
          :items="avalibleTermograbado"
          label=""
          color="primary darken-2"
          prepend-inner-icon="mdi-cog"
          hide-details="true"
          class="mt-0 py-0"
        ></v-select>
      </v-col>
    </v-row>

    <!-- Selector cm2 para digital -->
    <v-row v-if="infoTecnicaSeleccionada.tamanocm2Digital">
      <v-col>
        <span class="litle-head-title">
          {{ $t("configuracion.CalculoPrecios.tamaño") }}
        </span>
        <v-select
          v-model="params.digitalTam"
          :items="actualPositionSizeDigitalOptions"
          item-text="label"
          item-value="code"
          label=""
          color="primary darken-2"
          prepend-inner-icon="mdi-crop-free"
          hide-details="true"
          class="mt-0 py-0"
        ></v-select>
      </v-col>
    </v-row>

    <!-- Selector cm2 para Doming -->
    <v-row v-if="infoTecnicaSeleccionada.tamanoCm2Doming">
      <v-col>
        <span class="litle-head-title">
          {{ $t("configuracion.CalculoPrecios.tamaño") }}
        </span>
        <v-select
          v-model="params.domingTamCode"
          :items="avalibleSizeDoming"
          item-text="label"
          item-value="code"
          label=""
          color="primary darken-2"
          prepend-inner-icon="mdi-crop-free"
          hide-details="true"
          class="mt-0 py-0"
        ></v-select>
      </v-col>
    </v-row>

    <!-- Input para aquellas técnicas que no tienen valores fijos en cm2 -->
    <v-row v-if="infoTecnicaSeleccionada.tamanoCm2">
      <v-col>
        <span class="litle-head-title">
          {{ $t("configuracion.CalculoPrecios.tamaño") }}
        </span>
        <v-text-field
          v-model="params.tamanoCm2Area"
          :disabled="isSizeTamanoCm2Forced"
          type="number"
          label=""
          min="1"
          :max="maxSizeForTamanoCm2?.Area ?? 1"
          color="primary darken-2"
          prepend-inner-icon="mdi-tshirt-crew"
          hide-details
          class="mt-0 py-0"
          @input="updateSizeCm2($event, 'Area')"
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- Alerta enc aso de que exista para la técnia/modelo actual -->
    <v-row v-if="alertInfo?.length">
      <v-col cols="12">
        <v-card v-for="alert in alertInfo" :key="alert.text">
          <v-alert
            :type="alert.type ?? warning"
            :color="alert.color ?? ''"
            elevation="8"
            text
            dense
            class="mb-3"
          >
            {{ alert.text }}
          </v-alert>
        </v-card>
      </v-col>
    </v-row>

    <!-- Activar ajustes avanzados -->
    <v-row class="pt-3" :class="{'pb-4': !showOpcionesAvanzadas}">
      <v-col class="d-flex justify-start align-center">
        <a
          href="javascript:;"
          @click="showOpcionesAvanzadas = !showOpcionesAvanzadas"
        >
          <v-icon>
            <template v-if="!showOpcionesAvanzadas">mdi-chevron-right</template>
            <template v-else>mdi-chevron-down</template>
          </v-icon>

          {{ $t("general.advancedOptions") }}
        </a>
      </v-col>
      <v-col v-if="!showOpcionesAvanzadas" class="d-flex justify-end align-center">
        <a href="javascript:;" @click="removeZone">
          {{$t("general.Eliminar zona")}}
          <v-icon>mdi-close</v-icon>
        </a>
      </v-col>
    </v-row>
    <v-expand-transition>
     <v-row class="mt-5 ">
       <v-container v-show="showOpcionesAvanzadas" class="py-0 px-2">
        <v-row
          v-if="
            (infoTecnicaSeleccionada.maxWidth ||
              infoTecnicaSeleccionada.maxHeight) &&
            !infoTecnicaSeleccionada.noShowHeightAndWidth
          "
        >
          <v-col
            v-if="
              infoTecnicaSeleccionada.maxWidth &&
              !infoTecnicaSeleccionada.noShowHeightAndWidth
            "
            cols="12"
            md="6"
          >
            <div class="custom-input">
              <div class="prepend-text">
                {{ $t("configuracion.Serigrafia.Ancho") }}
              </div>
              <v-text-field
                v-model="params.maxWidth"
                dense
                hide-spin-buttons
                type="number"
                class="input-number"
                suffix="cm"
                :hint="$t('configuracion.Serigrafia.Max: 297mm')"
                outlined
                @change="updateTamanoByHeightAndWidth"
              />
            </div>
          </v-col>
          <v-col
            v-if="
              infoTecnicaSeleccionada.maxHeight &&
              !infoTecnicaSeleccionada.noShowHeightAndWidth
            "
            cols="12"
            md="6"
          >
            <div class="custom-input">
              <div class="prepend-text">
                {{ $t("configuracion.Serigrafia.Alto") }}
              </div>
              <v-text-field
                v-model="params.maxHeight"
                dense
                hide-spin-buttons
                type="number"
                class="input-number"
                suffix="cm"
                :hint="$t('configuracion.Serigrafia.Max: 420mm')"
                outlined
                @change="updateTamanoByHeightAndWidth"
              />
            </div>
          </v-col>
        </v-row>

        <!-- input exclusivos de las técnicas con tamanoCm2 para poder modificar width and height -->
        <v-row v-if="infoTecnicaSeleccionada.tamanoCm2">
          <v-col>
            <div class="custom-input">
              <div class="prepend-text">
                {{ $t("configuracion.Serigrafia.Ancho") }}
              </div>
              <v-text-field
                v-model="widthTamanoCm2"
                :disabled="isSizeTamanoCm2Forced"
                dense
                hide-spin-buttons
                type="number"
                class="input-number"
                suffix="mm"
                min="1"
                :hint="$t('configuracion.Serigrafia.Max: 297mm')"
                :max="maxSizeForTamanoCm2?.Ancho ?? 1"
                outlined
                @change="updateSizeCm2($event, 'Ancho')"
              />
            </div>
          </v-col>
          <v-col>
            <div class="custom-input">
              <div class="prepend-text">
                {{ $t("configuracion.Serigrafia.Alto") }}
              </div>
              <v-text-field
                v-model="heightTamanoCm2"
                :disabled="isSizeTamanoCm2Forced"
                dense
                hide-spin-buttons
                type="number"
                class="input-number"
                suffix="mm"
                min="1"
                :hint="$t('configuracion.Serigrafia.Max: 420mm')"
                :max="maxSizeForTamanoCm2?.Alto"
                outlined
                @change="updateSizeCm2($event, 'Alto')"
              />
            </div>
          </v-col>
        </v-row>

        <v-row>
          <!-- Check para planchado  -->
          <v-col v-if="infoTecnicaSeleccionada.hasPlanchado" cols="6">
            <v-checkbox
              v-model="params.hasPlanchado"
              :label="$t('configuracion.Serigrafia.PreguntaPlanchado')"
              color="primary darken-2 white--text"
              class="mt-1 pt-1"
              hide-details="true"
            ></v-checkbox>
          </v-col>

          <!-- Check para especificar tinta negra -->
          <v-col v-if="infoTecnicaSeleccionada.tintaNegra" cols="6">
            <span v-if="params.colores == 1" class="d-flex">
              <v-checkbox
                v-model="params.tintaNegra"
                :label="$t('configuracion.Serigrafia.Usar tinta negra')"
                color="primary darken-2 white--text"
                class="mt-1 pt-1"
                hide-details="true"
              ></v-checkbox>

              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon class="mt-1">mdi-information</v-icon>
                  </v-btn>
                </template>
                <span class="litle-head-title">
                  {{
                    $t(
                      "configuracion.Serigrafia.Si alguno de los colores contiene el color negro"
                    )
                  }}
                </span>
              </v-tooltip>
            </span>
          </v-col>

          <!-- Check para cambiar indicar que vamos a indicar metros lineales -->
          <v-col v-if="infoTecnicaSeleccionada.MetrosLineales">
            <span v-if="params.colores == 1" class="d-flex">
              <v-checkbox
                v-model="seEnvianMetrosLineales"
                :label="$t('configuracion.DTF.Metros lineales')"
                color="primary darken-2 white--text"
                class="mt-1 pt-1"
                hide-details="true"
              ></v-checkbox>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon class="mt-1">mdi-information</v-icon>
                  </v-btn>
                </template>
                <span class="litle-head-title">
                  {{ $t($t("configuracion.DTF.Aviso metros")) }}
                </span>
              </v-tooltip>
            </span>
          </v-col>

          <!-- Check para indicar que se va aplicar un diseño especial para números -->
          <v-col v-if="infoTecnicaSeleccionada.disenoEspecial">
            <v-checkbox
              v-model="params.disenoEspecial"
              color="primary darken-2 white--text"
              hide-details="true"
              :label="$t(`configuracion.NumerosDtf.DisenoEspecial`)"
            ></v-checkbox>
          </v-col>

          <!-- Check para indicar si debemos mostrar el check para doble base en serigrafía -->
          <v-col v-if="hasDobleBaseSerigrafia">
            <v-checkbox
              v-model="params.dobleBaseSerigrafia"
              color="primary darken-2 white--text"
              class="warning-text"
              :error="!params.dobleBaseSerigrafia"
              hide-details="true"
              :label="$t('configuracion.Serigrafia.CheckBasePrendasOscuras')"
            ></v-checkbox>
          </v-col>

          <!-- Checkbox para indicar que se va a realizar una personalización nombre a nombre -->
          <v-col v-if="infoTecnicaSeleccionada.nombreANombre">
            <v-checkbox
              v-model="params.nombreANombre"
              color="primary darken-2 white--text"
              hide-details="true"
              :label="$t('general.NombreANombre')"
            ></v-checkbox>
          </v-col>
        </v-row>

        <!-- Selector de tipo de tinta -->
        <v-row v-if="infoTecnicaSeleccionada.idTipoTinta">
          <v-col>
            <span class="litle-head-title">
              {{ $t("configuracion.Tintas.Tipo de tinta") }}
            </span>
            <v-select
              v-model="params.idTipoTinta"
              :items="allTintas"
              label=""
              outlined
              color="primary darken-2"
              prepend-inner-icon="mdi-water-outline"
              class="mt-1"
              hide-details="true"
            ></v-select>
            <span class="litle-head-title">
              {{
                $t(
                  "configuracion.Tintas.Si la prenda es elástica (elastano) o el tejido es de canalé, se ha de aplicar tinta para elastano"
                )
              }}
            </span>
          </v-col>
        </v-row>

        <!-- Check obligatorio para mostrar la posibilidad de indicar que es una repetición -->
        <v-row>
          <v-col class="container-check-repeticion" cols="12" md="6">
            <check-repeticion
              v-model="params.esRepeticion"
              :id-zone="zona.id"
            ></check-repeticion>
          </v-col>

          <v-col>
            <div class="d-flex justify-end align-center h-100">
              <a href="javascript:;" @click="removeZone">
                {{ $t("general.Eliminar zona") }}
                <v-icon>mdi-close</v-icon>
              </a>
            </div>
          </v-col>
        </v-row>
      </v-container>
     </v-row>
    </v-expand-transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import configTecnicasUtil from "@/utils/tecnicasUtils.js";

import CheckRepeticion from "@/components/basic/CheckRepeticion.vue";

export default {
  components: {
    CheckRepeticion
  },
  props: {
    // Zona actual a la que vamos a calcularle el precio
    zona: {
      type: Object,
      required: true,
      validator: function (value) {
        return value?.Posicion && value?.Zona && value?.Tecnicas;
      }
    }
  },
  data() {
    return {
      tecnicaSeleccionada: null, // Técnica seleccionada
      specificTecnicaSeleccionadOfGroup: null, // Técnica seleccionada de un grupo de técnicas (en caso de que tecnicaSeleccionada sea un grupo)
      cargandoTabZona: false, // Loading para la zona
      showOpcionesAvanzadas: false, // Check para mostrar o no las opciones avanzadas
      lastAtrb: null, // Última configuración de parámetros para los que se ha calculado el precio
      seEnvianMetrosLineales: false, // Check para comprobar si se deben mandar metros lineales o no
      widthTamanoCm2: 0, // Variable auxiliar para indicar el ancho en caso de que se especifique un tamaño en cm2
      heightTamanoCm2: 0, // Variable auxiliar para indicar el alto en caso de que se especifique un tamaño en cm2
      debouncedCalculate: null, // Función debounce en el calculo del precio

      // Parametros del calcula que se enviarán en la petición
      params: {
        tamano: null,
        maxWidth: 0,
        maxHeight: 0,
        hasPlanchado: null,
        repeticion: null,
        tintaNegra: false,
        colores: 1,
        idTipoTinta: 5,
        esRepeticion: false,
        puntadas: 1,
        idTamImpresion: 4,
        MetrosLineales: 1,
        dobleNumero: false,
        tamanoEstampadoNumerosDTF: null,
        disenoEspecial: false,
        typeColor: null,
        maxHeightSelect: 100,
        numCaras: 1,
        gajos: 1,
        digitalTam: null,
        domingTamCode: null,
        nombreANombre: false,
        tamanoCm2Area: 0,
        typeTermograbado: null,

        // atributos sin inputs
        prendasBlanco: 0,
        prendasClaro: 0,
        prendasOscuro: 0
      }
    };
  },
  computed: {
    ...mapGetters("carrito", [
      "soloBlancoEnCarritoSublimacion",
      "loadingCarrito",
      "carrito",
      "medidasMaximas",
      "numReprocesosSerigrafiaOrTransfer",
      "hasPackUno",
      "totalUnidades",
      "quantityByColor"
    ]),
    ...mapGetters("modelo", [
      "isReprocesosStm",
      "tieneSublimacionTotal",
      "sublimacionStmInfo",
      "getFilteredTecnicasExtra",
      "transferStmTypeAvalible",
      "maxValidSize",
      "zonesFondoByZone",
      "modelInfoFromCatalog",
      "stmMaxColor",
      "modelPrice",
      "listColorFondo"
    ]),
    ...mapGetters("config", [
      "allSizeByTecnica",
      "getMedidasByTamano",
      "allTintas",
      "tamanosImpresionDirecta",
      "optionsNumbers",
      "sizeDigitalOptions"
    ]),

    // Array de técnicas disponibles con la configuración de parámetros que debemos hacer
    tecnicas() {
      let sublimacionInfo = null;
      let serigrafiaInfo = null;
      let transferInfo = null;
      let digitalInfo = null;

      if (this.isReprocesosStm) {
        sublimacionInfo = this.sublimacionStmInfo(this.zona.Posicion);
        serigrafiaInfo = this.getFilteredTecnicasExtra(
          this.zona.Posicion,
          "Serigrafia"
        );
        transferInfo = this.getFilteredTecnicasExtra(
          this.zona.Posicion,
          "Transfer"
        );

        digitalInfo = this.getFilteredTecnicasExtra(
          this.zona.Posicion,
          "Digital"
        );
      }

      // Recuperamos la información para el listado actual
      let listTecnicas = configTecnicasUtil.tecnicasListCreator(
        this.zona.Tecnicas,
        this.isReprocesosStm,
        {
          soloBlancoEnCarritoSublimacion: this.soloBlancoEnCarritoSublimacion,
          tieneSublimacionTotal: this.tieneSublimacionTotal,
          isSublimacionSombreros: sublimacionInfo?.Type == "Sombreros",
          isSublimacionTazas: sublimacionInfo?.Type == "Tazas",
          isLanyardSublimacion: sublimacionInfo?.NumCaras,
          useGajosSerigrafia: serigrafiaInfo?.Type == "Gajos",
          useGajosTransfer: transferInfo?.Type == "Gajos",
          isSerigrafiaSombreros: serigrafiaInfo?.Type == "Sombreros",
          tecnicasTransfer: transferInfo?.AvaliblesTransfer,
          useDigitalTextil: digitalInfo?.Type == "Textil",
          useCalzadoTransfer: transferInfo?.Type == "Calzado",
          useTransferTextil: transferInfo?.TypeProducto == "Textil",
        }
      );
          
        console.log("🚀 ~ file: GenericGlobalCalculadora.vue:747 ~ tecnicas ~ this.zona:", this.zona)
        console.log("🚀 ~ file: GenericGlobalCalculadora.vue:747 ~ tecnicas ~ transferInfo?.AvaliblesTransfer:", transferInfo?.AvaliblesTransfer)
      return listTecnicas.map(t => {
        return {
          ...t,
          label: this.$t("Tecnicas." + t.nombreTecnica)
        }
      })
    },

    // Tecnicas agrupadas por grupos, en caso de que no exista grupo, no se mostrará
    groupedTecnicas() {
      let grouped = {};

      this.tecnicas.forEach(t => {
        if (!t.groupTecnica) return;

        if (!grouped[t.groupTecnica]) grouped[t.groupTecnica] = [t];
        else grouped[t.groupTecnica].push(t);
      });

      console.log("🚀 ~ groupedTecnicas ~ grouped:", grouped);
      return grouped;
    },

    // Bool que indica si tenemos algun grupo seleccionado
    hasGroupedTecnicaSelected() {
      return !!this.groupedTecnicas[this.tecnicaSeleccionada];
    },

    // Listado de técnicas a mostrar
    groupedTecnicasArray() {
      let array = [];

      this.tecnicas.forEach(t => {
        if (!t.groupTecnica) array.push(t);
      });

      Object.keys(this.groupedTecnicas).forEach(t => {
        console.log("THIS", this.groupedTecnicas[t][0]);
        array.push({
          nombreTecnica: t,
          valueTecnica: t,
          label: this.$t("Tecnicas." + this.groupedTecnicas[t][0]?.groupTecnica)
        });
      });

      return array;
    },

    // Información completa de los parametros a usar para la técnica seleccionada
    infoTecnicaSeleccionada() {
      if (!this.tecnicaSeleccionada || !this.tecnicas?.length) return {};

      if (this.hasGroupedTecnicaSelected) {
        return (
          this.tecnicas.find(
            t => this.specificTecnicaSeleccionadOfGroup == t.valueTecnica
          ) ?? {}
        );
      }

      return (
        this.tecnicas.find(t => this.tecnicaSeleccionada == t.valueTecnica) ??
        {}
      );
    },

    // Opciones DTF números filtradas por numeros dobles
    filteredOptions() {
      return this.optionsNumbers(
        this.infoTecnicaSeleccionada,
        this.params.dobleNumero
      );
    },

    // Indicador de doble base en serigrafia
    hasDobleBaseSerigrafia() {
      return (
        this.infoTecnicaSeleccionada.dobleBaseSerigrafia &&
        this.getFilteredTecnicasExtra(this.zona.Posicion, "Serigrafia")
          ?.AplicarBase
      );
    },

    // Número de gajos disponibles para la técnica seleccionada
    numGajosDisponibles() {
      let gajos = [];

      let tecnica = this.infoTecnicaSeleccionada.valueTecnica.startsWith(
        "Serigrafia"
      )
        ? "Serigrafia"
        : this.infoTecnicaSeleccionada.valueTecnica.startsWith("Transfer")
          ? "Transfer"
          : this.infoTecnicaSeleccionada.valueTecnica.replace("Stm", "");

      let maxNumGajos = parseInt(
        this.getFilteredTecnicasExtra(this.zona.Posicion, tecnica)?.NumGajos ??
          0
      );

      for (let i = 1; i <= maxNumGajos; i++) {
        gajos.push(i);
      }
      return gajos;
    },

    // Tamaño máximo para impresión directa
    actualMaxValidSizeImpresionDirecta() {
      if (this.carrito.productos.length == 0) return null;
      return this.maxValidSize(
        this.tamanosImpresionDirecta,
        this.zona.Posicion
      );
    },

    // Tamano máximo para la técnica numeros dtf
    actualMaxValidSizeNumDtf() {
      if (!this.filteredOptions.length) return;

      let maxValue = null;
      this.filteredOptions.forEach(tam => {
        let isPrimerValor = !maxValue;

        let firstValueOrIsBigger =
          isPrimerValor ||
          (tam.Ancho >= maxValue.Ancho && tam.Alto >= maxValue.Alto);

        let numExcededSize = this.medidasMaximas(
          this.zona.Posicion,
          tam.Ancho,
          tam.Alto
        );

        if (firstValueOrIsBigger && numExcededSize.length == 0) maxValue = tam;
      });

      return maxValue;
    },

    // Altura máxima si lo ponemos en formato de select (para transferNumeros)
    maxHeightSelectOption() {
      if (!this.filteredOptions.length) return;

      return this.filteredOptions
        .filter(option => option.Type === this.params.typeColor)
        .map(option => option.Alto);
    },

    // Número máximo de colores en caso de tratarse de transferNumeros
    maxColorSelectNumerosOption() {
      if (!this.filteredOptions.length) return;

      const maxColor =
        this.filteredOptions.find(
          option => option.Type === this.params.typeColor
        )?.MaxColor ?? 0;

      return maxColor <= 1
        ? [1]
        : Array.from({ length: maxColor }, (_, i) => i + 1);
    },

    // Listado de valores posibiles para el selector de caras
    numCarasAvaliblesArray() {
      if (this.modelInfoFromCatalog.code == "CP9001")
        return [{ value: 1, text: 1 }];
      if (
        this.infoTecnicaSeleccionada.valueTecnica?.startsWith("SublimacionStm")
      ) {
        // Recuperamos el número de caras
        let numCarasSublimacion = this.getFilteredTecnicasExtra(
          this.zona.Posicion,
          "Sublimacion"
        )?.NumCaras;

        if (numCarasSublimacion == 0) {
          return [
            {
              text: "1",
              value: 1
            },
            {
              text: "2",
              value: 2
            }
          ];
        }

        if (this.infoTecnicaSeleccionada.specificCaras) {
          // Solo el número de caras especificado
          return [
            {
              text: `${numCarasSublimacion}`,
              value: Number(numCarasSublimacion)
            }
          ];
        }

        let result = [];
        let index = 0;
        while (index < numCarasSublimacion) {
          result.push({
            text: (index + 1).toString(),
            value: index + 1
          });
          index++;
        }

        return result;
      } else return [{ value: 2, text: 2 }];
    },

    // Calculo de tamaños disponibles para la técnica actual
    tamanos() {
      return this.allSizeByTecnica(
        this.tecnicaSeleccionada,
        this.zona.Posicion
      );
    },

    // Array de tamaño
    tamanosArray() {
      return Object.keys(this.tamanos);
    },

    // Computed para calcular el tamaño máximo válido para el conjunto de productos actualmente seleccionado
    actualMaxValidSize() {
      if (this.carrito.productos.length == 0 || !this.zona) return null;
      let customSizes = Object.keys(this.tamanos)
        .filter(s => this.tamanosArray.includes(s))
        .map(s => {
          return {
            ...this.tamanos[s],
            text: s
          };
        });
      return this.maxValidSize(customSizes, this.zona.Posicion);
    },

    // Computed para calcular el número máximo de colores
    maxColores() {
      // Tinta vinilica
      if (this.params.idTipoTinta == 6) {
        return 1;
      } else {
        return 8;
      }
    },

    // Listado de número de colores disponibles
    coloresDisponibles() {
      const tecnica = this.infoTecnicaSeleccionada?.valueTecnica ?? "";

      let coloresMaximos = this.maxColores;

      if (this.params.tintaNegra) {
        coloresMaximos = 1;
      } else if (tecnica === "TransferNumeros") {
        coloresMaximos = this.maxColorSelectNumerosOption;
      } else if (this.isReprocesosStm) {
        if (tecnica.startsWith("Serigrafia")) {
          coloresMaximos = parseInt(
            this.stmMaxColor(this.zona.Posicion, "Serigrafia") ?? 1
          );
        } else if (tecnica === "Tampografia") {
          coloresMaximos = parseInt(
            this.stmMaxColor(this.zona.Posicion, "Tampografia") ?? 1
          );
        } else if (tecnica.startsWith("Sublima")) {
          coloresMaximos = parseInt(
            this.stmMaxColor(this.zona.Posicion, "Sublimacion") ?? 1
          );
        } else if (tecnica.startsWith("Transfer")) {
          coloresMaximos = parseInt(
            this.stmMaxColor(this.zona.Posicion, "Transfer") ?? 1
          );
        } else {
          coloresMaximos = parseInt(
            this.stmMaxColor(this.zona.Posicion, tecnica) ?? 1
          );
        }
      }

      console.log("🚀 ~ coloresDisponibles ~ coloresMaximos:", coloresMaximos);
      return Array.from({ length: coloresMaximos }, (_, i) => i + 1);
    },

    // Información del tipo de fondo, dependiendo del modelo y zona (BLANCO, CLARO o OSCURO)
    infoZonasFondo: function () {
      return this.zonesFondoByZone(this.zona.Posicion);
    },

    // Información de alerta
    alertInfo() {
      let listAlert = [];

      // Warning relacionadas con sublimación
      if (
        this.tecnicaSeleccionada == "SublimacionParcial" ||
        this.tecnicaSeleccionada == "SublimacionTotal" ||
        this.tecnicaSeleccionada == "SublimacionStm" ||
        this.tecnicaSeleccionada == "SublimacionStmIndividualLanyard" ||
        this.tecnicaSeleccionada == "SublimacionStmSombreros" ||
        this.tecnicaSeleccionada == "SublimacionStmTazas"
      ) {
        let sublimacionInfo = this.sublimacionStmInfo(this.zona.Posicion);
        console.log("🚀 ~ alertInfo ~ sublimacionInfo:", sublimacionInfo);

        if (sublimacionInfo?.SoloBlanco ?? false) {
          listAlert.push({
            type: "warning",
            text: this.$t("configuracion.Sublimacion.sublimacionSoloBlanco")
          });
        } else {
          let fondoBlanco = false;
          const soloBlancoEnCarrito =
            this.carrito.productos.length >= 1 &&
            this.carrito.productos.every(p => p.color == "01");

          if (soloBlancoEnCarrito) {
            // Si solo hay blanco en el carrito no es necesario mirar más
            fondoBlanco = true;
          } else if (this.actualFondo) {
            fondoBlanco =
              this.carrito.productos.length >= 1 &&
              this.carrito.productos.every(p => {
                if (p.color == "01") return true;

                let actualFondoProduct = this.actualFondo.find(
                  c => c.Color == p.color
                );
                return actualFondoProduct && actualFondoProduct.isBlanco;
              });
          }

          if (!fondoBlanco) {
            listAlert.push({
              type: "warning",
              text: this.$t("alert.alertSublimacionZonaNoBlanca")
            });
          }
        }
      }

      return listAlert;
    },

    // Tamaños disponibles para Digital para la posición actual
    actualPositionSizeDigitalOptions() {
      let result = this.sizeDigitalOptions[this.zona.Posicion];
      console.log("🚀 ~ actualPositionSizeDigitalOptions ~ result:", result);

      return result;
    },

    // Listado de tamaños disponibles para Doming
    avalibleSizeDoming() {
      const listSizes = this.getFilteredTecnicasExtra(
        this.zona.Posicion,
        "Doming"
      )?.Sizes;
      console.log("🚀 ~ avalibleSizeDoming ~ listSizes:", listSizes);

      if (listSizes == null) return [];

      return (
        Object.keys(listSizes)?.map(item => {
          return {
            value: listSizes[item],
            label: `${listSizes[item]} cm2`,
            code: item
          };
        }) ?? []
      );
    },

    // Indica si debemos forzar el tamaño máximo
    isSizeTamanoCm2Forced() {
      if (!this.infoTecnicaSeleccionada.tamanoCm2) return false;

      let tecnicaInfo = null;
      if (this.infoTecnicaSeleccionada.valueTecnica.startsWith("Sublimacion")) {
        tecnicaInfo = this.getFilteredTecnicasExtra(
          this.zona.Posicion,
          "Sublimacion"
        );
      } else {
        tecnicaInfo = this.getFilteredTecnicasExtra(
          this.zona.Posicion,
          this.infoTecnicaSeleccionada.valueTecnica.replace("Stm", "")
        );
      }

      return tecnicaInfo?.calculateByUnits ?? false;
    },

    // Calculo de tamaño máximo para aquellas técnicas con cm2 como input
    maxSizeForTamanoCm2() {
      // Comprobamos que tenga activado el input de tamanoCm2
      if (
        !this.infoTecnicaSeleccionada?.valueTecnica ||
        !this.infoTecnicaSeleccionada.tamanoCm2
      ) {
        return null;
      }

      let actualInfo = null;

      // En caso de ser una de las técnicas de Transfer
      if (this.infoTecnicaSeleccionada.valueTecnica.startsWith("Transfer") && this.infoTecnicaSeleccionada.valueTecnica != "TransferDigitalStm") {
        actualInfo = this.getFilteredTecnicasExtra(
          this.zona.Posicion,
          "Transfer"
        );
      } else if (
        this.infoTecnicaSeleccionada.valueTecnica.startsWith("Sublimacion")
      ) {
        actualInfo = this.getFilteredTecnicasExtra(
          this.zona.Posicion,
          "Sublimacion"
        );
      } else {
        actualInfo = this.getFilteredTecnicasExtra(
          this.zona.Posicion,
          this.infoTecnicaSeleccionada.valueTecnica
        );
      }

      // Comprobamos haber obtenido la información necesaria
      if (
        !actualInfo ||
        !actualInfo.TamanoCm2 ||
        !actualInfo.Height ||
        !actualInfo.Width
      ) {
        console.warn(
          "[maxSizeForTamanoCm2] No se ha encontrado la información necesaria para calcular el tamaño máximo"
        );
        return null;
      }

      var listTallas = this.carrito.productos.map(p => p.talla);
      var listProduct = this.modelPrice.tallasInfo.filter(t =>
        listTallas.includes(t.Talla)
      );
      var productosSelect = [];

      listProduct.forEach(x => {
        productosSelect.push(
          x.Posiciones.find(p => this.zona && p.Posicion == this.zona.Posicion)
        );
      });

      // Obtener el área más pequeña del listado de productos
      let smallestArea = Infinity;
      let ancho = 0;
      let alto = 0;
      productosSelect.forEach(producto => {
        if (ancho == 0 || alto == 0) {
          ancho = producto.Ancho;
          alto = producto.Alto;
        }

        const area = producto.Ancho * producto.Alto;
        if (area < smallestArea) {
          smallestArea = area;
          ancho = producto.Ancho;
          alto = producto.Alto;
        }
      });

      // Pasamos el area a mm para hacer bien el calculo
      let tamanoCm2 = actualInfo.TamanoCm2 * 100;

      if (tamanoCm2 < smallestArea) {
        smallestArea = tamanoCm2;

        let alturaObjetivo = actualInfo.Height;

        let anchoObjetivo = actualInfo.Width;
        return {
          Area: smallestArea / 100,
          Alto: Math.round(alturaObjetivo),
          Ancho: Math.round(anchoObjetivo)
        };
      }

      return {
        Area: smallestArea / 100,
        Alto: alto,
        Ancho: ancho
      };
    },

    // Tipos de termograbado
    avalibleTermograbado() {
      if (!this.infoTecnicaSeleccionada.typeTermograbado) {
        return [];
      }

      return Object.keys(
        this.getFilteredTecnicasExtra(this.zona.Posicion, "Termograbado")
      ).map(type => {
        return {
          text: this.$t("configuracion.CalculoPrecios.Termografia." + type),
          value: type
        };
      });
    }
  },
  watch: {
    // Si se actualiza el listado de técnicas, intentamos buscar la técnica seleccionada, si ya no existe, ponemos la primera valida
    tecnicas(newVal) {
      if (newVal?.length) {
        console.log("🚀 ~ tecnicas ~ newVal:", newVal);
        console.log(
          "🚀 ~ tecnicas ~ this.tecnicaSeleccionada:",
          this.tecnicaSeleccionada
        );

        if (
          newVal.some(
            t =>
              (!this.hasGroupedTecnicaSelected &&
                t.valueTecnica == this.tecnicaSeleccionada) ||
              (this.hasGroupedTecnicaSelected &&
                t.valueTecnica == this.specificTecnicaSeleccionadOfGroup)
          )
        )
          return;
        console.log(
          "UPDATE TECNICASELECCIONADA",
          this.tecnicaSeleccionada,
          this.hasGroupedTecnicaSelected,
          this.specificTecnicaSeleccionadOfGroup,
          newVal[0].valueTecnica
        );
        this.tecnicaSeleccionada = newVal[0].valueTecnica;
      }
    },

    // Si hay un nuevo grupo de técnicas seleccionadas, comprobamos que siga siendo correcto su valor
    hasGroupedTecnicaSelected(newVal, oldVal) {
      if (
        newVal &&
        !oldVal &&
        this.groupedTecnicas[this.tecnicaSeleccionada].length
      ) {
        this.specificTecnicaSeleccionadOfGroup =
          this.groupedTecnicas[this.tecnicaSeleccionada][0].valueTecnica;
      }
    },

    // Actualizamos alto y ancho en caso de que se actualice el tamaño en cm2
    maxSizeForTamanoCm2(newVal, oldVal) {
      if (newVal && oldVal) {
        let sameArea = newVal.Area == oldVal.Area;
        let sameAlto = newVal.Alto == oldVal.Alto;
        let sameAncho = newVal.Ancho == oldVal.Ancho;
        if (sameArea && sameAlto && sameAncho) return;
      }

      if (this.infoTecnicaSeleccionada.tamanoCm2) {
        this.params.tamanoCm2Area = newVal?.Area ?? 1;
        this.widthTamanoCm2 = newVal?.Ancho ?? 1;
        this.heightTamanoCm2 = newVal?.Alto ?? 1;
      }
    },

    // Actualizamos el número de colores si actualmente tiene puesto uno demasiado grande
    maxColores(newVal) {
      if (newVal < this.params.colores) this.params.colores = 1;
    },

    // Si se cambia el arrai de número de colores disponibles, y el color actual no esta incluido
    coloresDisponibles(newVal) {
      if (!newVal?.length) return;

      if (newVal.includes(this.params.colores)) return;

      this.params.colores = newVal[0];
    },

    // Si se cambia el tamaño máximo de la zona para el conjunto de productos seleccionados, comprobamos que tenga un tamaño válido
    actualMaxValidSize(newVal) {
      console.log("🚀 ~ actualMaxValidSize ~ newVal:", newVal);
      if (!newVal?.text) return;

      if (
        newVal.Ancho > this.params.ancho ||
        newVal.Alto > this.params.alto ||
        !this.params.tamano
      ) {
        this.params.tamano = newVal?.text ?? "A8";
        this.params.maxWidth = this.tamanos[this.params.tamano].Ancho;
        this.params.maxHeight = this.tamanos[this.params.tamano].Alto;
        console.log("UPDATE PARAMS");
      }
    },

    // Si se cambia el tamaño máximo de la zona para impresión directa, comprobamos que tenga un tamaño válido
    actualMaxValidSizeImpresionDirecta(newVal) {
      if (!newVal?.value) return;

      let resultFind = this.tamanosImpresionDirecta.find(
        t => t.value == this.params.idTamImpresion
      );

      if (
        newVal.Ancho > resultFind.Ancho ||
        newVal.Alto > resultFind.Alto ||
        !this.params.idTamImpresion
      ) {
        this.params.idTamImpresion = newVal?.value ?? 4;
      }
    },

    // Si se actualiza el listado de opciones, comprobamos que la opción seleccionada siga siendo válida
    filteredOptions(newVal) {
      if (!this.infoTecnicaSeleccionada.typeColor || !newVal?.length) {
        this.params.typeColor = null;
        return;
      }

      if (!newVal.some(p => p.Type == this.params.typeColor)) {
        this.params.typeColor = newVal[0].Type;
      }
    },

    // Si se actualiza la altura máxima, revisamos que tenga un valor correcto
    maxHeightSelectOption(newVal) {
      if (!newVal?.length) {
        this.params.maxHeightSelect = 100;
        return;
      }

      if (!newVal.includes(this.params.maxHeightSelect)) {
        this.params.maxHeightSelect = newVal[0];
      }
    },

    // En caso de que se actualice el número de caras, comprobamos que tengamos un valor valido
    numCarasAvaliblesArray(newVal) {
      if (!newVal?.length) return;

      if (
        this.params.numCaras &&
        newVal.some(v => v.value == this.params.numCaras)
      )
        return;

      this.params.numCaras = newVal[0].value;
    },

    // Recalculamos si el tamaño máximo es válido para el seleccionado actualmente
    actualMaxValidSizeNumDtf(newVal, oldVal) {
      if (!this.infoTecnicaSeleccionada.tamanoEstampadoNumerosDTF || !newVal) {
        this.params.tamanoEstampadoNumerosDTF = null;
        return;
      }

      if (newVal == oldVal) return;

      let actualSelected = this.filteredOptions.find(
        o => o.value == this.params.tamanoEstampadoNumerosDTF
      );

      if (!actualSelected || (newVal && newVal.value != actualSelected.value)) {
        this.params.tamanoEstampadoNumerosDTF =
          newVal?.value ?? this.filteredOptions[0].value;
      }
    },

    // IMPORTANTE: si cualquier parámetro activo se cambia, se lanza el calculo del precio actual, con la nueva configuración
    params: {
      deep: true,
      async handler() {
        console.log("- - - DEBUG: update params - - -");
        this.calculateActualTecnicaDebounced();
      }
    },

    // Si varia las opciones de Digital, comprobamos que sigamos teniendo un valor válido
    actualPositionSizeDigitalOptions(newVal) {
      if (newVal?.length) {
        if (newVal.some(p => p.code == this.params.digitalTam)) return;

        this.params.digitalTam = newVal[0].code;
      }
    },

    // Comprobamos que tenemos un valor valido para doming
    avalibleSizeDoming(newVal) {
      console.log("🚀 ~ avalibleSizeDoming ~ newVal:", newVal);
      if (newVal?.length) {
        if (newVal.some(p => p.code == this.params?.domingTamCode)) return;

        this.params.domingTamCode = newVal[0].code;
      }
    },

    //  Comprobamos que el tipo de termograbado sigue siendo valido
    avalibleTermograbado(newVal) {
      if (newVal?.length) {
        console.log("🚀 ~ avalibleTermograbado - newVal:", newVal);
        if (newVal.some(t => t.value == this.params.typeTermograbado)) return;

        this.params.typeTermograbado = newVal[0].value;
      }
    },

    //  Si se cambia el número de unidades totales, debemos forzar una actualización de precios, y recalcular la técnica seleccionada aunque no se hayan modificado los parámetros
    totalUnidades(newVal, oldVal) {
      console.log("🚀 ~ file: GenericGlobalCalculadora.vue:1463 ~ totalUnidades ~ newVal:", newVal)
      if (!newVal || newVal < 1) return;

      if (newVal == oldVal) return;

      console.log("- - - DEBUG: update totalUnidades - - -");
      this.calculateActualTecnicaDebounced(true);
    },

    // Si se cambia la técnica seleccionada, debemos volver a obtener los parámetros que sean necesarios para la técnica, resetear los campos correspondientes y forzamos una actualización de precios
    async infoTecnicaSeleccionada(newVal, oldVal) {
      if (!newVal) return;

      if (newVal?.valueTecnica == oldVal?.valueTecnica) return;

      if (newVal.valueTecnica == "DTFNumeros") {
        await this.fetchOptionsNumerosDtf();
      }

      if (newVal.valueTecnica == "TransferNumeros") {
        await this.fetchOptionsNumerosTransfer();
      }

      if (
        newVal.tamanocm2Digital &&
        !this.actualPositionSizeDigitalOptions?.length
      ) {
        await this.fetchSizeDigitalOptions(this.zona.Posicion);
      }

      if (newVal.caras && this.numCarasAvaliblesArray.length) {
        this.params.numCaras = this.numCarasAvaliblesArray[0].value;
      }

      if (newVal.dobleBaseSerigrafia && !this.showOpcionesAvanzadas) {
        this.showOpcionesAvanzadas = true;
      }

      if (newVal.tamanoCm2Doming && this.avalibleSizeDoming.length) {
        this.params.domingTamCode = this.avalibleSizeDoming[0].code;
      }

      if (newVal.typeTermograbado && this.avalibleTermograbado.length) {
        this.params.typeTermograbado = this.avalibleTermograbado[0].value;
      }

      console.log("- - - DEBUG: update infoTecnicaSeleccionada - - -");

      this.calculateActualTecnica(true);
    },

    // Si se indica que debemos poner metros lineales provocaremos una actualización de precios forzosa
    seEnvianMetrosLineales() {
      console.log("- - - DEBUG: update seEnvianMetrosLineales - - -");
      this.calculateActualTecnica();
    }
  },

  mounted() {
    // Forzamos a seleccionar una primera técnica
    if (!this.tecnicaSeleccionada && this.tecnicas?.length) {
      if (!this.tecnicas[0]?.groupTecnica) {
        this.tecnicaSeleccionada = this.tecnicas[0].valueTecnica;
      }
      else {
        this.tecnicaSeleccionada = this.tecnicas[0].groupTecnica;
        console.log("🚀 ~ file: GenericGlobalCalculadora.vue:1530 ~ mounted ~ this.tecnicaSeleccionada:", this.tecnicaSeleccionada)
        this.specificTecnicaSeleccionadOfGroup = this.groupedTecnicas[this.tecnicaSeleccionada]?.[0]?.valueTecnica
        console.log("🚀 ~ file: GenericGlobalCalculadora.vue:1532 ~ mounted ~ this.specificTecnicaSeleccionadOfGroup:", this.specificTecnicaSeleccionadOfGroup)
      }
    }
  },
  methods: {
    ...mapActions("carrito", ["calcularPrecios", "deleteReprocesoById"]),
    ...mapActions("modelo", ["addAlert"]),
    ...mapActions("config", [
      "fetchOptionsNumerosDtf",
      "fetchOptionsNumerosTransfer",
      "fetchSizeDigitalOptions"
    ]),

    // Actualizar tamaño de cm2
    updateSizeCm2(value, type) {
      let maxSizeTecnica = this.maxSizeForTamanoCm2;
      console.log("🚀 ~ file: GenericGlobalCalculadora.vue:1539 ~ updateSizeCm2 ~ maxSizeTecnica:", maxSizeTecnica)

      if (!maxSizeTecnica) {
        console.warn("No se ha definido el tamaño máximo");
        return;
      }

      // Si es de type Area, actualizamos el alto y ancho, siempre que no supere el maximo
      if (type == "Area" && value <= maxSizeTecnica.Area) {
        // Pasamos de cm2 a mm2
        value *= 100;

        const alturaObjetivo = Math.min(
          Math.sqrt((value * maxSizeTecnica.Alto) / maxSizeTecnica.Ancho),
          maxSizeTecnica.Alto
        );

        // Calcula la anchura usando el valor objetivo de la altura
        const anchoObjetivo = value / alturaObjetivo;

        // Redondea los valores al entero más cercano
        this.heightTamanoCm2 = Math.round(alturaObjetivo);
        this.widthTamanoCm2 = Math.round(anchoObjetivo);

        this.params.tamanoCm2Area = Math.round(value / 100);
      } else if (type == "Alto" && value <= maxSizeTecnica.Alto) {
        // Calculamos el area y comprobamos que no nos pasemos del máximo
        const area = (value * this.anchoTamanoCm2) / 100;

        if (area <= maxSizeTecnica.Area) {
          this.params.tamanoCm2Area = area;
          this.heightTamanoCm2 = value;
        } else {
          this.updateSizeCm2(maxSizeTecnica.Area, "Area");
        }
      } else if (type == "Ancho" && value <= maxSizeTecnica.Ancho) {
        // Calculamos el area y comprobamos que no nos pasemos del máximo
        const area = (value * this.heightTamanoCm2) / 100;

        if (area <= maxSizeTecnica.Area) {
          this.params.tamanoCm2Area = area;
          this.widthTamanoCm2 = value;
        } else {
          this.updateSizeCm2(maxSizeTecnica.Area, "Area");
        }
      } else {
        console.warn("Intentando hacer el calculo para el area máxima");
        this.updateSizeCm2(maxSizeTecnica.Area, "Area");
      }
    },

    // Método para actualizar ancho y alto respecto al tamaño seleciconado
    actualGetMedidasByTamano() {
      let tamano = this.params.tamano;
      var result = this.getMedidasByTamano(tamano);
      this.params.maxWidth = result.Ancho;
      this.params.maxHeight = result.Alto;
    },

    // Método para actualizar el tamaño respecto al ancho y alto
    updateTamanoByHeightAndWidth() {
      let tamañoCompatible = null;
      let diferenciaMinima = Infinity;

      for (const [tamaño, dimensiones] of Object.entries(this.tamanos)) {
        if (
          dimensiones.Ancho >= this.params.maxWidth &&
          dimensiones.Alto >= this.params.maxHeight
        ) {
          const diferencia =
            dimensiones.Ancho -
            this.params.maxWidth +
            (dimensiones.Alto - this.params.maxHeight);
          if (diferencia < diferenciaMinima) {
            diferenciaMinima = diferencia;
            tamañoCompatible = tamaño;
          }
        }
      }

      this.params.tamano = tamañoCompatible;
    },

    // Metodo para emitir el evento de borrado de la zona
    removeZone() {
      this.$emit("removeZone");

      this.deleteReprocesoById(this.zona.id);
    },

    // Función wrapper de calculateActualTecnica para crear el debounce de 700ms
    calculateActualTecnicaDebounced() {
      console.log("DEBOUNCED!!!!");
      clearTimeout(this.debouncedCalculate);
      this.debouncedCalculate = setTimeout(async () => {
        console.log("END DEBOUNCED!!!!");
        await this.calculateActualTecnica();
      }, 700);
    },

    // Función para calcular el precio de la técnica actual
    async calculateActualTecnica(noCompareOldParams = false) {
      {
        console.log("START CALCULATE TECNICA");
        let param = { ...this.params };
        let resultParam = {};

        // Se van a comprobar que campos tenemos activos en infoTecnicaSeleccionada
        // todos aquellos que sean true, se hara la comproción que corresponda y se añadirá
        // a los atributos a enviar a la api para el calculo de precio

        let actualTecnicaValue = this.infoTecnicaSeleccionada.valueTecnica;

        // Comprobación unidades mínimas
        const isSublimacion = actualTecnicaValue.startsWith("SublimacionStm");
        const isSerigrafia =
          !isSublimacion && actualTecnicaValue.startsWith("SerigrafiaStm");
        if (isSublimacion || isSerigrafia) {
          let tecnicaInfo = this.getFilteredTecnicasExtra(
            this.zona.Posicion,
            isSublimacion
              ? "Sublimacion"
              : isSerigrafia
                ? "Serigrafia"
                : actualTecnicaValue.replace("Stm", "")
          );

          if (tecnicaInfo?.MinUnit) {
            if (this.totalUnidades < tecnicaInfo.MinUnit) {
              this.addAlert({
                title: this.$t("alert.errorMinUnitTitleDialog", {
                  modelo: this.modelCode
                }),
                code: "errorMinUnit",
                minUnit: tecnicaInfo?.MinUnit ?? 0
              });
              return;
            }
          }
        }

        // Comprobaciones de maxWidth y maxHeight
        if (
          this.infoTecnicaSeleccionada.maxWidth &&
          this.infoTecnicaSeleccionada.maxHeight &&
          !this.infoTecnicaSeleccionada.noShowHeightAndWidth
        ) {
          if (!param.maxWidth || !param.maxHeight) {
            console.warn("No se han definido las medidas");
            return;
          }

          var excedido = this.medidasMaximas(
            this.zona.Posicion,
            param.maxWidth,
            param.maxHeight
          );
          console.log("🚀 ~ calculateActualTecnica ~ excedido:", excedido);

          if (excedido.length > 0) {
            this.addAlert({
              title:
                this.$t("alert.errorTamEstampacionTitleDialog") +
                " (" +
                this.$t("Tecnicas.SERIGRAFÍA") +
                ")",
              listProduct: excedido,
              code: "errorTamEstampacion",
              tamEstX: this.ancho,
              tamEstY: this.alto,
              zone: this.zona,
              tamEstampacion: this.tamaño
            });
          }

          resultParam.maxWidth = param.maxWidth;
          resultParam.maxHeight = param.maxHeight;
        }

        // Calculo de prendasBlanco, prendasClaro y prendasOscuro para las técnicas que le sean necesario
        if (
          this.infoTecnicaSeleccionada.prendasBlanco &&
          this.infoTecnicaSeleccionada.prendasClaro &&
          this.infoTecnicaSeleccionada.prendasOscuro
        ) {
          let hasSpecificZone =
            this.infoZonasFondo &&
            this.infoZonasFondo.AvalibleColors &&
            this.infoZonasFondo.AvalibleColors.length > 0;

          resultParam.prendasBlanco = 0;
          resultParam.prendasClaro = 0;
          resultParam.prendasOscuro = 0;

          this.carrito.productos.forEach(e => {
            let productInfo = this.modelInfoFromCatalog.products[e.id];
            if (productInfo == null) return;
            console.log(
              "🚀 ~ calculateActualTecnica ~ productInfo:",
              productInfo
            );

            if (hasSpecificZone) {
              let actualColorZone = this.infoZonasFondo.AvalibleColors.find(
                c => c.Color == e.color
              );

              if (actualColorZone) {
                if (actualColorZone.isBlanco) {
                  resultParam.prendasBlanco += parseInt(e.cantidad);
                } else if (actualColorZone.isClaro) {
                  resultParam.prendasClaro += parseInt(e.cantidad);
                } else if (actualColorZone.isOscuro) {
                  resultParam.prendasOscuro += parseInt(e.cantidad);
                }
                return;
              }
            }

            if (productInfo.COLOR == "01") {
              resultParam.prendasBlanco += parseInt(e.cantidad);
            } else if (!this.listColorFondo[productInfo.COLOR] == 0)
              resultParam.prendasClaro += parseInt(e.cantidad);
            else resultParam.prendasOscuro += parseInt(e.cantidad);

            if (
              !resultParam.prendasClaro &&
              !resultParam.prendasClaro &&
              !resultParam.prendasBlanco
            ) {
              console.warn(
                "No se han encontrado unidades para ninguna combinación"
              );
              return;
            }
          });
        }

        if (this.infoTecnicaSeleccionada.idTipoTinta) {
          if (!param.idTipoTinta) {
            console.warn("No se han encontrado un id de tinta valido");
            return;
          }

          resultParam.idTipoTinta = param.idTipoTinta;
        }

        if (this.infoTecnicaSeleccionada.hasPlanchado) {
          resultParam.hasPlanchado = param.hasPlanchado ?? false;
        }

        if (this.infoTecnicaSeleccionada.tintaNegra) {
          resultParam.tintaNegra = param.tintaNegra ?? false;
        }

        if (this.infoTecnicaSeleccionada.colores) {
          if (!param.colores || param.colores < 1) {
            console.warn("No se han encontrado número de colores correcto");
            return;
          }
          resultParam.colores = param.colores;

          if (
            this.infoTecnicaSeleccionada.valueTecnica ==
              "TransferSerigraficoStm" &&
            !this.isBlanco
          ) {
            resultParam.colores++;
          }
        }

        if (this.infoTecnicaSeleccionada.tamano) {
          if (
            this.infoTecnicaSeleccionada.MetrosLineales &&
            this.seEnvianMetrosLineales
          ) {
            resultParam.tamano = "AML";
          } else {
            if (!param.tamano) {
              console.warn("No se han encontrado un tamano válido");
              return;
            }
            resultParam.tamano = param.tamano;
          }
        }

        if (this.infoTecnicaSeleccionada.nreprocesos) {
          resultParam.nreprocesos = this.numReprocesosSerigrafiaOrTransfer ?? 0;
        }

        if (this.infoTecnicaSeleccionada.esPrendaPackUno) {
          resultParam.esPrendaPackUno = this.hasPackUno ?? false;
        }

        if (this.infoTecnicaSeleccionada.tintas) {
          resultParam.tintas = "blanca";
        }

        if (this.infoTecnicaSeleccionada.puntadas) {
          if (!param.puntadas || param.puntadas < 1) {
            console.warn(
              "No se han encontrado un proporcionado un número de puntadas válido"
            );
            return;
          }

          resultParam.puntadas = param.puntadas;
        }

        if (this.infoTecnicaSeleccionada.productos) {
          resultParam.productos = Object.keys(this.quantityByColor).reduce(
            (acc, cur) => acc + this.quantityByColor[cur] + "::" + cur + ";",
            ""
          );
        }

        if (this.infoTecnicaSeleccionada.fondo) {
          resultParam.fondo = "Claro"; // Impresión directa siempre claro
        }

        if (this.infoTecnicaSeleccionada.MetrosLineales) {
          if (this.seEnvianMetrosLineales) {
            resultParam.MetrosLineales = param.MetrosLineales;
          }
        }

        if (this.infoTecnicaSeleccionada.dobleNumero) {
          resultParam.dobleNumero = param.dobleNumero;
        }

        if (this.infoTecnicaSeleccionada.idTamImpresion) {
          let resultFind = this.tamanosImpresionDirecta.find(
            t => t.value == param.idTamImpresion
          );
          let xaxis = resultFind.Ancho;
          let yaxis = resultFind.Alto;

          let excedido = this.medidasMaximas(this.zona.Posicion, xaxis, yaxis);
          if (excedido && excedido.length > 0) {
            this.addAlert({
              title:
                this.$t("alert.errorTamEstampacionTitleDialog") +
                " (" +
                this.$t("Tecnicas.IMPRESIÓN DIRECTA") +
                ")",
              listProduct: excedido,
              code: "errorTamEstampacion",
              tamEstX: xaxis,
              tamEstY: yaxis,
              zone: this.zona,
              tamEstampacion: resultFind?.text
            });
          }

          resultParam.idTamImpresion = param.idTamImpresion;
        }

        if (this.infoTecnicaSeleccionada.tamanoEstampadoNumerosDTF) {
          let actualSelected = this.filteredOptions.find(
            o => o.value == param.tamanoEstampadoNumerosDTF
          );

          if (!actualSelected) {
            console.warn(
              "No se ha encontrado el tamaño seleccionado (numeros)"
            );
            return;
          }

          if (
            actualSelected.Alto > this.actualMaxValidSizeNumDtf.Alto ||
            actualSelected.Ancho > this.actualMaxValidSizeNumDtf.Ancho
          ) {
            this.addAlert({
              title:
                this.$t("alert.errorTamEstampacionTitleDialog") +
                " (" +
                this.$t("Tecnicas.NUMEROS-DTF") +
                ")",
              listProduct: [],
              code: "errorTamEstampacion",
              tamEstX: actualSelected.Ancho,
              tamEstY: actualSelected.Alto,
              zone: this.zona,
              tamEstampacion: param.tamanoEstampadoNumerosDTF
            });
          }

          if (actualSelected.minimum > this.totalUnidades) {
            this.addAlert({
              code: "errorMin",
              minUnit: actualSelected.minimum ?? 0
            });
            return;
          }

          if (
            this.infoTecnicaSeleccionada.maxWidth &&
            this.infoTecnicaSeleccionada.maxHeight
          ) {
            resultParam.maxWidth = actualSelected.Ancho;
            resultParam.maxHeight = actualSelected.Alto;
          }

          if (!param.tamanoEstampadoNumerosDTF || !actualSelected) {
            console.warm("No tamaño especificado para DTF");
            return;
          }

          resultParam.tamanoEstampadoNumerosDTF =
            param.tamanoEstampadoNumerosDTF;
        }

        if (this.infoTecnicaSeleccionada.disenoEspecial) {
          resultParam.disenoEspecial = param.disenoEspecial;
        }

        if (this.infoTecnicaSeleccionada.coloresImprDir) {
          resultParam.coloresImprDir = JSON.stringify(this.quantityByColor);
        }

        if (this.infoTecnicaSeleccionada.typeColor) {
          resultParam.typeColor = param.typeColor;
        }

        if (this.infoTecnicaSeleccionada.maxHeightSelect) {
          resultParam.maxHeight = param.maxHeightSelect;
        }

        if (this.infoTecnicaSeleccionada.isTransferDigital) {
          resultParam.isTransferDigital = param.isTransferDigital;
        }

        if (this.infoTecnicaSeleccionada.isTransferSerigrafico) {
          resultParam.isTransferSerigrafico = param.isTransferSerigrafico;
        }

        if (
          this.infoTecnicaSeleccionada.caras ||
          this.infoTecnicaSeleccionada.specificCaras
        ) {
          resultParam.caras = param.numCaras;

          if (
            !this.numCarasAvaliblesArray.some(v => v.value == resultParam.caras)
          ) {
            console.warn("Parámetro no valido de caras", resultParam.caras);
            return;
          }
        }

        if (this.infoTecnicaSeleccionada.dobleBaseSerigrafia) {
          resultParam.dobleBaseSerigrafia = param.dobleBaseSerigrafia;
        }

        if (this.infoTecnicaSeleccionada.gajos) {
          resultParam.gajos = param.gajos;
        }

        if (this.infoTecnicaSeleccionada.tamanocm2Digital) {
          if (!param.digitalTam) {
            console.warn("No se ha seleccionado un tamaño digital");
            return;
          }

          if (
            !this.actualPositionSizeDigitalOptions.some(
              p => p.code == param.digitalTam
            )
          ) {
            console.warn("Tamaño digital no valido");
            return;
          }

          resultParam.tamanoDigital = param.digitalTam;
        }

        if (this.infoTecnicaSeleccionada.tamanoCm2Doming) {
          if (!param.domingTamCode) {
            console.warn("No se ha seleccionado un tamaño para doming");
            return;
          }

          if (
            !this.avalibleSizeDoming.some(p => p.code == param.domingTamCode)
          ) {
            console.warn("Tamaño doming no valido");
            return;
          }

          resultParam.tamanoCm2Doming = param.domingTamCode;
        }

        if (this.infoTecnicaSeleccionada.nombreANombre) {
          resultParam.nombreANombre = param.nombreANombre;
        }

        if (this.infoTecnicaSeleccionada.tamanoCm2) {
          resultParam.tamanoCm2 = param.tamanoCm2Area;

          if (resultParam.maxHeight || resultParam.maxWidth) {
            console.warn(
              "Sobrescribiendo valores por defecto de height y width por los generados por cm2"
            );
          }

          resultParam.maxHeight = this.heightTamanoCm2;
          resultParam.maxWidth = this.widthTamanoCm2;
        }

        if (this.infoTecnicaSeleccionada.fondoOscuro) {
          resultParam.fondoOscuro = !this.isBlanco;
        }

        if (this.infoTecnicaSeleccionada.typeTermograbado) {
          resultParam.typeTermograbado = param.typeTermograbado;
        }

        resultParam.repeticion = param.esRepeticion;

        resultParam.unidadesTotales = this.totalUnidades;

        let unidades = this.totalUnidades;

        // Transformamos los parámetros generados, en un string que usaremos para comprobar que se ha modificado algún parámetros, y si se ha modificado, se lo mandaremos a la api para actualizar
        let atr = JSON.stringify([resultParam]);
        console.log(
          "🚀 ~ handler ~ resultParam:",
          atr,
          this.lastAtrb,
          atr == this.lastAtrb
        );

        if (atr == this.lastAtrb && !noCompareOldParams) return;

        // Llamada a la api para calcular la técnica actual
        try {
          this.cargandoTabZona = true;
          await this.calcularPrecios({
            id: this.zona.id,
            tecnica: this.hasGroupedTecnicaSelected
              ? this.specificTecnicaSeleccionadOfGroup
              : this.tecnicaSeleccionada,
            codigo: this.modelInfoFromCatalog.code,
            posicion: this.zona.Posicion,
            zona: this.zona.Zona,
            unidades: unidades,
            isRepeticion: this.esRepeticion,
            atributos: atr
          });
        } catch (error) {
          if (error != "errorColorBanned") {
            console.error(error);
            alert(this.$t("general.Error al calcular precios"));
          }
        } finally {
          this.lastAtrb = atr;
          this.cargandoTabZona = false;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.generic-calculadora-parameters {
  a {
    text-decoration: underline;
    font: normal normal bold 12px/15px $heading-font-family;
    letter-spacing: 0px;
    color: #000000 !important;
  }
  .row {
    margin-top: 3px !important;

    .col {
      padding: 6px !important;
      &:first-child {
        padding-left: 12px !important;
      }
      
      &:last-child {
        padding-right: 12px !important;
      }
    }
  }

  .container-check-repeticion {
    padding: 6px !important;
  }

  .litle-head-title {
    font: normal normal normal 12px/15px $heading-font-family;
    letter-spacing: 0px;
  }

  .v-text-field--outlined.v-select {
    background: white !important;
  }

  .custom-input {
    margin: 2px 4px;
    display: flex;
    justify-content: center;
    max-height: 40px;

    .prepend-text {
      background: black;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px;
      padding: 4px 12px;
      text-transform: capitalize;
      min-height: 100%;
      flex-direction: column;
      min-width: 66px;
      border-radius: 4px 0px 0px 4px;
      font: normal normal 400 14px/18px $heading-font-family;
    }

    .v-input__slot {
      border-radius: 0px 4px 4px 0px;
      max-height: 46px;
    }

    .v-text-field__details {
      // font: normal normal normal 12px/15px $heading-font-family;
    }
  }
}

.v-input--checkbox {
  .v-label {
    font: normal normal normal 12px/15px $heading-font-family;
    letter-spacing: 0px;
  }
}
</style>
