<template>
  <span class="d-flex justify-end mb-3">
          <v-checkbox
            v-model="localValue"
            :disabled="disabled"
            :label="$t('configuracion.unidadesExactas')"
            color="primary darken-2 white--text"
            class="mt-1 pt-1"
          >
          </v-checkbox>

          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon class="mt-1">$fi-info</v-icon>
              </v-btn>
            </template>
            <span>{{$t("configuracion.infoUnidadesExactas")}}</span>
          </v-tooltip>
        </span>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        disabled: {
          type: Boolean,
          default: false
        }
    },
    data() {
        return { 
            localValue: this.value
        }
    },
    watch: {
        value(newValue) {
            this.localValue = newValue
        },
        localValue(newValue, oldValue) {
            if(newValue != oldValue)
                this.$emit('input', newValue);
        }
    },
}
</script>

<style lang="scss">
  .v-input--checkbox {
  .v-label {
    font: normal normal normal 12px/15px $heading-font-family;
    letter-spacing: 0px;
  }
}
</style>