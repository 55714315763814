<template>
  <div>
    <v-dialog
      ref="cartDialog"
      v-model="localDialog"
      transition="dialog-bottom-transition"
      scrollable
      :max-width="activeComercial ? '800px' : '1400px'"
      class="dialog-z-index-general"
      content-class="productModal cart-modal h-100 mh-100 mh-sm-90 youReprocesosProductModal margin-top-banner"
    >
      <template #activator="{ on }">
        <v-badge
          class="w-100"
          background-color="primary"
          :content="clientCartAvalible ? countClientCart : countComercialCart"
          overlap
          bordered
          :value="
            clientCartAvalible ? countClientCart != 0 : countComercialCart != 0
          "
        >
          <CotizadorBtn dark :on="on" content-class="w-100">
            <img
              class="ma-2 btn-icon-presupuesto"
              src="@/assets/cart.svg"
              alt="cart"
            />
            <span>{{ $t("presupuesto.fichaReprocesos.titulop1") }}</span>
          </CotizadorBtn>
        </v-badge>
      </template>
      <v-card :loading="loading != 0 ? 'primary' : false">
        <!--  Titulo del modal -->
        <v-card-title class="pa-0">
          <v-toolbar height="fit-content" dark color="black">
            <v-toolbar-title
              class="productos-titulo d-flex justify-space-between"
            >
              <v-container fluid>
                <v-row>
                  <v-col cols="8" class="d-flex align-center py-0">
                    <v-row>
                      <v-col cols="12" md="6" class="d-flex align-center py-0">
                        <span class="modelo-name">
                          {{ $t(`MODELS.${modelCode}.NAME`) }}
                        </span>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        class="d-flex justify-md-center align-center py-0"
                      >
                        <span>
                          {{ $t("general.Presupuesto") }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="4" class="d-flex flex-row-reverse pr-0 py-0">
                    <div class="left-border" @click="cerrar">
                      <v-btn icon dark>
                        <v-icon xl>mdi-close</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>

        <v-card-text>
          <div v-if="activeComercial" class="d-flex flex-column align-center">
            <div class="mt-3 d-flex flex-column align-center">
              <h5>
                {{ loggedClient.clientId }} -
                {{ loggedClient.clientName }}
              </h5>
              <h5>{{ userOfClient.label }}</h5>
            </div>
          </div>
        </v-card-text>

        <v-card-text class="mw-100 px-5 py-0">
          <v-row class="overflow-sm-hidden h-100">
            <v-col v-if="!activeComercial" cols="12" md="6" class=" overflow-y-sm-auto  h-100">
              <v-row class="presupuestoBody mx-3">
                <v-col>
                  <h2 class="title1">
                    {{ $t("presupuesto.incrementoTitle") }}
                  </h2>
                  <v-divider class="mt-1" />
                  <h3 class="subtitle1 mt-2">
                    {{ $t("presupuesto.incrementoSubtitle") }}
                  </h3>

                  <v-row class="mt-3">
                    <v-col class="py-0">
                      <div class="inLineInput">
                        <span>{{ $t("presupuesto.porcproductos") }}</span>
                        <v-text-field
                          v-model="misPrecios.porcentajeProductos"
                          class="mt-1"
                          type="number"
                          min="0"
                          step="1"
                          dense
                          hide-details
                        />
                      </div>
                    </v-col>
                    <v-col class="py-0">
                      <div class="inLineInput">
                        <span>{{ $t("presupuesto.porcreprocesos") }}</span>
                        <v-text-field
                          v-model="misPrecios.porcentajeReprocesos"
                          class="mt-1"
                          type="number"
                          min="0"
                          step="1"
                          dense
                          hide-details
                        />
                      </div>
                    </v-col>
                    <v-col class="py-0">
                      <div class="inLineInput">
                        <span>{{ $t("presupuesto.costesextras") }}</span>
                        <v-text-field
                          v-model="misPrecios.costeExtra"
                          class="mt-1"
                          type="number"
                          min="0"
                          step="1"
                          dense
                          hide-details
                        />
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="mt-3">
                    <v-col>
                      <span class="inLineInput">{{ $t("presupuesto.Observaciones") }}</span>
                      <v-textarea
                        v-model="misPrecios.Observaciones"
                        dense
                        auto-grow
                        no-resize
                        rows="2"
                        counter="250"
                      />
                    </v-col>
                  </v-row>

                  <h2 class="title1">{{ $t("presupuesto.MisDatos") }}</h2>
                  <v-divider class="mt-1" />
                  <v-row class="mt-2">
                    <v-col cols="9" class="encabezado">
                      <b>{{ $t("presupuesto.encabezadoTitle") }}</b>
                      <span>
                        {{ $t("presupuesto.encabezadoHelper") }}
                      </span>
                      <v-btn
                        :loading="isSelecting"
                        class="mt-3"
                        large
                        @click="handleFileImport"
                      >
                        <v-icon color="primary" class="mr-3">
                          mdi-plus-circle
                        </v-icon>
                        {{ $t("presupuesto.encabezadoUpload") }}
                      </v-btn>
                      <input
                        ref="uploader"
                        class="d-none"
                        type="file"
                        accept="image/*"
                        @change="selectFile"
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-row>
                        <v-col v-if="idImg">
                          <v-img
                            :id="idImg"
                            class="centerImg"
                            :src="misPrecios.CustomLogoPath"
                            width="85"
                            height="120"
                          />
                          <div class="centerContainer">
                            <v-btn @click="resetImg">
                              {{
                                $t("configuracion.ReprocesosGenerales.Eliminar")
                              }}
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </v-col>
                        <v-col v-else>
                          <v-img
                            src="@/assets/Page-encabezado.svg"
                            width="85"
                            height="120"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <div class="inLineInput">
                        <span>{{ $t("presupuesto.Empresa") }}</span>
                        <v-text-field
                          v-model="misPrecios.Empresa"
                          hide-details
                          clearable
                          dense
                        ></v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0" cols="7">
                      <div class="inLineInput">
                        <span>{{ $t("presupuesto.Correo") }}</span>
                        <v-text-field
                          v-model="misPrecios.correoContacto"
                          hide-details
                          clearable
                          dense
                          type="email"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col class="pt-0" cols="5">
                      <div class="inLineInput">
                        <span>{{ $t("presupuesto.Teléfono") }}</span>
                        <v-text-field
                          v-model="misPrecios.telefono"
                          hide-details
                          clearable
                          dense
                          type="tel"
                        ></v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0" cols="7">
                      <div class="inLineInput">
                        <span>{{ $t("presupuesto.DireccionCalle") }}</span>
                        <v-text-field
                          v-model="misPrecios.calle"
                          hide-details
                          clearable
                          dense
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col class="pt-0" cols="5">
                      <div class="inLineInput">
                        <span>{{ $t("presupuesto.CP") }}</span>
                        <v-text-field
                          v-model="misPrecios.cp"
                          hide-details
                          clearable
                          dense
                        ></v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <div class="inLineInput">
                        <span>{{ $t("presupuesto.Poblacion") }}</span>
                        <v-text-field
                          v-model="misPrecios.poblacion"
                          hide-details
                          clearable
                          dense
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col class="pt-0">
                      <div class="inLineInput">
                        <span>{{ $t("presupuesto.Ciudad") }}</span>
                        <v-text-field
                          v-model="misPrecios.ciudad"
                          hide-details
                          clearable
                          dense
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col class="pt-0">
                      <div class="inLineInput">
                        <span>{{ $t("presupuesto.Pais") }}</span>
                        <v-text-field
                          v-model="misPrecios.pais"
                          hide-details
                          clearable
                          dense
                        ></v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" :md="activeComercial ? 12 : 6" class=" overflow-y-sm-auto  h-100 custom-scroll">
              <v-container>
                <v-row class="cart-item-list">
                  <v-col class="py-0">
                    <v-container class="overflow-y-auto">
                      <v-row>
                        <v-col>
                          <span class="title-presupuestos-realizados">
                            {{ $t("cart.titleCartPresupuestosRealizados")}}
                          </span>
                        </v-col>
                      </v-row>
                      <v-row class="scroll-container">
                        <v-col>
                          <template v-if="totalActualCart > 0">
                            <cart-element
                              :cart="actualCart"
                              :loading="loading != 0 || loadingCarrito"
                            ></cart-element>
                          </template>
                          <template v-else>
                            <div
                              class="d-flex justify-center align-center loading-cart"
                            >
                              <v-icon class="icon-cart mr-3" large>
                                mdi-cart-outline
                              </v-icon>
                              <div>
                                <span class="subtitle1">
                                  {{ $t("cart.EmptyCart") }}
                                </span>
                                <br />
                                <span class="subtitle1">
                                  {{ $t("cart.SubheaderEmptyCart") }}
                                </span>
                              </div>
                            </div>
                          </template>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-card color="primary">
                            <v-card-actions>
                              <v-spacer />
                              <span class="totalHeader">
                                {{ $t("presupuesto.fichaReprocesos.Total") }}:
                                {{ totalActualCart }}
                                {{ currencySymbol }}
                              </span>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="d-flex clean-cart-text">
                          <v-spacer />
                          <a
                            :disabled="loading != 0 || totalActualCart == 0"
                            href="javascript:;"
                            @click="deleteCart"
                          >
                            {{ $t("cart.CleanCart") }}
                          </a>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
                <v-row class="extra-info-row mt-0">
                  <v-col class="py-0">
                    <v-container class="">
                      <v-row>
                        <v-col>
                          <span class="label-input">
                            {{ $t("cart.DireccionesEnvio") }}
                          </span>
                          <v-radio-group
                            v-model="direcciones"
                            class="radio-group-direction mt-0"
                            hide-details
                            row
                          >
                            <v-radio :value="false">
                              <template #label>
                                {{ $t("cart.DireccionesGuardadas") }}
                              </template>
                            </v-radio>
                            <v-radio :value="true">
                              <template #label>
                                {{ $t("cart.DireccionNuevaCliente") }}
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0">
                        <v-col>
                          <template v-if="!direcciones">
                            <v-select
                              v-model="selectedDireccion"
                              :items="shippingAddress"
                              :label="$t('presupuesto.DireccionCalle')"
                              filled
                              hide-details
                              item-text="address"
                              item-value="codToken"
                              :loading="loading != 0 || loadingCarrito"
                              class="items-address-selector"
                            >
                              <template #item="{ item }">
                                <div
                                  class="mt-2 pa-1 px-0 individual-address-item"
                                >
                                  <strong>
                                    {{ item.address }}
                                  </strong>
                                  <br />
                                  <span>
                                    {{ item.countrycode }}
                                  </span>
                                  <br />
                                  <span>
                                    {{
                                      `${item.street} ${item.cp} ${item.city} ${item.state} ${item.country}`
                                    }}
                                  </span>
                                  <br />
                                </div>
                              </template>
                            </v-select>
                          </template>
                          <template v-else>
                            <address-creator
                              v-model="customAddress"
                              :avalible.sync="avalibleCustomAddress"
                              :loading="loading != 0 || loadingCarrito"
                              container-class="pa-0"
                            />
                          </template>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col class="pb-0">
                          <span class="label-input">
                            {{ $t("presupuesto.Observaciones") }}
                          </span>
                          <v-textarea
                            v-model="observaciones"
                            class="mt-1"
                            filled
                            dense
                            auto-grow
                            no-resize
                            rows="3"
                            counter="250"
                            :rules="[
                              observacionesValid ||
                                $t('cart.MaxInputObservaciones', {
                                  numCaracter: 250
                                })
                            ]"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="mt-0">
                        <v-col class="pb-0">
                          <span class="label-input">
                            {{ $t("cart.Referencia") }}
                          </span>
                          <v-textarea
                            v-model="referencia"
                            class="mt-1"
                            filled
                            dense
                            auto-grow
                            no-resize
                            rows="1"
                            counter="100"
                            :rules="[
                              referenceValid ||
                                $t('cart.MaxInputObservaciones', {
                                  numCaracter: 100
                                })
                            ]"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="d-flex align-center">
          <v-spacer></v-spacer>

          <!-- <pdf class="mr-3" cotizador-btn :mis-precios="misPrecios"/> -->
          <cotizador-btn 
            class="mr-2"
            :loading="loading != 0 || loadingCarrito"
            :disabled="loading != 0 || loadingCarrito || totalActualCart == 0"
            :on="{
              click: dowloadAllPresupuestosInCartWithPrices
            }"
          > <v-icon small class="mr-1">mdi-download</v-icon>{{ $t("presupuesto.guardar") }}</cotizador-btn>

          <v-btn
            class="white--text"
            :loading="loading != 0 || loadingCarrito"
            :disabled="!avalibleConfirm"
            color="primary"
            @click="confirmAll"
          >
            {{ $t("cart.generateBudget") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import cartElement from "../basic/cartElement.vue";
import AddressCreator from "@/components/basic/AddressCreator.vue";
import CotizadorBtn from "@/components/basic/CotizadorBtn.vue";
// import Pdf from "@/components/presupuestado/generadorPDFPresupuesto.vue"

export default {
  components: {
    cartElement,
    AddressCreator,
    CotizadorBtn,
    // Pdf
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localDialog: this.dialog,
      loading: 0,
      observaciones: "",
      referencia: "",
      direcciones: false,
      selectedDireccion: null,
      customAddress: {
        street: "",
        cp: "",
        city: "",
        country: "Spain",
        state: "",
        statecode: "",
        district: "",
        email: "",
        phoneNumber: "",
        addresstype: "S"
      },
      avalibleCustomAddress: false,
      misPrecios: {},
      isSelecting: false,
      idImg: 0
    };
  },
  computed: {
    ...mapGetters("config", [
      "loggedClient",
      "userOfClient",
      "comercialToken",
      "translateTecnica",
      "activeComercial",
      "clientCartAvalible",
      "defaultPdfConfig"
    ]),
    ...mapGetters("carrito", [
      "actualCart",
      "countClientCart",
      "totalActualCart",
      "shippingAddress",
      "loadingCarrito",
      "countComercialCart",
      "countClientCart"
    ]),
    ...mapGetters("modelo", [
      "currencySymbol",
      "modelCode",
      "modelInfoFromCatalog"
    ]),
    avalibleConfirm() {
      let customAddress =
        (!this.direcciones && this.selectedDireccion) ||
        (this.direcciones && this.avalibleCustomAddress);

      return (
        this.actualCart.length > 0 &&
        customAddress &&
        this.loading == 0 &&
        this.observacionesValid &&
        this.referenceValid
      );
    },
    observacionesValid() {
      return this.observaciones.length <= 250;
    },
    referenceValid() {
      return this.referencia.length <= 100;
    },
    logo() {
      return this.customImg != null
        ? this.customImg
        : this.modelInfoFromCatalog &&
            (this.modelInfoFromCatalog.originalShop == "STM" ||
              this.modelInfoFromCatalog.originalShop == "STA")
          ? "https://static.gorfactory.es/b2b/Calculadora/logo_stamina.png"
          : "https://static.gorfactory.es/b2b/Calculadora/logo_roly.png";
    }
  },
  watch: {
    dialog(newVal) {
      console.log("🚀 ~ dialog ~ newVal:", newVal);
      if (newVal != this.localDialog) this.localDialog = newVal;

      if (newVal) {
        this.fetchAddress();
      }
    },
    localDialog(newVal) {
      if (newVal != this.dialog) {
        this.$emit("cerrar");
      }

      if (newVal && this.localDialog) {
        this.fetchActualCart();

        if (!this.shippingAddress?.length) this.fetchAddress();
      }

      if (newVal) {
        this.$nextTick(() => {
          this.$refs.cartDialog.showScroll();
        });
      }
    },
    comercialToken(newVal) {
      if (newVal && this.localDialog) {
        this.fetchActualCart();
      }
    },
    shippingAddress(newVal) {
      if (newVal.length > 0 && !this.direcciones) {
        // Buscamos la dirección actual
        const search = newVal.find(d => d.codToken == this.selectedDireccion);

        if (!search) {
          this.selectedDireccion =
            newVal.find(a => a.isDefault)?.codToken ?? newVal[0].codToken;
        }
      }
    }
  },
  created() {
    this.misPrecios = this.defaultPdfConfig;
  },
  methods: {
    ...mapActions("carrito", [
      "fetchActualCart",
      "deleteActualCart",
      "confirmActualCart",
      "fetchAddress",
      "dowloadAllPresupuestosInCart"
    ]),
    dowloadAllPresupuestosInCartWithPrices() {
      if (this.loading != 0 || this.loadingCarrito || this.totalActualCart == 0)
        return;

      this.dowloadAllPresupuestosInCart(this.misPrecios);
    },
    resetImg() {
      this.misPrecios.CustomLogo = null;
      this.misPrecios.CustomLogoPath = null;
      this.idImg = 0;
    },
    handleFileImport() {
      this.isSelecting = true;

      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      // Trigger click on the FileInput
      this.$refs.uploader.click();
    },
    selectFile(event) {
      const file = event.target.files[0];

      if (file) {
        var reader = new FileReader();
        var image = new Image();
        reader.onload = function () {
          image.onload = function () {
            // you can check the image width and height here
            var width = this.width;
            var height = this.height;

            if (width > 500 && height > 500) {
              throw "La imagen debe ser menor de 500x500";
            }
          };

          image.src = this.result;
        };

        reader.addEventListener(
          "loadend",
          () => {
            const base64 = reader.result;
            // this.$set(this.misPrecios, "CustomLogo", file);
            this.misPrecios.CustomLogo = base64;
            // this.$set(this.misPrecios, "CustomLogoPath", base64);
            this.misPrecios.CustomLogoPath = base64;
            this.idImg++;
          },
          { once: true }
        );

        reader.readAsDataURL(file);
      }
    },
    cerrar: function () {
      this.localDialog = 0;
      this.$emit("cerrar");
    },
    async deleteCart() {
      if (this.loading != 0 || this.loadingCarrito || this.totalActualCart == 0)
        return;

      this.loading++;
      await this.deleteActualCart();
      this.loading--;
      this.cerrar();
    },
    async confirmAll() {
      try {
        this.loading++;
        await this.confirmActualCart({
          observaciones: this.observaciones,
          referencia: this.referencia,
          direcciones: this.direcciones,
          addressCode: this.selectedDireccion,
          customAddress: this.customAddress
        });
        this.observaciones = "";
        this.referencia = "";
        this.cerrar();
        parent.postMessage({ type: "reloadCart" }, "*");
      } finally {
        this.loading--;
      }
    }
  }
};
</script>

<style lang="scss">
.max-width-fit-content {
  max-width: fit-content;
}
.v-application--is-ltr
  .v-textarea.v-text-field--enclosed
  .v-text-field__slot
  textarea {
  font: normal normal normal 12px/15px $heading-font-family;
  letter-spacing: 0px;
}

.cart-modal {

  .v-divider.theme--light {
    border-color: #707070;
  }

  .radio-group-direction .v-radio > .v-label {
    font: normal normal normal 12px/15px $heading-font-family;
    letter-spacing: 0px;
  }

  .label-input {
    font: normal normal 600 12px/15px $heading-font-family;
    letter-spacing: 0px;
  }

  .title-presupuestos-realizados {
    font: normal normal normal 31px/39px $heading-font-family;
    letter-spacing: 0px;
    color: black;
  }

  .clean-cart-text {
    padding-top: 0;

    a {
      text-decoration: underline;
      font: normal normal 600 12px/15px $heading-font-family;
      letter-spacing: 0px;
      color: var(--v-primary-darken3);
      cursor: pointer;
      text-transform: uppercase;
      transition: all 0.3s;

      &:hover {
        color: var(--v-primary-darken2);
      }

      &[disabled] {
        cursor: not-allowed;
        color: #707070;
      }
    }
  }

  .title1 {
    font: normal normal 600 16px/20px $heading-font-family;
    letter-spacing: 0px;
  }

  .subtitle1 {
    // color: #707070;
    opacity: 1;
    font: normal normal 400 15px/19px $heading-font-family;
    letter-spacing: 0px;
  }

  .presupuestoBody {
    font: normal normal 300 15px/19px $body-font-family;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;

    .encabezado {
      font-size: 12px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .v-btn {
        max-width: 320px;
        .v-btn__content {
          font-family: $heading-font-family, sans-serif;
          font-size: 17px;
          line-height: 40px;
          font-weight: 500;
          text-transform: none !important;
          letter-spacing: 0 !important;
        }
      }
    }

    .inLineInput {
      // display: flex;
      // align-items: center;
      max-height: 40px;
      letter-spacing: 0px;
      font: normal normal normal 12px/15px $heading-font-family;

      .v-input {
        margin: 0;
      }
    }
  }
}

.individual-address-item {
  max-width: 400px;
  font: normal normal normal 12px/15px $heading-font-family;
  letter-spacing: 0px;
}

.cart-item-list {
  .scroll-container {
    max-height: 250px;
  }
}

.title-list {
  font-size: 0.95rem;
}

.active-panel {
  box-shadow:
    0px 3px 1px -2px var(--v-primary-darken2),
    0px 2px 2px 0px var(--v-primary-darken2),
    0px 1px 5px 0px var(--v-primary-darken2);
}


.totalHeader {
  text-shadow: 0 0 1px var(--v-primaryColorText-lighten2);
  color: var(--v-primaryColorText-lighten2)
}

.scroll-container {
  overflow: auto;
}

.loading-cart {
  background-color: #f8f8f8;
  height: 100%;
  padding: 15px;
}

.btn-icon-presupuesto {
  width: 20px;
  height: 20px;
}

.dark-btn {
  .btn-icon-presupuesto {
    filter: invert(1);
  }
}
</style>
