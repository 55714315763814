// Importa el paquete feather-icons
import feather from 'feather-icons';

// Importa todos los archivos SVG del directorio '../assets/icons'
const requireIcons = require.context('../assets/icons', false, /\.svg$/);

// Reduce los archivos importados a un objeto de iconos personalizados
const customIcons = requireIcons.keys().reduce((icons, file) => {
    // Obtiene el nombre del icono eliminando './' y '.svg' del nombre del archivo
    const iconName = file.replace('./', '').replace('.svg', '');
    // Añade el icono al objeto customIcons
    icons[iconName] = requireIcons(file);
    return icons;
}, {});

// Crea un conjunto de iconos que combina los iconos de feather y los iconos personalizados
const featherIconsSet = {
    // Combina todos los iconos de feather y los iconos personalizados
    complete: { ...feather.icons, ...customIcons },
    aliases: {},
    values: Object.keys({ ...feather.icons, ...customIcons }).reduce((acc, iconName) => {
        acc[`fi-${iconName}`] = {
            component: {
                functional: true,
                props: { size: { type: [Number, String], default: 24 } },
                render(h, { props, data }) {
                    // Obtiene el tamaño predeterminado del icono
                    const defaultSize = props.size;

                    // Función para añadir 'px' si es necesario
                    const addPxIfNeeded = value => typeof value === 'number' || /^\d+$/.test(value) ? `${value}px` : value;

                    // Obtiene la altura y el ancho del estilo de los datos, o usa el tamaño predeterminado
                    const height = data.style?.height ? addPxIfNeeded(data.style.height) : `${defaultSize}px`;
                    const width = data.style?.width ? addPxIfNeeded(data.style.width) : `${defaultSize}px`;

                    // Devuelve un elemento 'span' con el icono SVG o una imagen si es un icono personalizado
                    return h('span', {
                        style: { display: 'inline-block', width, height, "margin-bottom": "4px", ...data.style },
                        domProps: { innerHTML: feather.icons[iconName]?.toSvg ? feather.icons[iconName].toSvg({ width, height, stroke: 'currentColor' }) : `<img src="${customIcons[iconName]}" width="${width}" height="${height}" />` }
                    });
                }
            }
        };
        return acc;
    }, {})
};

// Exporta el conjunto de iconos
export default featherIconsSet;