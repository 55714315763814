<template>
  <div
    v-if="modelCode && zonas && zonas.length > 0"
    :id="modelCode"
    class="zone-list"
  >
    <v-row class="min-zonas-size">
      <template v-if="totalUnidades > 0">
        <v-col
          v-for="(zona, key) in listZonas"
          :id="key + modelCode"
          :key="key"
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          class="px-1"
        >
          <Zona
            :id="key"
            :cantidad="cantidadCarrito"
            :zona="zona"
            :activo="key === 0"
            :color="color"
            @zonaChange="zonaChange(key, $event)"
          ></Zona>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="6" xl="6">
          <div class="mx-auto ml-2 cotizador-zona d-flex">
            <v-row cols="12">
              <v-col
                v-if="totalUnidades > 0"
                cols="12"
                class="d-flex flex-column justify-center align-center"
              >
                <div v-if="numZonas < maxZonas" @click="numZonas++">
                  <CotizadorBtn>+ {{ $t("general.Añadir zona") }}</CotizadorBtn>
                </div>
                <br />
                <div v-if="numZonas > 0" @click="numZonas--">
                  <CotizadorBtn>
                    + {{ $t("general.Eliminar zona") }}
                  </CotizadorBtn>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </template>
      <template v-else>
        <v-col cols="12" class="d-flex justify-center align-center loading-zonas">
          <v-progress-circular
            :size="100"
            :width="7"
            class="ml-5"
            indeterminate
            color="primary"
          >
            <v-icon size="72">mdi-calculator</v-icon>
          </v-progress-circular>

          <div class="ml-5">
            <b>{{ $t("alert.alertSelectProduct") }}</b>
          </div>
        </v-col>
      </template>
    </v-row>
  </div>
  <div v-else>
    {{ $t("general.El modelo seleccionado no tiene zonas") }}
  </div>
</template>

<script>
import Zona from "./Zona.vue";
import CotizadorBtn from "../basic/CotizadorBtn.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Zonas",
  components: {
    Zona,
    CotizadorBtn
  },
  props: {
    cantidad: {
      type: Number,
      default: 0
    },
    cantidadCarrito: {
      type: Number,
      default: 0
    },
    color: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      numZonas: 0,
      listZonas: []
    };
  },
  computed: {
    ...mapGetters("config", ["resetCalculadora"]),
    ...mapGetters("modelo", ["zonas", "modelCode", "numMaximunZones"]),
    ...mapGetters("carrito", ["totalUnidades"]),
    maxZonas() {
      // 20231204: (STY) Se devuelve un valor fijo 8 tal y como están los configurables de SAP.
      // return this.zonas ? this.zonas.length : 0;
      return this.numMaximunZones;
    }
  },
  watch: {
    modelo() {
      this.numZonas = 1;
      this.listZonas = this.zonas.slice(0, this.numZonas);
    },
    numZonas(newVal, oldVal) {
      if (newVal === oldVal || newVal == this.listZonas.length) return;
      // En caso de que se haya añadido una zona
      if (newVal > oldVal) {
        // Debemos comprobar que la zona a añadir no se encuentre ya en la lista
        // si se encuentra, se intentará añadir la siguiente
        let zona = this.zonas[0]; // Ponemos la primera zona por defecto
        // Si hay más de 1 zona buscamos la siguiente automáticamente
        if (this.zonas.length > 1) {
          zona = this.zonas[newVal - 1];
          let index = 0;
          while (this.listZonas.some(z => z.Zona == zona.Zona)) {
            zona = this.zonas[index];
          }
        }

        // Metemos la zona
        this.listZonas.push(zona);
      } else {
        // Calculamos la diferencia entre el número de zonas nuevo y el viejo, y borro tantas como la diferencia
        this.listZonas.splice(
          this.listZonas.length - (oldVal - newVal),
          oldVal - newVal
        );
      }

      if (newVal == 1 && this.listZonas.length != 1) {
        this.listZonas = this.zonas.slice(0, newVal);
      }

      this.$nextTick(() => {
        this.updateHeightFrame();
      });
    },
    resetCalculadora(newVal, oldVal) {
      if (newVal === oldVal) return;
      if (newVal) {
        this.numZonas = 1;
        this.listZonas = this.zonas.slice(0, this.numZonas);
      }
    },
    zonas(newVal, oldVal) {
      if (newVal === oldVal) return;
      this.numZonas = 1;
      this.listZonas = this.zonas.slice(0, this.numZonas);
    }
  },
  beforeMount() {
    this.listZonas = this.zonas?.slice(0, this.numZonas) ?? [];

    this.$nextTick(() => {
      this.updateHeightFrame(document.activeElement.scrollHeight);
    });
  },
  methods: {
    ...mapActions("config", ["updateHeightFrame"]),
    zonaChange(index, zona) {
      let newZone = this.zonas.find(z => z.Zona == zona);
      // Sustituimos en listZonas la zona que ha cambiado
      this.listZonas.splice(index, 1, newZone);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cotizador-zona {
  border-radius: 0px;
  background-color: #f8f8f8;
  padding: 15px;
  height: 100%;
  border-radius: 10px;
}

.min-zonas-size {
  min-height: 500px;
}

.loading-zonas {
  background-color: #f8f8f8;
}
</style>
