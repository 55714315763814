<template>
    <span class="d-flex flex-column">
        <generic-check
            v-model="repeticion"
            :checkbox-label="
                $t('configuracion.CalculoPrecios.Es un pedido de repetición')
            "
            icon="mdi-information"
            :tooltip-text="
                $t(
                    'configuracion.Si ya nos ha hecho este mismo diseño en la misma prenda, marque esta casilla para que incorporemos la información del pedido anterior'
                )
            "
        />
        <div class="d-flex">
            <v-tooltip top>
                <template #activator="{ on, attrs }">
                    <v-btn
                    v-show="repeticion"
                        v-bind="attrs"
                        :disabled="!repeticion || isSelecting"
                        :loading="isSelecting"
                        class="mt-0 fileBtn-repeticion mb-3"
                        large
                        v-on="on"
                        @click="fileBoceto ? removeFile() : handleFileImport()"
                    >
                        <v-icon
                            :color="!fileBoceto ? 'primary' : 'error'"
                            class="mr-3"
                        >
                            {{ !fileBoceto ? "mdi-plus-circle" : "mdi-delete" }}
                        </v-icon>
                        {{
                            fileBoceto
                                ? $t("configuracion.eliminarBocetoRepeticion")
                                : $t("configuracion.adjuntarBocetoRepeticion")
                        }}
                    </v-btn>
                </template>
                <span>{{ $t("configuracion.AyudaInputBoceto") }}</span>
            </v-tooltip>
            <div  v-if="fileBoceto" class="d-flex align-center">
                <span class="text-caption">{{ fileBoceto?.name }}</span>
                <v-btn icon @click="removeFile">
                    <v-icon color="error">mdi-close</v-icon>
                </v-btn>
            </div>
            <input
                ref="uploaderCheckRepeticion"
                class="d-none"
                type="file"
                accept="application/pdf"
                @change="selectFile"
            />
        </div>
    </span>
</template>

<script>
import GenericCheck from "@/components/basic/GenericCheck.vue";
import { mapActions } from "vuex";
export default {
    components: {
        GenericCheck
    },
    props: {
        idZone: {
            type: Number,
            required: true
        },
        value: {
            type: Boolean,
            default: false
        },
        checkboxLabel: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: null
        },
        tooltipText: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            repeticion: false,
            fileBoceto: null,
            isSelecting: false
        };
    },
    watch: {
        repeticion(newVal) {
            if (newVal != this.value) this.$emit("input", newVal);
        }
    },
    created() {
        this.repeticion = this.value;
    },
    methods: {
        ...mapActions("carrito", ["addBocetoRepeticion", "deleteBocetoRepeticion"]),
        handleFileImport() {
            if (!this.repeticion || this.isSelecting) return;
            this.isSelecting = true;
            this.$refs.uploaderCheckRepeticion.click();
        },
        selectFile(event) {
            const file = event.target.files[0];

            if (file) {
                if (file.size > 15000000) {
                    alert(this.$t("alert.alertSizeBiggerThan15Mb"));
                } else {
                    this.fileBoceto = file;
                    this.addBocetoRepeticion({
                        id: this.idZone,
                        value: this.fileBoceto
                    });
                }
            }

            this.isSelecting = false;
        },
        removeFile() {
            this.fileBoceto = null;
            this.$refs.uploaderCheckRepeticion.value = null;
            this.deleteBocetoRepeticion(this.idZone);
        }
    }
};
</script>

<style lang="scss">
.fileBtn-repeticion{
    font-family: $heading-font-family, sans-serif;
    font-size: 14px;
    line-height: 40px;
    font-weight: 500;
    text-transform: none !important;
    letter-spacing: 0 !important;
}

</style>
