import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";

Vue.use(VueI18n);

function loadLocalMessages(locale) {
  try {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    return locales(`./${locale}.json`);
  } catch (error) {
    console.error("Failed to load remote locale", error);
    return null;
  }
}

async function loadRemoteMessages(locale) {
  try {
    const blob = process.env.VUE_APP_BLOB_URL;
    const response = await axios.get(`${process.env.VUE_APP_STATIC_IMG_URL}/${blob}/locale/${locale}.json`);
    return response.data;
  } catch (error) {
    console.error("Failed to load remote locale", error);
    throw error;
  }
}

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: {},
});

i18n.loadedLanguages = {};

i18n.loadLanguageAsync = async (locale) => {
  const loadMessages = async (lang) => {
    if (i18n.loadedLanguages[lang]) {
      i18n.setLocaleMessage(lang, i18n.loadedLanguages[lang]);
      return;
    }

    let localMessages = loadLocalMessages(lang) || {};
    let remoteMessages = await loadRemoteMessages(lang);

    const combinedMessages = { ...localMessages, ...remoteMessages };

    i18n.loadedLanguages[lang] = combinedMessages;

    i18n.setLocaleMessage(lang, combinedMessages);
  };

  const fallback = process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en";

  if (!locale) locale = fallback;

  await loadMessages(locale);

  i18n.locale = locale;


  if (locale !== fallback) {
    await loadMessages(fallback);
  }
}


export default i18n;
