export default {
    isProveedor: false,
    locale: "en",
    allSizes: {
        "A0": {
            Ancho: 841,
            Alto: 1189,
        },
        "A1": {
            Ancho: 594,
            Alto: 841,
        },
        "A2": {
            Ancho: 420,
            Alto: 594,
        },
        "A3": {
            Ancho: 297,
            Alto: 420,
        },
        "A4": {
            Ancho: 210,
            Alto: 297,
        },
        "A5": {
            Ancho: 148,
            Alto: 210,
        },
        "A6": {
            Ancho: 105,
            Alto: 148,
        },
        "A7": {
            Ancho: 74,
            Alto: 105,
        },
        "A8": {
            Ancho: 52,
            Alto: 74,
        },
        "A9": {
            Ancho: 37,
            Alto: 52,
        },
        "A10": {
            Ancho: 26,
            Alto: 37,
        },

    },
    comercial: null,
    userList: {},
    loggedClient: null,
    userOfClient: null,
    key: null,
    resetCalculadora: false,
    calculadoraHeight: 0,
    colorSelected: "01",
    company: "GOR",

    // Parámetros para youReprocesos
    youReprocesosId: null,
    youReprocesosConfig: null,
    youReprocesosSession: null,

    initLoaderYouReprocesos: false,
    optionsNumerosDtf: [],
    optionsNumerosTransfer: [],
    sizeDigitalOptions: {}
};
