<template>
 <v-dialog
      v-model="dialog"
      v-no-scroll-body
      scrollable
      transition="dialog-bottom-transition"
      class="dialog-zindex margin-top-banner"
      :content-class="'margin-top-banner productModal smallModal youReprocesosProductModal'"
    >
      <template #activator="{on}">
        <CotizadorBtn
          class="mw-100 w-100 pr-2" content-class="w-100"
          sm
          :animated="modelPrice && totalUnidades == 0"
          :on="on"
          :disabled="!modelPrice"
        >
          + {{ $t("general.sizeColorQuantity") }}
        </CotizadorBtn>
      </template>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar
            extended
            extension-height="0"
            height="fit-content"
            dark
            color="black"
          >
            <v-toolbar-title
              class="productos-titulo d-flex justify-space-between"
            >
              <v-container fluid>
                <v-row>
                  <v-col cols="8" class="d-flex align-center py-0">
                    <v-row>
                      <v-col cols="12" md="6" class="d-flex align-center py-0">
                        <span class="modelo-name">
                          {{ $t(`MODELS.${modelCode}.NAME`) }}
                        </span>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        class="d-flex justify-md-center align-center py-0"
                      >
                        <span>
                          {{ $t("general.Anadir talla, color y cantidad") }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="4" class="d-flex flex-row-reverse pr-0 py-0">
                    <div class="left-border" @click="emitClick(false)">
                      <v-btn icon dark>
                        <v-icon xl>mdi-close</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text id="productSelectorDialogBody">
          <list-product-input></list-product-input>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <CotizadorBtn
            content-class="px-10"
            :on="{
              click: () => {
                deleteAllProducts();
              }
            }"
          >
            {{ $t("cart.CleanCart") }}
          </CotizadorBtn>
          <CotizadorBtn
            content-class="px-10"
            class="ml-4"
            success
            :on="{
              click: () => {
                emitClick(false);
              }
            }"
          >
            {{ $t("cart.addToBudget") }}
          </CotizadorBtn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CotizadorBtn from "../basic/CotizadorBtn.vue";
import listProductInput from "@/components/cotizadorClientes/listProductInput.vue";

export default {
  name: "Productos",
  components: { CotizadorBtn, listProductInput },
  props: {
    color: Object,
    cantidadInicial: {
      type: Number,
      default: 0
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      productosModeloColor: null,
      notifications: false,
      actualColor: null,
      debouncedProductQuantity: {},
      loadingPrices: 0
    };
  },
  computed: {
    ...mapGetters("carrito", [
      "carrito",
      "quantityByColor",
      "quantityByColorAndProduct",
      "soloBlancoEnCarritoSublimacion",
      "totalUnidades"
    ]),
    ...mapGetters("modelo", [
      "productos",
      "modelPrice",
      "waitExtranet",
      "getProductosByModeloColor",
      "zonas",
      "colorAvalible",
      "currency",
      "hasRangePrices",
      "modelInfoFromCatalog",
      "modelCode",
      "sublimacionSoloBlanco"
    ]),
    ...mapGetters("config", ["key", "isYouReprocesos"]),
    cantidadByTalla: function () {
      // if this.carrito.productos is empty

      let result = this.carrito.productos.reduce((grouping, item) => {
        console.log("REDUCE!", grouping, item);
        if (grouping[item.talla] === undefined) {
          grouping[item.talla] = item.cantidad;
        } else {
          grouping[item.talla] += item.cantidad;
        }
        return grouping;
      }, {});

      console.log("RESULT!", result);
      return { S: 200, M: 500 };
    },
    actualColorInfo: function () {
      return this.colorAvalible.find(c => c.code == this.actualColor);
    }
  },
  watch: {
    dialog: function () {
      setTimeout(() => {
        const heightDialog =
          document.getElementById("productSelectorDialogBody")?.scrollHeight ??
          0;
        this.updateHeightFrame(heightDialog + 53);
      }, 250);
    },
    modelCode(newVal) {
      if (newVal && !this.dialog && !this.totalUnidades) {
        this.dialog = true;
      }
    },
    active: function (newActive, oldActive) {
      if (newActive !== oldActive) this.dialog = newActive;
    },
    colorAvalible: function (newVal) {
      if (newVal.length == 0) this.actualColor = null;
      else this.actualColor = newVal[0].code;
    }
  },
  async created() {
    this.dialog = this.active;
    this.updateProductosModeloColor();
    await this.waitExtranet;

  },
  mounted() {
    if (this.modelCode && !this.dialog && !this.totalUnidades) {
      this.dialog = true;
    }
  },
  methods: {
    ...mapActions("carrito", [
      "fetchCarrito",
      "addProducto",
      "deleteProducto",
      "deleteAllProducts"
    ]),
    ...mapActions("modelo", ["addAlert"]),
    ...mapActions("config", ["updateHeightFrame"]),
    async removeAllProducts() {
      this.deleteAllProducts();
    },
    emitClick(payload) {
      this.dialog = payload;
      parent.postMessage({ type: "scrollTop" }, "*");
      if(!payload)
      this.$emit("close", payload);
    },
    changeColor(colorCode) {
      if (
        this.loadingPrices > 0 ||
        (this.soloBlancoEnCarritoSublimacion && colorCode != "01") ||
        (colorCode == "01" &&
          this.sublimacionSoloBlanco &&
          !this.soloBlancoEnCarritoSublimacion &&
          this.totalUnidades > 0)
      )
        return;
      this.actualColor = colorCode;
      this.updateHeightFrame();
      this.$emit("changeColor", colorCode);
    },
    updateProductosModeloColor: async function (modelo, color) {
      if (modelo == null) modelo = this.modelo;
      if (color == null) color = this.color;
      if (modelo == null || color == null) return;
      // this.productosModeloColor = this.productos.find(
      //   (p) =>  p.modelo == modelo.id && p.color == color.CODE
      // )?.productos;

      if (
        this.productosModeloColor == null ||
        this.productosModeloColor.length == 0
      ) {
        this.productosModeloColor = this.getProductosByModeloColor(
          this.actualColor
        ).find(p => p.color == color);
      }

      if (
        this.productosModeloColor != null &&
        this.productosModeloColor.length > 0
      )
        return;

      // await this.fetchProductos({
      //   modelo: modelo.id,
      //   color: color.CODE,
      //   key: this.key,
      // });
      this.productosModeloColor = this.productos.find(
        p => p.modelo == modelo.id && p.color == color.CODE
      )?.productos;
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.dialog-zindex {
  z-index: 1000;
}

.productModal {
  margin-top: 83px !important;
  overflow-x: hidden;

  &.youReprocesosProductModal {
    margin-top: 12px !important;
    position: absolute;
    top: 35px;
    max-width: 1481px;
    max-height: 840px !important;

    &.smallModal {
      max-width: 600px;
    }
    // #productSelectorDialogBody {
    //   max-height: 956px;
    //   min-height: 760px;
    // }
  }

  .title-table-header {
    font-size: 18px !important;
    font-weight: 700;
    text-transform: uppercase;
    font-family: $heading-font-family;
    min-height: 48px;
  }

  .v-data-table >>> th {
    font-size: 20px !important;
  }

  .text-body {
    font-size: 14px !important;
  }

  .productos-titulo {
    font-size: 200% !important;
    // margin-left: 25px;
    width: 100%;

    span {
      font-size: 18px !important;
      font-weight: 300;
      font-family: $heading-font-family;
    }

    .left-border {
      border-left: 1px solid white;
      padding-left: 28px;
      padding-right: 28px;
      cursor: pointer;
      &:hover {
        background: #434343;
      }
    }

    .modelo-name {
      font-size: 20px;
      font-weight: bold;
    }
  }

  .color-list {
    width: 100%;
  }
}

.text-size-zones {
  font-size: 10px;
}

.v-toolbar__content {
  padding-right: 0px;
}

@media (max-width: 600px) {
  .productModal.youReprocesosProductModal {
    top: 0px;
    margin-top: 0px !important;
    max-height: 100%;

    // #productSelectorDialogBody {
    //   max-height:  100%;
    //   overflow-y: scroll;
    // }
  }
}
</style>
