import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/lib/locale/es';
import featherIconsSet from './featherIcons';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
    values: {
      ...featherIconsSet.values
    },
  },
  theme: {
      options: {
      customProperties: true,
      cancel: 'fi-x', // Icono para cancelar
      close: 'fi-x-circle', // Icono para cerrar 
      delete: 'fi-trash', // Icono para eliminar 
      clear: 'fi-x-circle', // Icono para limpiar 
      success: 'fi-check-circle', // Icono de éxito 
      info: 'fi-info', // Icono de información 
      warning: 'fi-alert-triangle', // Icono de advertencia 
      error: 'fi-x-circle', // Icono de error 
      prev: 'fi-chevron-left', // Icono para anterior 
      next: 'fi-chevron-right', // Icono para siguiente 
      dropdown: 'fi-chevron-down', // Icono de desplegable 
      checkboxOn: 'fi-check-square', // Icono de checkbox activado 
      checkboxOff: 'fi-square', // Icono de checkbox desactivado 
      checkboxIndeterminate: 'fi-minus-square', // Icono de checkbox indeterminado 
      delimiter: 'fi-circle', // Icono de delimitador 
      sort: 'fi-arrow-up', // Icono de ordenar 
      expand: 'fi-chevron-down', // Icono de expandir 
      menu: 'fi-menu', // Icono de menú 
      subgroup: 'fi-chevron-right', // Icono de subgrupo 
      dropdownIcon: 'fi-chevron-down', // Icono de desplegable en el menú 
      radioOn: 'fi-check-circle', // Icono de radio activado 
      radioOff: 'fi-circle', // Icono de radio desactivado 
      edit: 'fi-edit', // Icono de editar 
      ratingEmpty: 'fi-star', // Icono de rating vacío 
      ratingFull: 'fi-star', // Icono de rating lleno 
      ratingHalf: 'fi-star-half', // Icono de rating medio 
      loading: 'fi-loader',
      },
    themes: {
      light: {
        primary: '#3AF56D',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFA726',
        bannerBackground: "#9743FC",
        colorTextBtn: "#222",
        colorTextBanner: "#ffffff",
        colorBtn: '#3AF56D',
        primaryColorPdf: "#7e7e7e",
        primaryColorText: "#ffffff",
        secondaryColorPdf: "#ebebeb",
        secondaryColorText: "#222",
        darkZones: "#f4f4f4",
      },
    },
  },
    lang: {
      locales: { es },
      current: 'es',
    },
});
