<template>
  <v-app v-if="initLoadCalculadora || initLoaderYouReprocesos">
    <!-- Container de carga -->
    <v-container class="loader">
      <v-row>
        <v-col cols="12">
          <v-progress-circular
            :rotate="360"
            :size="100"
            :width="15"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
  <v-app v-else id="appWrapContainer">
    <!-- Modal que contine el carrito -->
    <CartModal
      v-if="comercialLoginAvalible || clientCartAvalible"
      :dialog="cartDialog"
      @cerrar="onCloseCart"
    ></CartModal>
    <!-- Modal para hacer el login como comercial -->
    <LoginComercialModal
      v-if="comercialLoginAvalible"
      :dialog="loginDialog"
      @cerrar="onCloseLogin"
    ></LoginComercialModal>
    <!-- Modal de busqueda de usuarios -->
    <UserSearchModal
      v-if="comercialLoginAvalible"
      :dialog="userDialog"
      @cerrar="onCloseUser"
    ></UserSearchModal>
    <!-- Modal de alerta para mostrar los distintos errores -->
    <alerta
      :dialog="alertActive"
      :titulo="alertError.title"
      :boton-cerrar="$t('confirm.Cerrar')"
      :cuerpo="alertError.body"
      :type-error="alertError"
      @cerrar="alertActive = false"
    ></alerta>

    <!-- Menu principal -->
    <v-app-bar color="bannerBackground" app height="85" class="menu-app-bar">
      <v-container class="px-0 px-md-5" fluid>
        <v-row>
          <v-col cols="12" lg="3" md="4" class="d-flex">
            <v-img
              :max-height=" isOk || isYouReprocesos ? 60 : 40"
              max-width="150"
              :src="actualLogo"
              alt="Logo compañia"
              contain
              class="mr-6"
              :class="{ 'white-svg': whiteSvg && !isYouReprocesos }"
            />
            <div
              v-if="!isYouReprocesos"
              class="d-flex flex-column justify-center title-app-bar"
            >
              <h1><b>YOURCUSTOMIZER</b></h1>
              <span v-if="!isOk && !isYouReprocesos">by gorfactory</span>
            </div>
            <div
              v-if="comercialLoginAvalible && comercialInfo && !isYouReprocesos"
              class="calculadora-comercialname"
            >
              <v-menu auto offset-y>
                <template #activator="{ on, attrs }">
                  <v-btn class="py-6 px-3" v-bind="attrs" v-on="on">
                    {{ comercialInfo.name }} {{ comercialInfo.lastName }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item class="menu-options" @click="reiniciar">
                    <v-list-item-icon>
                      <v-icon>mdi-reload</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      {{ $t("general.Reiniciar") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item class="menu-options" @click="onLogout">
                    <v-list-item-icon>
                      <v-icon>mdi-logout-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      {{ $t("login.Logout") }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
          <v-col cols="12" md="6" class="d-flex justify-center align-center">
            <model-search @empty-model="initModalSearch"></model-search>
            <!-- Modal para buscar un modelo o cargar un prediseño si no se ha especificado nada -->
            <v-dialog
              v-model="initialModal"
              max-width="555px"
              class="productModal youReprocesosProductModal"
              transition="dialog-bottom-transition"
              :content-class="'productModal youReprocesosProductModal inital-modal'"
            >
              <v-card color="bannerBackground">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <div class="head">
                        {{$t("general.selectModelOrDraft")}}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <model-search
                        @model-selected="initialModal = false"
                      ></model-search>
                    </v-col>
                  </v-row>
                  <v-row v-if="!isYouReprocesos">
                    <v-col class="w-100">
                      <cotizador-btn disabled outlined content-class="w-100">
                        {{$t("draftLiteral.loadDraft")}}
                      </cotizador-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="12" lg="3" md="2">
            <div
              v-if="
                comercialLoginAvalible &&
                loggedClient &&
                userOfClient &&
                !isYouReprocesos
              "
              class="calculadora-user"
            >
              <v-btn class="mr-1" dark outlined @click="userDialog = true">
                <v-icon dark>mdi-magnify</v-icon>
                <span class="selectedClient ml-1">
                  {{ loggedClient.clientId }}
                  <br />
                  {{ loggedClient.clientName }}
                </span>
              </v-btn>
              <v-select
                v-model="localUserOfClient"
                :items="loggedClient.users"
                outlined
                dense
                hide-details
                item-text="label"
                item-value="user"
                color="primary"
                background-color="white"
                class="selectableUser"
              ></v-select>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-main>
      <new-cotizador @reload="reiniciar" />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getTextColor, lighterColor } from "@/utils/colorUtils";

import alerta from "@/components/modales/alert.vue";
import LoginComercialModal from "@/components/modales/loginComercialModal.vue";
import UserSearchModal from "@/components/modales/userSearchModal.vue";
import CartModal from "@/components/modales/cartModal.vue";
import ModelSearch from "@/components/basic/modelSearch.vue";
import newCotizador from "@/components/cotizadorClientes/NewCotizador.vue";
import CotizadorBtn from "@/components/basic/CotizadorBtn.vue";

export default {
  components: {
    LoginComercialModal,
    alerta,
    UserSearchModal,
    CartModal,
    ModelSearch,
    newCotizador,
    CotizadorBtn
  },
  data() {
    return {
      cartDialog: false,
      loginDialog: false,
      userDialog: false,
      initLoadCalculadora: true,
      alertError: {},
      alertActive: false,
      loadingKey: false,
      initialModal: false,
      initReprocesos: false,
      localUserOfClient: null
    };
  },
  computed: {
    ...mapGetters("config", [
      "comercialLoginAvalible",
      "clientCartAvalible",
      "initLoaderYouReprocesos",
      "getLocaleFromLanguageRegion",
      "isYouReprocesos",
      "youReprocesosConfig",
      "isOk",
      "isStamina",
      "loggedClient",
      "userOfClient",
      "comercialInfo",
      "key"
    ]),
    ...mapGetters("modelo", ["modelCode"]),
    // Logo actual dependiendo de que tienda este activa
    actualLogo() {
      return this.isYouReprocesos
        ? this.youReprocesosConfig.logourl
        : this.isOk
          ? "https://erolystorage.blob.core.windows.net/oktextil/LOGO_OK_BLANCO.svg"
          : this.isStamina
            ? "https://static.gorfactory.es/images/header/Logo_Stamina_200x56px.svg"
            : "https://static.gorfactory.es/images/header/logo_Roly.svg";
    },
    // Calculamos is tenemos que poner el svg en blanco o en negro
    whiteSvg() {
      return (
        this.$vuetify.theme.currentTheme.colorTextBanner &&
        (this.$vuetify.theme.currentTheme.colorTextBanner == "#ffffff" ||
          this.$vuetify.theme.currentTheme.colorTextBanner == "white")
      );
    }
  },
  watch: {
    isYouReprocesos(newVal) {
      if (newVal && !this.initReprocesos) {
        this.initYouReprocesos();
      }
    },
    loggedClient() {
      this.reiniciar();
    },
    async userOfClient(newVal, oldVal) {
      // console.log("🚀 ~ file: App.vue:310 ~ userOfClient ~ newVal:", newVal);
      if (newVal != oldVal && newVal?.user && newVal?.reprocesosKey) {
        this.cargando = true;
        this.localUserOfClient = newVal.user;
        this.setKey(newVal.reprocesosKey);
        this.initialModal = true;
      }
    },
    localUserOfClient(newVal, oldVal) {
      if (newVal != oldVal && newVal != this.userOfClient?.user) {
        this.setUserOfClient(
          this.loggedClient.users.find(u => u.user == newVal)
        );
      }
    }
  },
  async beforeMount() {
    if (this.$route.query.company) {
      this.setCompanyValue(this.$route.query.company);
    }

    await this.initCalculadora();
  },
  mounted() {
    let key = this.$route.query.k;
    let avalibleClientKey = !(key === undefined || key == null || key == "");
    if (!avalibleClientKey) {
      if (
        !this.comercialInfo &&
        this.comercialLoginAvalible &&
        !this.isYouReprocesos
      )
        this.loginDialog = true;
    } else {
      this.setKey(key);
      this.loadingKey = true;
    }
  },
  methods: {
    ...mapActions("modelo", ["InitCotizador"]),
    ...mapActions("config", [
      "setKey",
      "setlocale",
      "setUserOfClient",
      "logout",
      "setResetCalculadora",
      "setCompanyValue"
    ]),
    ...mapActions("carrito", ["cleanAllDesign", "restoreCarrito"]),
    // Función para actualizar el color del texto dependiendo del tema
    updateFontColorByTheme(param) {
      let { colorTextBtn, colorTextBanner } = param || {};;
      console.log("🚀 ~ updateFontColorByTheme ~ colorTextBtn:", colorTextBtn)
      if (!this.$vuetify.theme.currentTheme) return;


      if (colorTextBtn) {
        this.$vuetify.theme.currentTheme.colorTextBtn = colorTextBtn;
      }
      else
      {
        this.$vuetify.theme.currentTheme.colorTextBtn = getTextColor(
          this.$vuetify.theme.currentTheme.colorBtn
        );
        console.log("🚀 ~ updateFontColorByTheme ~ this.$vuetify.theme.currentTheme:", this.$vuetify.theme.currentTheme)
      }
      if (colorTextBanner) {
        this.$vuetify.theme.currentTheme.colorTextBanner = colorTextBanner;
      }
      else {
        this.$vuetify.theme.currentTheme.colorTextBanner = getTextColor(
          this.$vuetify.theme.currentTheme.bannerBackground
        );
      }
    },
    // Función para inicializar la calculadora
    async initCalculadora() {
      // Inicializamos el idioma
      await this.setLocaleApp();

      // Buscamos todos los modelos
      await this.InitCotizador();

      // Actualizamos youReprocesos si estamos en una sesión de YouReprocesos
      if (this.isYouReprocesos) {
        await this.initYouReprocesos();
      }

      if (this.isOk) {
        this.$vuetify.theme.currentTheme.primary = "#b74040";
        this.$vuetify.theme.currentTheme.bannerBackground = "#b74040";
        this.$vuetify.theme.currentTheme.colorBtn = "#b74040";

        this.updateFontColorByTheme({colorTextBanner: "#fff"});
      }

      // Cerramos el loader
      this.initLoadCalculadora = false;
      this.loadingKey = false;
    },
    // Función que pone el idioma a la calculadora
    async setLocaleApp(lang = null) {
      if (lang == null) {
        lang = this.$route.query.lang ?? "en";
      }

      lang = this.getLocaleFromLanguageRegion(lang);
      this.setlocale(lang);
      await this.$root.$i18n.loadLanguageAsync(lang);
    },
    // Función para inicializar youReprocesos
    async initYouReprocesos() {
      this.$vuetify.theme.currentTheme.primary =
        this.youReprocesosConfig.accentColor;
      this.$vuetify.theme.currentTheme.colorBtn =
        this.youReprocesosConfig.accentColor;
      
      this.$vuetify.theme.currentTheme.bannerBackground =
        this.youReprocesosConfig.bannerColor;
      if (!this.modeloSeleccionado) this.searchModelDialogShow = true;

      if (this.youReprocesosConfig.logourl) {
        const favicon = document.getElementById("favicon-link");
        favicon.href = this.youReprocesosConfig.logourl;
      }

      await this.setLocaleApp(this.youReprocesosConfig.language);

      this.$vuetify.theme.currentTheme.primaryColorPdf =
        this.youReprocesosConfig.colorTypePdf == 1
          ? this.youReprocesosConfig.accentColor
          : this.youReprocesosConfig.colorTypePdf == 2
            ? this.youReprocesosConfig.bannerColor
            : "#7e7e7e";
      this.$vuetify.theme.currentTheme.primaryColorText =
        this.youReprocesosConfig.colorTypePdf == 0
          ? "white"
          : getTextColor(this.$vuetify.theme.currentTheme.primaryColorPdf);

      this.$vuetify.theme.currentTheme.secondaryColorPdf = lighterColor(
        this.$vuetify.theme.currentTheme.primaryColorPdf,
        85
      );

      this.$vuetify.theme.currentTheme.secondaryColorText = getTextColor(
        this.$vuetify.theme.currentTheme.secondaryColorPdf
      );

      this.updateFontColorByTheme();

      this.initReprocesos = true;
      this.initialModal = true;
    },
    // Función para cerrar el login y abrir el buscador de usuarios
    onCloseLogin() {
      this.loginDialog = false;
      this.userDialog = true;
    },
    // Función para cerrar el carrito
    onCloseCart() {
      this.cartDialog = false;
    },
    // Función para cerrar el dialogo de usuarios
    onCloseUser() {
      this.userDialog = false;
    },
    // Función para iniciar el modal de busqueda
    initModalSearch() {
      console.log("TESTTTTTT", !this.key);
      if (!this.key) return;
      this.initialModal = true;
    },
    // Función para reinicar completamente el cotizador
    async reiniciar() {
      this.restoreCarrito();
      // await this.InitCotizador();
      await this.cleanAllDesign();
      this.setResetCalculadora(false);
      this.initialModal = true;
    },
    // Función para cerrar sesión de comercial
    async onLogout() {
      await this.reiniciar();
      await this.logout();
      this.loginDialog = true;
      this.userDialog = false;
    }
  }
};
</script>

<style lang="scss">
.inital-modal {
  color: var(--v-colorTextBanner-base);
  overflow: visible;
  *:not(
      .searchInputModel,
      .listSerachModel,
      .cotizador-btn-success,
      .cotizador-btn
    ) {
    color: inherit !important;
  }

  .head {
    display: flex;
    justify-content: center;
    font: normal normal normal 19px/24px $heading-font-family;
    letter-spacing: 0px;
    margin-top: 15px;
    color: var(--v-colorTextBanner-base) !important;
  }
}

.menu-app-bar {
  font-family: $heading-font-family;
  color: var(--v-colorTextBanner-base) !important;

  .calculadora-user {
    font-size: 15px;
    font-weight: 300;
    margin-right: 45px;
    /* position: absolute; */
    /* right: 10px; */
    /* height: 100%;  */
    /* margin-top: 5px; */
    /* background: white; */
    max-height: 45px;
    /* max-width: 250px; */
    display: flex;
    align-items: center;

    .selectableUser,
    .selectedClient {
      max-width: 250px;
      overflow: hidden;
    }

  }

  .calculadora-comercialname {
      font-size: 15px;
      font-weight: 300;
      margin-left: 10px;
      /* position: absolute; */
      /* left: 10px;
  height: 100%; */
      /* margin-top: 5px; */
    }

  .white-svg {
    filter: invert(1);
  }

  .title-app-bar {
    h1 {
      height: 28px;
      font-size: 25px;
    }

    span {
      font-weight: 100;
    }
  }
}

@media screen and (max-width: 600px) {
  .menu-app-bar {
    height: 170px !important;

    .v-toolbar__content {
      height: 170px !important;
    }
  }

  .v-main {
    padding-top: 170px !important;
  }
}
</style>
