import Vue from 'vue'
import './plugins/axios'
import App3D from './App3D.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import i18n from './i18n'
import router from './router'
// import PerfectScrollbar from 'vue2-perfect-scrollbar'
// import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

// Importamos las clases de utilidad que hemos ido creando en scss
import "@/static/scss/_utilities.scss";
import "@/static/scss/_scrollbars.scss";

Vue.config.productionTip = false

Vue.filter('filtrarPrecios', function (value) {
  var decimales = 4;
  if (!value) return 0.00;
  var signo = (value >= 0 ? 1 : -1);
    value = value * signo;
    if (decimales === 0) //con 0 decimales
        return signo * Math.round(value);
    // round(x * 10 ^ decimales)
    value = value.toString().split('e');
    value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + decimales) : decimales)));
    // x * 10 ^ (-decimales)
    value = value.toString().split('e');
    return signo * (value[0] + 'e' + (value[1] ? (+value[1] - decimales) : -decimales));
})

Vue.filter('filtrarPreciosWithPad', function (value) {
  var decimales = 3; // Redondear a 3 decimales
  if (!value) return '0.000';
  var signo = (value >= 0 ? 1 : -1);
  value = value * signo;
  if (decimales === 0) //con 0 decimales
    return signo * Math.round(value);
  // round(x * 10 ^ decimales)
  value = value.toString().split('e');
  value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + decimales) : decimales)));
  // x * 10 ^ (-decimales)
  value = value.toString().split('e');
  value = signo * (value[0] + 'e' + (value[1] ? (+value[1] - decimales) : -decimales));

  // Convertir a string con 3 decimales
  var resultado = value.toFixed(decimales);

  // Asegurar que tenga al menos 2 decimales
  if (resultado.split('.')[1].length < 2) {
    resultado = parseFloat(resultado).toFixed(2);
  }

  return resultado;
});
// Vue.use(PerfectScrollbar)


Vue.directive('no-scroll-body', {
  inserted() {
    document.body.style.overflow = 'hidden';
  },
  unbind() {
    document.body.style.overflow = '';
  }
});

new Vue({
  vuetify,
  store,
  i18n,
  router,
  render: h => h(App3D)
}).$mount('#app')
