import Vue from 'vue'
import VueRouter from 'vue-router'
import App from '../App.vue'
import store from '@/store/index'
import App3D from '../App3D.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Cotizador',
    component: App
  },
  {
    path: '/YouReprocesos/:identificador',
    name: 'youReprocesos',
    beforeEnter(to, from, next) {
      store.dispatch('config/setYouReprocesosSession', to.params.identificador);

      next();
    },
    component: App
  },
  {
    path: '/',
    name: 'New Cotizador',
    component: App3D
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
